import React from 'react';
import { MdClose } from 'react-icons/md';
import { Button } from '../../../../component/button/button';
import * as yup from 'yup';
import { useForm , Controller} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import InputField from '../../../../atoms/inputField/inputField';
import { addMemberAction, getMemberAction, updateMemberBatchAction } from '../../../../redux/states/member/member.actions';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { handleUseFormError } from '../../../../utils/utils';
import MultiSelectField from '../../../../atoms/multiSelectField/multiSelectField';
import { useEffect } from 'react';
import { getBatchListAction } from '../../../../redux/states/batch/batch.actions';

const schema = yup
  .object({
    batch: yup.object().nullable().required()
  })
  .required();

export default function UpdateBatchModal({ memberId, batchData, memberPackageHistoriesId, onClose, active }) {
  const dispatch = useDispatch();

  const { data: batchListData } = useSelector((state) => state?.batch?.batchList);

  useEffect(() => {
    dispatch(getBatchListAction());
  }, []);

  function handleModalClose() {
    onClose();
  }

  const {
    register,
    formState: { errors, values },
    handleSubmit,
    control,
    reset,
    setValue
  } = useForm({
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    setValue('batch', { label: batchData?.name, value: batchData?.id });
  }, [batchData]);

  const onSubmit = async (data) => {
    const body = { batchId: data?.batch?.value, memberPackageHistoriesId };
    const res = await dispatch(updateMemberBatchAction(memberId, body));
    if (res?.statusCode === 200) {
      toast.success(res?.message);
      onClose();
      dispatch(getMemberAction(memberId));
    } else {
      handleUseFormError(res);
    }
  };

  return (
    <div className="modal" id="staticBackdrop" style={{ display: active ? 'block' : 'none' }}>
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <MdClose size={30} onClick={() => handleModalClose(false)} className="modal__icon" />
          <div className="modal-header">
            <h5 className="modal-title header-4" id="staticBackdropLabel">
              {'Update Batch'}
            </h5>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Controller
                name="batch"
                control={control}
                render={({ field: { onChange, value }, fieldState }) => (
                  <MultiSelectField
                    label={'Batch'}
                    onChange={onChange}
                    value={value}
                    placeholder={'Select Batch'}
                    options={batchListData?.map((batch) => ({ value: batch?.id, label: batch?.name }))}
                    noOptionsMessage={'Please create batch before adding member'}
                    error={fieldState?.error?.message}
                  />
                )}
              />

              <Button label={'Update'} type="submit" class="btn-primary w-100 mt-4" />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
