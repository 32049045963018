import { get, patch, post, put } from '../../services/api/api';
import { baseUrl, memberListUrl, membersFilterUrl, membershipDurationsUrl, calculatePaymentUrl, memberStatusUrl, GymPaymentUrl, updateMemberBatchUrl, updateMemberPackageUrl, updateMemberTrainerUrl, bulkAddMemberUrl } from '../../services/api/apiConstants';

export const membershipDurationsService = () => get(baseUrl + membershipDurationsUrl);

export const getMemberFiltersService = () => get(baseUrl + membersFilterUrl );

export const getMemberListService = (urlParams) => get(baseUrl + memberListUrl + urlParams);

export const getMemberService = (id) => get(baseUrl + memberListUrl + `/${id}`);

export const addMemberService = (data) => post(baseUrl + memberListUrl, data);

export const bulkAddMemberService = (data) => post(baseUrl + bulkAddMemberUrl, data);

export const updateMemberService = (id, data) => post(baseUrl + memberListUrl + `/${id}`, data);

export const updateStatusMemberService = (id, status) => get(baseUrl + memberListUrl + `/${id}` + memberStatusUrl + status);

export const calculatePaymentService = (data) => post(baseUrl + calculatePaymentUrl, data);

export const getGymPaymentService = (memberId) => get(baseUrl + GymPaymentUrl + `${memberId}`);
export const postGymPaymentService = (data) => post(baseUrl + GymPaymentUrl, data);

export const updateMemberBatchService = (id, data) => post(baseUrl + updateMemberBatchUrl?.replace('${memberId}', id), data);

export const updateMemberPackageService = (id, data) => post(baseUrl + updateMemberPackageUrl?.replace('${memberId}', id), data);

export const updateMemberTrainerService = (id, data) => post(baseUrl + updateMemberTrainerUrl?.replace('${memberId}', id), data);
