import { getResponseError } from '../../../utils/utils';
import { handleRequest, handleSuccess, handleFailure } from '../../services/handleData/handleData.ts';
import {  GET_PROFILE_FAILED, GET_PROFILE_REQUEST, GET_PROFILE_SUCCESS, UPDATE_PROFILE_FAILED, UPDATE_PROFILE_REQUEST, UPDATE_PROFILE_SUCCESS } from './profile.constants';
import { getProfileService, updateProfileService  } from './profile.services';

// get Profile
export const getProfileAction = () => async (dispatch) => {
  dispatch(handleRequest(GET_PROFILE_REQUEST));
  try {
    const res = await getProfileService();
    if (res?.statusCode === 200) {
      dispatch(handleSuccess(GET_PROFILE_SUCCESS, res?.data));
    } else {
      let error = getResponseError(res);
      dispatch(handleFailure(GET_PROFILE_FAILED, error));
    }
    return res;
  } catch (ex) {
    console.log('API FAILED', ex);
    dispatch(handleFailure(GET_PROFILE_FAILED, ex?.message));
    return ex;
  }
};

// update Profile
export const updateProfileAction = (profileInfo) => async (dispatch) => {
  dispatch(handleRequest(UPDATE_PROFILE_REQUEST));
  try {
    const res = await updateProfileService(profileInfo);
    if (res?.statusCode === 201) {
      dispatch(handleSuccess(UPDATE_PROFILE_SUCCESS, res?.data));
    } else {
      let error = getResponseError(res);
      dispatch(handleFailure(UPDATE_PROFILE_FAILED, error));
    }
    return res;
  } catch (ex) {
    console.log('API FAILED', ex);
    dispatch(handleFailure(UPDATE_PROFILE_FAILED, ex?.message));
    return ex;
  }
};


