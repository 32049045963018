import React from 'react';
import Select from 'react-select';
import './multiSelectField.css';

const MultiSelectField = ({ label, options, error, onChange, value, placeholder, noOptionsMessage, isDisabled = false, isMulti = false }) => {
  return (
    <div className="mb-2">
      <label htmlFor="multiControlInput1" className="form-label label-resize">
        {label}
      </label>
      <Select
        options={options}
        onChange={onChange}
        value={value}
        id="multiControlInput1"
        className="react-dropdown"
        classNamePrefix={error ? 'multi-dropdown-error' : 'multi-dropdown'}
        placeholder={placeholder}
        isMulti={isMulti}
        noOptionsMessage={() => noOptionsMessage}
        isClearable
        isSearchable
        isDisabled={isDisabled}
      />
      {error ? <span className="error text-danger label">{error}</span> : null}
    </div>
  );
};

export default MultiSelectField;
