import { combineReducers } from 'redux';
import authReducers from './states/auth/auth.reducers';
import planReducers from './states/plan/plan.reducers'
import batchReducers from './states/batch/batch.reducers';
import visitorReducers from './states/visitor/visitor.reducers';
import profileReducers from './states/profile/profle.reducers';
import trainerReducers from './states/trainer/trainer.reducers';
import memberReducers from './states/member/member.reducers';
import smsReducers from './states/sms/sms.reducers';
import dashboardReducers from './states/dashboard/dashboard.reducers';


const rootReducer = combineReducers({
  auth: authReducers,
  dashboard: dashboardReducers,
  plan: planReducers,
  profile:profileReducers,
  batch: batchReducers,
  trainer: trainerReducers,
  visitor: visitorReducers,
  member: memberReducers,
  sms: smsReducers
});

export default rootReducer;
