import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RAZORPAY_KEY_ID } from '../utils/config';
import { toast } from 'react-toastify';
import { razorpayFailureAction, razorpaySuccessAction } from '../redux/states/auth/auth.actions';
import { getAvailableSmsCountAction, razorpaySmsFailureAction, razorpaySmsSuccessAction } from '../redux/states/sms/sms.actions';
import { saveData } from './../redux/services/handleData/localStorage.ts';

const usePaymentRazorpay = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data: profileData } = useSelector((state) => state?.profile?.profileData);
  const token = 'test';
  // const {
  //   user: { token }
  // } = useSelector((state) => state.user);

  // const {
  //   user: { name, email, mobile }
  // } = useSelector((state) => state?.user);

  const __DEV__ = window && window.document.domain === 'localhost';

  const loadScript = (srcpath) => {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = srcpath;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const handlePayment = async (paymentReferenceId, payableAmount, razorpayOrderId, orderType) => {
    console.log('razor', paymentReferenceId, payableAmount, razorpayOrderId);
    if (token) {
      const scriptRes = await loadScript('https://checkout.razorpay.com/v1/checkout.js');

      if (!scriptRes) {
        alert('razorpay sdk failed to load');
        return;
      }

      if (paymentReferenceId && payableAmount && razorpayOrderId) {
        const currency = 'INR';

        const options = {
          key: RAZORPAY_KEY_ID,
          amount: payableAmount, //get this from backend with api call
          currency: currency || 'INR', //get this from backend with api call
          name: 'Gym Height Products',
          description: 'Product Transaction',
          image: 'http://localhost:3000/vercel.svg',
          order_id: razorpayOrderId, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
          handler: async function (response) {
            console.log('orderType', orderType);
            if (response?.razorpay_order_id && response?.razorpay_payment_id && paymentReferenceId) {
              // successful handle of razorpay
              if (orderType === 'sms') {
                await dispatch(
                  razorpaySmsSuccessAction(paymentReferenceId, {
                    razorpayOrderId: response?.razorpay_order_id,
                    razorpayPaymentId: response?.razorpay_payment_id
                  })
                );
                dispatch(getAvailableSmsCountAction());
              } else {
                await dispatch(
                  razorpaySuccessAction(paymentReferenceId, {
                    razorpayOrderId: response?.razorpay_order_id,
                    razorpayPaymentId: response?.razorpay_payment_id
                  })
                );
                saveData('isPaymentDone', 1);
                window.location.reload();
                navigate('/dashboard');
              }
            } else {
              // error handling
              toast.error('Something went wrong');
              if (orderType === 'sms') {
                dispatch(razorpaySmsFailureAction(paymentReferenceId));
              } else {
                dispatch(razorpayFailureAction(paymentReferenceId));
              }
            }
          },
          prefill: {
            name: profileData?.name || '',
            email: profileData?.email || '',
            contact: profileData?.mobile || ''
          },
          notes: {
            address: 'Razorpay Corporate Office'
          },
          theme: {
            color: '#7367F0'
          }
        };
        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
      } else {
        toast.error('Something went wrong');
      }
    } else {
      toast.error('Please login again to continue payment')
    }
  };

  return [handlePayment];
};

export default usePaymentRazorpay;
