import { get, post, patch } from '../../services/api/api';
import {
  baseUrl,
  batchUrl
} from '../../services/api/apiConstants';

export const getBatchListService = () => get(baseUrl + batchUrl);

export const addBatchService = (data) => post(baseUrl + batchUrl, data);

export const updateBatchService = (batchId, data) => patch(baseUrl + batchUrl + batchId , data);


