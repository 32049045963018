import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { getMemberAction } from '../../../../redux/states/member/member.actions';
import { getPackageAction } from '../../../../redux/states/plan/plan.actions';
import './packageDetail.css';

export default function UpcomingPackageDetail({
  upcomingPlan: { batch, packageId = '', packagePriceDiscount, trainerPriceDiscount, packagePriceTotal, trainerId } = {}
}) {
  const dispatch = useDispatch();

  const { data: packageData } = useSelector((state) => state?.plan?.package);

  useEffect(() => {
    dispatch(getPackageAction(packageId));
  }, [packageId, dispatch]);

  console.log('packageData', packageData);
  return (
    <div className="card-1 mt-4">
      <div className="d-flex justify-content-between">
        <p className="header-3">Upcoming Plan Detail</p>
      </div>
      <div className="d-flex justify-content-between">
        <p className="header-8">Package</p>
        <p className="package-type">{packageData?.title}</p>
      </div>
      <div className="d-flex justify-content-between">
        <p className="header-8">Plan</p>
        <p className="package-type">{packageData?.plan?.name}</p>
      </div>
      <div className="d-flex justify-content-between">
        <p className="header-8">Membership</p>
        <p className="package-type">{packageData?.membershipDuration} month</p>
      </div>
      <div className="d-flex justify-content-between">
        <p className="header-8">Package Discount %</p>
        <p className="package-type">{packagePriceDiscount}%</p>
      </div>
      <div className="d-flex justify-content-between">
        <p className="header-8">Trainer Discount %</p>
        <p className="package-type">{trainerPriceDiscount}%</p>
      </div>
      <div className="d-flex justify-content-between">
        <p className="header-8">Trainer Taken</p>
        <p className="package-type">{trainerId ? 'Yes' : 'No'}</p>
      </div>
      <div className="d-flex justify-content-between">
        <p className="header-8">Package Fee</p>
        <p className="package-type">₹{packagePriceTotal}</p>
      </div>
      <div className="d-flex justify-content-between">
        <p className="header-8">Batch</p>
        <p className="package-type">{batch?.name}</p>
      </div>
    </div>
  );
}
