//get batch list
export const GET_BATCH_LIST = 'GET_BATCH_LIST';
export const GET_BATCH_LIST_REQUEST = 'GET_BATCH_LIST_REQUEST';
export const GET_BATCH_LIST_SUCCESS = 'GET_BATCH_LIST_SUCCESS';
export const GET_BATCH_LIST_FAILED = 'GET_BATCH_LIST_FAILED';

// add batch
export const ADD_BATCH = 'ADD_BATCH';
export const ADD_BATCH_REQUEST = 'ADD_BATCH_REQUEST';
export const ADD_BATCH_SUCCESS = 'ADD_BATCH_SUCCESS';
export const ADD_BATCH_FAILED = 'ADD_BATCH_FAILED';

// update batch
export const UPDATE_BATCH = 'UPDATE_BATCH';
export const UPDATE_BATCH_REQUEST = 'UPDATE_BATCH_REQUEST';
export const UPDATE_BATCH_SUCCESS = 'UPDATE_BATCH_SUCCESS';
export const UPDATE_BATCH_FAILED = 'UPDATE_BATCH_FAILED';
