import React, { useEffect, useState } from 'react';
import './planList.css';
import { Button } from '../../../../component/button/button';
import PlanCard from '../../../../component/molecule/plan-card/planCard';
import { useDispatch, useSelector } from 'react-redux';
import { getMemberListAction } from '../../../../redux/states/member/member.actions';
import { useNavigate } from 'react-router-dom';
import PackageCard from '../package-card/packageCard';
import { getPackagesListAction, getPlansListAction } from '../../../../redux/states/plan/plan.actions';
import Loader from '../../../../component/loader/loader';
import EmptyState from '../../../../component/molecule/empty-state/emptyState';
import ErrorState from '../../../../component/molecule/error-state/errorState';

export default function PlanList() {
  const { loading: planListsLoading, error: planListsError, data: planListsData } = useSelector((state) => state?.plan?.plansList);

  const { loading: packagesListLoading, error: packagesListError, data: packagesListData } = useSelector((state) => state?.plan?.packagesList);

  let navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPlansListAction());
    dispatch(getPackagesListAction());
  }, []);

  const handlePlanEdit = (id) => {
    console.log('first', id);
    navigate('/dashboard/add-plan', { state: { planId: id } });
  }

  return (
    <div>
      <div className="row">
        <div className="col-lg-7">
          <div className="d-flex justify-content-between mb-4">
            <h3 className="header-2 m-0">Plans</h3>
            {planListsData?.length === 0 ? null : (
              <Button label="Add Plan" class="btn-primary" handleBtnClick={() => navigate('/dashboard/add-plan')} />
            )}
          </div>

          <div className="row gx-md-3 gx-0">
            {planListsLoading ? (
              <Loader />
            ) : planListsError ? (
              <ErrorState />
            ) : planListsData?.length === 0 ? (
              <EmptyState message={'No plan added yet'} btnTitle={'Add Plan'} handleBtnClick={() => navigate('/dashboard/add-plan')} />
            ) : (
              planListsData?.map((item, index) => (
                <div className="col-md-6 mb-4" key={index}>
                  <PlanCard id={item?.id} title={item?.name} planBenefitList={item?.benefits} handlePlanEdit={handlePlanEdit} />
                </div>
              ))
            )}
          </div>
        </div>

        {planListsData?.length !== 0 ? (
          <div className="col-lg-5">
            {packagesListLoading ? <Loader /> : planListsError ? <ErrorState /> : <PackageCard tableContent={packagesListData} />}
          </div>
        ) : null}
      </div>
    </div>
  );
}
