import React from 'react'

const RadioButton = ({ name, label, value, register, selected, handleOnChange }) => {
  return (
    <div className="form-check mb-3">
      <input
        {...register(name)}
        className="form-check-input"
        type="radio"
        value={value}
        id={label}
        // checked={value === 'batch'}
        // onChange={(e) => handleOnChange(e.target.value)}
      />
      <label className="form-check-label" htmlFor={label}>
        {label}
      </label>
    </div>
  );
};

export default RadioButton