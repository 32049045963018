import React, { useState, useEffect } from 'react';
import { Button, LinkButton } from '../../../component/button/button';
import RegisterImage from '../../../assets/register.svg';
import Logo from '../../../assets/logo.svg';
import '../../auth/login/login.css';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import InputField from '../../../atoms/inputField/inputField';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { saveData } from '../../../redux/services/handleData/localStorage.ts';
import { toast } from 'react-toastify';

import { validateOtpAction } from '../../../redux/states/auth/auth.actions';

import * as yup from 'yup';
import { handleUseFormError } from '../../../utils/utils';

const schema = yup
  .object({
    otp: yup.string().length(4).required()
  })
  .required();

export default function MobileOtp() {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { data, error, loading } = useSelector((state) => state?.auth?.validateOtp);
  //we can use loading to set ui loader

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmit = async (data) => {
    const res = await dispatch(validateOtpAction({ ...data, mobile: state?.mobile }));
    if(res?.statusCode === 200){
      saveData('token', res?.data?.token);
      navigate('/gym-address');
      window.location.reload();
    }else{
     handleUseFormError(res);
    }
  };


  return (
    <div className="row gx-0">
      <div className="col-md-8">
        <div className="illustration-area-wrapper">
          <div className="header">
            <img alt="logo" src={Logo} />
          </div>
          <div className="illustration-area">
            <img alt="register" src={RegisterImage} />
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <div className="main-content-wrapper">
          <h5 className="header-3">OTP</h5>
          <p className="subHeading">We have sent an OTP to your mobile</p>
          <br />
          <form onSubmit={handleSubmit(onSubmit)}>
            <InputField placeholder="OTP" label="OTP" type="text" name={'otp'} maxLength={4} register={register} error={errors.otp?.message} />

            <Button class="btn-primary mt-2 w-100" label="Next" isLoading={loading} type="submit" />
          </form>
          <div className="text-center mt-3">
            <p className="subHeading">
              <Link to="/" replace={true}>
                <LinkButton label="Resend OTP?" class="text-primary subHeading" />
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
