import React, { useState, useEffect } from 'react'
import { BrowserRouter, useNavigate, Outlet, Route, Routes } from 'react-router-dom'
import ActiveMemberGraph from '../../component/active-member-graph/activeMemberGraph'
import BirthdayCard from '../../component/birthday-card/birthdayCard'
import DuePlanMembersTable from '../../component/expired-plan-members-table/expiredPlanMembersTable'
import Loader from '../../component/loader/loader'
import PlanDistributionGraph from '../../component/plan-distribution-graph/planDistributionGraph'
import StatisticsCard from '../../component/statistics-card/statisticsCard'
import './dashboard.css'
import Header from './header/header'
import SideBar from './side-bar/sideBar'
import { useDispatch, useSelector } from 'react-redux'
import { getProfileAction } from '../../redux/states/profile/profile.actions'
import RechargeSmsModal from '../../component/molecule/recharge-sms-modal/rechargeSmsModal'
import { getPlanDistributionAction, getRegisteredUsersStatsAction, getStatisticsAction, getTodaysBirthdaysAction } from '../../redux/states/dashboard/dashboard.actions'
import { getMemberListAction } from '../../redux/states/member/member.actions'
import ErrorState from '../../component/molecule/error-state/errorState'
import { getAvailableSmsCountAction } from '../../redux/states/sms/sms.actions'
import EmptyState from '../../component/molecule/empty-state/emptyState'


export default function Dashboard() {
  const [showLoader, toggleLoader] = useState(true);
  const dispatch = useDispatch();


  const { data: profileData } = useSelector(state => state?.profile?.profileData);
  const { data: availableSmsCount } = useSelector(state => state?.sms?.availableSmsCount);
  const { loading: bulkSmsLoading } = useSelector(state => state?.sms?.bulkSms);

  setTimeout(() => {
    toggleLoader(false);
  }, 5000)

  useEffect(() => {
    dispatch(getProfileAction());
  }, []);

  useEffect(() => {
    dispatch(getAvailableSmsCountAction());
  }, [bulkSmsLoading])

  const [rechargeSmsModal, setRechargeSmsModal] = useState(false);

  function handleClose() {
    setRechargeSmsModal(false);
  }

  return (
    <div className='dashboard-body'>
      <div className='side-bar-wrapper'>
        <SideBar gymLogo={profileData?.gym?.logo} gymName={profileData?.gym?.name} />
      </div>

      <div className='main-area-wrapper position-relative'>
        {/* <Header /> */}
        <div className='mb-5 mt-2'>
          <Outlet />
          <div className='sms-counter' onClick={() => { setRechargeSmsModal(true) }}>
            {availableSmsCount >= 0 && <h3 className='header-3 mb-0 fw-bold'> <span className="text-danger">{availableSmsCount}</span> SMS Left</h3>}
          </div>
        </div>
      </div>
      <RechargeSmsModal active={rechargeSmsModal} onClose={handleClose} />
    </div>
  )
}

export function DashboardContent() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data: planDistributionData } = useSelector(state => state?.dashboard?.planDistribution);
  const { data: { data: membersHavingPaymentDue }, loading: memberListLoading, error: memberListError } = useSelector(state => state?.member?.memberList);
  const { data: statisticsData, loading: statisticsLoading, error: statisticsError } = useSelector(state => state?.dashboard?.statisticsData);
  const { data: { data: todaysBirthdayList },loading: todaysBirthdayLoading, error: todaysBirthdayError } = useSelector(state => state?.dashboard?.todaysBirthdayList);


  useEffect(() => {
    dispatch(getStatisticsAction());
    dispatch(getPlanDistributionAction());
    dispatch(getMemberListAction([{ id: "DUE", name: "Payment Due", type: "Payment Status" }]));
    dispatch(getTodaysBirthdaysAction());
  }, []);


  return (
    <>
      <div className='row'>
        <div className='col-md-12'>
          {statisticsData && Object.keys(statisticsData)?.length > 0 && <StatisticsCard stats={statisticsData} />}
        </div>
      </div>
      {
        statisticsData?.totalMembers > 0 ? (
          <>
            <div className='row pt-4'>
              <div className='col-md-7 mb-4 mb-md-0'>
                <ActiveMemberGraph />
              </div>
              <div className='col-md-5'>
                {planDistributionData && Object.keys(planDistributionData)?.length > 0 && <PlanDistributionGraph distributionData={planDistributionData} />}
              </div>
            </div>
            <div className='row pt-4'>
              <div className='col-md-8 mb-4 mb-md-0'>
                {memberListLoading ? (
                  <Loader />
                ) : memberListError ? (

                  <ErrorState />
                ) : (
                  membersHavingPaymentDue?.length > 0 && <DuePlanMembersTable data={membersHavingPaymentDue?.slice(0, 5)} />
                )}
              </div>
              <div className='col-md-4'>
                {todaysBirthdayList && todaysBirthdayList?.length > 0 && <BirthdayCard members={todaysBirthdayList} />}
              </div>
            </div>
          </>
        ) : <EmptyState message={'Start adding member here and make your gym life easy with us!'} btnTitle={'Add Member'} handleBtnClick={() =>  navigate('/dashboard/add-member')} />
      }
    </>
  )
}
