import React, { useEffect, useState } from 'react'
import Avatar6 from '../../../../assets/avatar6.png'
import Camera from '../../../../assets/camera.png'
import InputField from '../../../../atoms/inputField/inputField'
import SelectField from '../../../../atoms/selectField/selectField'
import PlaceHolderUser from '../../../../assets/placeHolderUser.png';
import { Button, OutlineButton } from '../../../../component/button/button'
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form'
import CurrentPlan from '../current-plan/currentPlan';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { getCitiesAction, getCountriesAction, getStatesAction } from '../../../../redux/states/auth/auth.actions'
import MultiSelectField from '../../../../atoms/multiSelectField/multiSelectField'
import { updateProfileAction } from '../../../../redux/states/profile/profile.actions'
import { toast } from 'react-toastify'
import { handleUseFormError } from '../../../../utils/utils'
import { compressFile } from '../../../../utils/compressor';


const MAX_LOGO_SIZE = 1048576 * 2;

const schema = yup
    .object({
        ownerName: yup.string().required(),
        gymName: yup.string().required(),
        email: yup.string().email().required(),
        country: yup.object().nullable().required(),
        state: yup.object().nullable().required(),
        city: yup.object().nullable().required(),
        address: yup.string().required()
    })
    .required();


export default function EditProfile() {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();


    const profileData = location?.state?.profileData;
    const gymData = profileData?.gym;
    const addressData = gymData?.address;

    const [logoSrc, setLogoSrc] = useState(gymData?.logo ?? PlaceHolderUser);
    const [compressedLogoToSave, setCompressedLogoToSave] = useState(null);
    const [logoLoading, setLogoLoading] = useState(false);

    const {
        data: countries
    } = useSelector((state) => state?.auth?.gymAddressCountries);
    const {
        data: states
    } = useSelector((state) => state?.auth?.gymAddressStates);

    const {
        data: cities
    } = useSelector((state) => state?.auth?.gymAddressCities);

    const {
        loading: updateProfileLoading
    } = useSelector((state) => state?.profile?.updateProfile);


    const {
        register,
        handleSubmit,
        reset,
        control,
        setValue,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            ownerName: gymData?.ownerName,
            gymName: gymData?.name,
            email: profileData?.email,
            mobile: profileData?.mobile,
            address: addressData?.addressLine1,
            country: { value: addressData?.country?.id, label: addressData?.country?.name },
            state: { value: addressData?.state?.id, label: addressData?.state?.name },
            city: { value: addressData?.city?.id, label: addressData?.city?.name },
        }
    });




    const onSubmit = async (profileInfo) => {

        const formDataToSave = new FormData();

        formDataToSave.append('gymName', profileInfo?.gymName);
        formDataToSave.append('ownerName', profileInfo?.ownerName);
        formDataToSave.append('email', profileInfo?.email);
        formDataToSave.append('address', profileInfo?.address);
        formDataToSave.append('addressId', addressData?.id);
        formDataToSave.append('countryId', profileInfo?.country?.value);
        formDataToSave.append('stateId', profileInfo?.state?.value);
        formDataToSave.append('cityId', profileInfo?.city?.value);

        if (compressedLogoToSave && compressedLogoToSave?.size > 0) {
            formDataToSave.append('logo', compressedLogoToSave, compressedLogoToSave?.name);
        }

        const updateRes = await dispatch(updateProfileAction(formDataToSave));
        if (updateRes?.statusCode === 201) {
            toast.success(updateRes?.message);
            navigate('/dashboard/profile');
        } else {
            handleUseFormError(updateRes);
        }

    };

    const handleOnCountryChange = (selectedCountry) => {
        if (selectedCountry) {
            dispatch(getStatesAction(selectedCountry?.value));
        }
    }

    const handleOnStateChange = (selectedState) => {
        if (selectedState) {
            setValue('city', null);
            dispatch(getCitiesAction(selectedState?.value));
        }
    }

    useEffect(() => {
        dispatch(getCountriesAction());
        dispatch(getStatesAction(addressData?.countryId));
        dispatch(getCitiesAction(addressData?.stateId));
    }, []);

    useEffect(() => {
        //on unmount revokeObjectURL (to free memory).
        return () => {
            if (compressedLogoToSave != null) {
                URL.revokeObjectURL(logoSrc);
            }
        }

    }, [compressedLogoToSave, logoSrc]);

    const handleOnLogoChange = async (e) => {
        const rawLogo = e.target.files[0];
        if (rawLogo && rawLogo?.size > 0) {
            try {
                setLogoLoading(true);
                const compressedLogo = await compressFile(rawLogo);
                if (compressedLogo?.size > MAX_LOGO_SIZE) {
                    setLogoLoading(false);

                    toast.error(`Logo size exceeding the limit. Please pick a smaller one!`);
                } else {
                    setCompressedLogoToSave(compressedLogo);
                    setLogoSrc(URL.createObjectURL(compressedLogo));
                }
            } catch (error) {
                setLogoLoading(false)
                toast.error(error?.message || 'Something went wrong!');
            }

        }
    }

    return (
        <>
            <div className='row'>
                <div className="col-md-9 mb-4">
                    <div className='d-flex justify-content-between'>
                        <h3 className="header-2 mb-0">Profile</h3>
                    </div>
                    <div className='card mt-4'>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">
                                <div className="col-md-4 mb-4 mb-2">
                                    <div className="avatar6-wrapper">
                                        {logoLoading && <span className='spinner-edit-profile'></span>}
                                        <img src={logoSrc} className="avatar6-img" alt="gym logo" onLoad={() => setLogoLoading(false)} onError={() => setLogoLoading(false)} />
                                        <label htmlFor="upload-profile">
                                            <img src={Camera} className="camera" alt="" />
                                        </label>
                                        <input id="upload-profile" type="file" accept="image/*" onChange={handleOnLogoChange} className="upload-file-input" />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6 mb-2">
                                    <InputField
                                        placeholder="Enter Owner Name"
                                        label="Owner Name"
                                        type="text"
                                        name={'ownerName'}
                                        register={register}
                                        error={errors?.ownerName?.message}
                                    />
                                </div>
                                <div className="col-md-6 mb-2">
                                    <InputField
                                        placeholder="Enter Gym Name"
                                        label="Gym Name"
                                        type="text"
                                        name={'gymName'}
                                        register={register}
                                        error={errors?.gymName?.message}
                                    />
                                </div>
                                <div className="col-md-6 mb-2">
                                    <InputField
                                        placeholder="Enter your email"
                                        label="Email"
                                        type="email"
                                        name={'email'}
                                        register={register}
                                        error={errors?.email?.message}
                                        maxLength={40}
                                    />
                                </div>
                                <div className="col-md-6 mb-2">
                                    <InputField
                                        placeholder="Enter your mobile number"
                                        label="Mobile"
                                        type="number"
                                        name={'mobile'}
                                        register={register}
                                        isDisabled={true}
                                    />
                                </div>
                                <div className='col-md-6 mb-2'>
                                    <Controller
                                        name="country"
                                        control={control}
                                        render={({ field: { onChange, value }, fieldState }) => (
                                            <MultiSelectField
                                                label={'Country'}
                                                onChange={(selectedCountry) => {
                                                    handleOnCountryChange(selectedCountry);
                                                    onChange(selectedCountry);
                                                }}
                                                value={value}
                                                placeholder={'Select Country'}
                                                options={countries.map(country => ({ value: country?.id, label: country?.name }))}
                                                error={fieldState?.error?.message}
                                            />
                                        )}
                                    />

                                </div>
                                <div className='col-md-6 mb-2'>
                                    <Controller
                                        name="state"
                                        control={control}
                                        render={({ field: { onChange, value }, fieldState }) => (
                                            <MultiSelectField
                                                label={'State'}
                                                onChange={(selectedState) => {
                                                    handleOnStateChange(selectedState);
                                                    onChange(selectedState);
                                                }}
                                                value={value}
                                                placeholder={'Select State'}
                                                options={states.map(state => ({ value: state?.id, label: state?.name }))}
                                                error={fieldState?.error?.message}
                                            />
                                        )}
                                    />

                                </div>
                                <div className='col-md-6 mb-2'>
                                    <Controller
                                        name="city"
                                        control={control}
                                        render={({ field: { onChange, value }, fieldState }) => (
                                            <MultiSelectField
                                                label={'City'}
                                                onChange={onChange}
                                                value={value}
                                                placeholder={'Select City'}
                                                options={cities.map(city => ({ value: city?.id, label: city?.name }))}
                                                error={fieldState?.error?.message}
                                            />
                                        )}
                                    />

                                </div>
                                <div className="col-md-6 mb-2">
                                    <InputField
                                        placeholder="Enter your address"
                                        label="Address"
                                        type="text"
                                        name={'address'}
                                        register={register}
                                        error={errors.address?.message}
                                        maxLength={80}
                                    />
                                </div>
                                <div className='col-md-6 mt-3'>
                                    <Button label="Save" type="submit" isLoading={updateProfileLoading} class="btn-primary w-100 mb-4 mb-lg-0" />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="col-md-3">
                    <CurrentPlan planDetails={profileData?.gym?.plan} />
                </div>
            </div>
        </>
    )
}
