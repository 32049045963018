import React, { useEffect, useState } from 'react';
import { Button, LinkButton } from '../../../component/button/button';
import RegisterImage from '../../../assets/register.svg';
import Logo from '../../../assets/logo.svg';
import '../../auth/login/login.css';
import InputField from '../../../atoms/inputField/inputField';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { toast } from 'react-toastify';
import { saveGymLogoAction } from '../../../redux/states/auth/auth.actions';
import { compressFile } from '../../../utils/compressor';

import * as yup from 'yup';
import { handleUseFormError } from '../../../utils/utils';
import { MdLogout } from 'react-icons/md';
import { removeData } from './../../../redux/services/handleData/localStorage.ts';
import { ConfirmationModal } from './../../../component/molecule/modal/modal';

const schema = yup
  .object({
    gymLogo: yup.mixed().test('required',
      'Please select your gym logo.',
      value => {
        return value && value.length;
      })
  })
  .required();

// max limit after compression 2mb  
const MAX_LOGO_SIZE = 1048576 * 2;

export default function GymLogo() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [compressLoading, setCompressLoading] = useState(false);
  const [isLogoutModalActive, updateLogoutModal] = useState(false);


  const handleLogout = () => {
    removeData('token');
    removeData('isPaymentDone');
    navigate('/')
    window.location.reload();
  }
  


  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  });

  const {
    data: saveGymLogoData,
    error: saveGymLogoError,
    loading: saveGymLogoLoading
  } = useSelector((state) => state?.auth?.saveGymLogo);


  const onSubmit = async (data) => {
    const selectedLogo = data?.gymLogo[0];
    try {
      setCompressLoading(true);
      const compressedLogo = await compressFile(selectedLogo);
      if (compressedLogo?.size > MAX_LOGO_SIZE) {
        setCompressLoading(false);
        toast.error(`Logo size exceeding the limit. Please pick a smaller one!`);
      } else {
        setCompressLoading(false);
        const logoFormData = new FormData();
        logoFormData.append('logo', compressedLogo, compressedLogo?.name);
        const logoRes = await dispatch(saveGymLogoAction(logoFormData));
        if (logoRes?.statusCode === 200) {
          navigate('/pricing-list');
        } else {
          handleUseFormError(logoRes);
        }
      }
    } catch (error) {
      console.log(error.message);
      setCompressLoading(false);
      toast.error(error?.message || 'Something went wrong!');
    }

  };

  return (
    <div className="row gx-0">
      <div className="col-md-8">
        <div className="illustration-area-wrapper">
          <div className="header">
            <img alt="logo" src={Logo} />
          </div>
          <div className="illustration-area">
            <img alt="register" src={RegisterImage} />
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <div className="main-content-wrapper position-relative">
         <MdLogout size="24" className="logout-icon-btn" onClick={() => updateLogoutModal(true)}/>

          <h5 className="header-3">Gym Logo</h5>
          <p className="subHeading">Add your gym logo</p>
          <br />
          <form onSubmit={handleSubmit(onSubmit)}>
            <InputField
              type="file"
              name={'gymLogo'}
              register={register}
              error={errors?.gymLogo?.message}
              accept="image/*"
            />
            <Button class="btn-primary mt-2 w-100" label="Next" isLoading={saveGymLogoLoading||compressLoading} type="submit" />


            <div className="text-center mt-3">
            <p className="subHeading">
              <Link to="/pricing-list" replace={true}>
                <LinkButton label="Skip" class="text-primary subHeading" />
              </Link>
            </p>
          </div>
          </form>
        </div>
      </div>
      <ConfirmationModal
        title={'Are you sure?'}
        description={'You want to logout'}
        active={isLogoutModalActive}
        onClose={() => {
          updateLogoutModal(false);
        }}
        modalIcon={<MdLogout size={50} className="text-primary" />}
        primaryBtnClick={handleLogout}
        primaryBtnTitle={'Logout'}
        secondaryBtnTite={'Cancel'}
        secondaryBtnClick={() => {
          updateLogoutModal(false);
        }}
      />
    </div>
  );
}
