import React, { useEffect, useState } from 'react';
import { Button, LinkButton } from '../../../component/button/button';
import InputField from '../../../atoms/inputField/inputField';
import RegisterImage from '../../../assets/register.svg';
import Logo from '../../../assets/logo.svg';
import '../../auth/login/login.css';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { postRegisterAction } from '../../../redux/states/auth/auth.actions';
import { toast } from 'react-toastify';
import { handleUseFormError } from '../../../utils/utils';


const schema = yup
  .object({
    gymName: yup.string().required(),
    ownerName: yup.string().required(),
    email: yup.string().email().required(),
    mobile: yup.string().length(10).required()
  })
  .required();

export default function Register() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(schema)
  });

  const { loading: registerUserLoading } = useSelector((state) => state?.auth?.registertUser);

  //we can use loading to set ui loader

  const onSubmit = async (data) => {
    const res = await dispatch(postRegisterAction(data));
    if(res?.statusCode === 200){
      navigate('/mobile-otp', { state: { mobile: data?.mobile } });
    }else{
      handleUseFormError(res);
    }
  };


  return (
    <div className="row gx-0">
      <div className="col-md-8">
        <div className="illustration-area-wrapper">
          <div className="header">
            <img alt="logo" src={Logo} />
          </div>
          <div className="illustration-area">
            <img alt="register" src={RegisterImage} />
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <div className="main-content-wrapper">
          <h5 className="header-3">Welcome to GymHeight!</h5>
          <p className="subHeading">Make your gym management easy and fun!</p>
          <br />

          <form onSubmit={handleSubmit(onSubmit)}>
            <InputField placeholder="name" label="Gym Name" type="text" name={'gymName'} register={register} error={errors.gymName?.message} />
            <InputField
              placeholder="name"
              label="Gym Owner Name"
              type="text"
              name={'ownerName'}
              register={register}
              error={errors.ownerName?.message}
            />
            <InputField placeholder="name" label="Email" type="email" name={'email'} register={register} error={errors.email?.message} maxLength={40} />
            <InputField placeholder="name" label="Mobile Number" type="number" name={'mobile'} register={register} error={errors.mobile?.message} />

            <Button class="btn-primary mt-2 w-100" label="Next" type="submit" isLoading={registerUserLoading} isDisabled={isSubmitting} />
          </form>

          <div className="text-center mt-3">
            <p className="subHeading">
              Already have an acount?{' '}
              <Link to="/" replace={true}>
                <LinkButton label="Sign in instead" class="text-primary subHeading" />
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
