import React, { useState } from 'react';
import './addMember.css';
import Avatar6 from '../../../../assets/avatar6.png';
import PlaceHolderUser from '../../../../assets/placeHolderUser.png';
import Camera from '../../../../assets/camera.png';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import InputField from '../../../../atoms/inputField/inputField';
import { Button } from '../../../../component/button/button';
import MultiSelectField from '../../../../atoms/multiSelectField/multiSelectField';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getBatchListAction } from '../../../../redux/states/batch/batch.actions';
import { useDispatch } from 'react-redux';
import { addTrainerAction, getTrainerListAction } from '../../../../redux/states/trainer/trainer.actions';
import { getCitiesAction, getCountriesAction, getStatesAction } from '../../../../redux/states/auth/auth.actions';
import { getCurrentDate, handleUseFormError } from '../../../../utils/utils';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { getPackagesListAction } from '../../../../redux/states/plan/plan.actions';
import { addMemberAction, calculatePaymentAction, getMemberAction, updateMemberAction } from '../../../../redux/states/member/member.actions';
import { compressFile } from '../../../../utils/compressor';
import { genderOptions } from '../../../../utils/constants';
import { CALCULATE_PAYMENT_SUCCESS } from '../../../../redux/states/member/member.constants';
import { handleSuccess } from '../../../../redux/services/handleData/handleData.ts';
import CheckBox from '../../../../atoms/checkBox/checkBox';
import moment from 'moment';


const schema = yup
  .object({
    name: yup.string().required(),
    email: yup.string().email('Must be a valid email'),
    mobile: yup.string().max(10).min(10).required(),
    dob: yup.string(),
    genderVal: yup.object().required(),
    weight: yup.string(),
    height: yup.string(),
    city: yup.object().nullable(),
    state: yup.object().nullable(),
    dueAmount: yup.string(),
    country: yup.object().nullable(),
    startDate: yup.string().required(),
    address: yup.string(),
    discountFee: yup.string(),
    discountFeeNumber: yup.string(),
    discountTrainer: yup.string(),
    discountTrainerNumber: yup.string(),
    trainer: yup.object().nullable(),
    batch: yup.object().nullable(),
    package: yup.object().nullable().required(),
    prevDueAmount: yup.string()
  })
  .required();

const MAX_LOGO_SIZE = 1048576;

export default function AddMember() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { data: batchListData } = useSelector((state) => state?.batch?.batchList);
  const { data: packagesListData } = useSelector((state) => state?.plan?.packagesList);
  const { data: trainerListData } = useSelector((state) => state?.trainer?.trainerList);
  const { data: countriesData } = useSelector((state) => state?.auth?.gymAddressCountries);
  const { data: statesData } = useSelector((state) => state?.auth?.gymAddressStates);
  const { data: citiesData } = useSelector((state) => state?.auth?.gymAddressCities);
  const { data: paymentCostData } = useSelector((state) => state?.member?.paymentCost);

  const { loading: addMemberLoading } = useSelector((state) => state?.member?.addMember);

  const [logoSrc, setLogoSrc] = useState(PlaceHolderUser);
  const [compressedLogoToSave, setCompressedLogoToSave] = useState(null);
  const [isPrevDue, setIsPrevDue] = useState(false);
  const [minStartDate, setMinStartDate] = useState();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    reset,
    watch
  } = useForm({
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    setValue('startDate', getCurrentDate());
    dispatch(getBatchListAction());
    dispatch(getTrainerListAction());
    dispatch(getPackagesListAction());
    dispatch(getCountriesAction());
    dispatch(handleSuccess(CALCULATE_PAYMENT_SUCCESS, {}));
  }, [dispatch, setValue]);

  useEffect(() => {
    //on unmount revokeObjectURL (to free memory).
    return () => {
      if (compressedLogoToSave != null) {
        URL.revokeObjectURL(logoSrc);
      }
    };
  }, [compressedLogoToSave, logoSrc]);

  const packageId = watch('package')?.value;
  const trainerId = watch('trainer')?.value;
  const dueAmount = watch('dueAmount');
  const discountFee = watch('discountFee');
  const discountFeeNumber = watch('discountFeeNumber');
  const discountTrainer = watch('discountTrainer');
  const discountTrainerNumber = watch('discountTrainerNumber');

  useEffect(() => {
    console.log('discountTrainerNumber', discountTrainerNumber);
    const body = {
      packageId,
      trainerId,
      dueAmount,
      discountFee: parseFloat(((Number(discountFeeNumber) / Number(paymentCostData?.packageFee)) * 100).toFixed(2)),
      discountTrainer: parseFloat(((Number(discountTrainerNumber) / Number(paymentCostData?.trainerFee)) * 100).toFixed(2))
    };
    if (!packageId) delete body?.packageId;
    if (!trainerId) delete body?.trainerId;
    if (!dueAmount) delete body?.dueAmount;
    if (!body?.discountFee) delete body?.discountFee;
    if (!body?.discountTrainer) delete body?.discountTrainer;
    Object.keys(body)?.length && dispatch(calculatePaymentAction(body));
  }, [dispatch, packageId, trainerId, dueAmount, discountFee, discountTrainer, discountFeeNumber, discountTrainerNumber]);

  const handleOnCountryChange = (obj) => {
    const countryId = obj?.value;
    setValue('country', obj, { shouldValidate: true });
    setValue('state', {}, { shouldValidate: true });
    if (countryId) {
      dispatch(getStatesAction(countryId));
    }
  };

  const handleOnStateChange = (obj) => {
    const stateId = obj?.value;
    setValue('state', obj, { shouldValidate: true });
    setValue('city', {}, { shouldValidate: true });
    if (stateId) {
      dispatch(getCitiesAction(stateId));
    }
  };

  const handleOnPackageChange = (obj) => {

    const label = obj?.label?.trim();
    const packageMonths =  label.split(" - ")[1].replace(/[^\d.-]/g, '');
    setMinStartDate(moment().subtract(packageMonths,'months').format("YYYY-MM-DD"));
    
    setValue('package', obj);
    setValue('discountFeeNumber', '');
    setValue('discountTrainerNumber', '');
  };

  const handleOnTrainerChange = (obj) => {
    setValue('trainer', obj);
    setValue('discountTrainerNumber', '');
  };

  const onSubmit = async (data) => {
    const formData = new FormData();

    if (data?.address) formData.append('address', data?.address);
    if (data?.discountFeeNumber) formData.append('discountPackage', ((Number(data?.discountFeeNumber) / Number(paymentCostData?.packageFee)) * 100));
    if (data?.discountTrainerNumber) formData.append('discountTrainer', ((Number(data?.discountTrainerNumber) / Number(paymentCostData?.trainerFee)) * 100));
    formData.append('dob', data?.dob);
    formData.append('dueAmount', data?.dueAmount);
    formData.append('email', data?.email);
    formData.append('mobile', data?.mobile);
    if (data?.weight) formData.append('weight', data?.weight);
    formData.append('name', data?.name);
    formData.append('startDate', data?.startDate);
    if (data?.city?.value) formData.append('cityId', data?.city?.value);
    if (data?.trainer?.value) formData.append('trainerId', data?.trainer?.value);
    if (data?.country?.value) formData.append('countryId', data?.country?.value);
    if (data?.batch?.value) formData.append('batchId', data?.batch?.value);
    if (data?.state?.value) formData.append('stateId', data?.state?.value);
    formData.append('gender', data?.genderVal?.value);
    if (data?.height) formData.append('height', data?.height);
    if (data?.package?.value) formData.append('packageId', data?.package?.value);

    if (isPrevDue && data?.prevDueAmount) {
      formData.append('anyPrevDueAmount', isPrevDue);
      formData.append('prevDueAmount', data?.prevDueAmount);
    } else {
      formData.append('anyPrevDueAmount', isPrevDue);
    }

    if (compressedLogoToSave && compressedLogoToSave?.size > 0) {
      formData.append('image', compressedLogoToSave, compressedLogoToSave?.name);
    }

    // if (!discountFee) delete body?.discountFee;
    // if (!discountTrainer) delete body?.discountTrainer;
    // if (!trainerId) delete body?.trainerId;

    delete data?.genderVal;
    delete data?.country;
    delete data?.state;
    delete data?.city;
    delete data?.batch;
    delete data?.trainer;
    delete data?.package;

    const addRes = await dispatch(addMemberAction(formData));
    if (addRes?.statusCode === 201) {
      toast.success(addRes?.message);
      reset();
      navigate('/dashboard/member-list');
    } else {
      handleUseFormError(addRes);
    }
  };

  const handleOnLogoChange = async (e) => {
    const rawLogo = e.target.files[0];
    if (rawLogo && rawLogo?.size > 0) {
      try {
        const compressedLogo = await compressFile(rawLogo);
        if (compressedLogo?.size > MAX_LOGO_SIZE) {
          toast.error(`Logo size exceeding the limit. Please pick a smaller one!`);
        } else {
          setCompressedLogoToSave(compressedLogo);
          setLogoSrc(URL.createObjectURL(compressedLogo));
        }
      } catch (error) {
        toast.error(error?.message || 'Something went wrong!');
      }
    }
  };

  return (
    <>
      <div className="d-flex justify-content-start px-3">
        <h3 className="header-2 mb-3">{'Add Member'}</h3>
      </div>
      <div className="card mx-3">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="avatar6-wrapper">
            <img src={logoSrc} className="avatar6-img" alt="img" />
            <label htmlFor="upload-profile">
              <img src={Camera} className="camera" alt="" />
            </label>
            <input id="upload-profile" type="file" className="upload-file-input" accept="image/*" onChange={handleOnLogoChange} />
          </div>

          <div className="row ">
            <div className="col-md-4 p-0">
              <h1 className="header-7 mt-5">Personal Info</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 mb-3 ps-0">
              <InputField placeholder="Enter Name" label="Name" type="text" name={'name'} register={register} error={errors.name?.message} />
            </div>

            <div className="col-md-4 mb-3 ps-0">
              <InputField
                placeholder="Enter Mobile Number"
                label="Mobile"
                type="number"
                name={'mobile'}
                register={register}
                error={errors.mobile?.message}
                maxLength={10}
              />
            </div>
            <div className="col-md-4 mb-3 ps-0">
              <InputField
                placeholder="Enter Email Address"
                label="Email"
                type="email"
                name={'email'}
                register={register}
                error={errors.email?.message}
                maxLength={40}
              />
            </div>
            <div className="col-md-4 mb-3 ps-0">
              <InputField placeholder="Enter DOB" label="DOB" type="date" name={'dob'} register={register} error={errors.dob?.message} />
            </div>
            <div className="col-md-4 mb-3 ps-0">
              <Controller
                name="genderVal"
                control={control}
                render={({ field: { onChange, value }, fieldState }) => {
                  return (
                    <MultiSelectField
                      label={'gender'}
                      onChange={onChange}
                      value={value}
                      placeholder={'Select Gender'}
                      options={[
                        { label: 'Male', value: 'MALE' },
                        { label: 'Female', value: 'FEMALE' },
                        { label: 'Other', value: 'ITS COMPLICATED' }
                      ]}
                      noOptionsMessage={'Please select gender.'}
                      error={fieldState?.error?.message}
                    />
                  );
                }}
              />
            </div>
            <div className="col-md-4 mb-3 ps-0">
              <InputField
                placeholder="Enter Weight"
                label="Weight (in kg)"
                type="number"
                name={'weight'}
                register={register}
                error={errors.weight?.message}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 mb-3 ps-0">
              <InputField
                placeholder="Enter Height"
                label="Height (in cm)"
                step="0.01"
                name={'height'}
                register={register}
                error={errors.height?.message}
              />
            </div>
          </div>

          {/* address section */}
          <div className="row">
            <div className="col-md-4 my-2 p-0">
              <h1 className="header-7">Address</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 mb-4 ps-0">
              <Controller
                name="country"
                control={control}
                render={({ field: { value }, fieldState }) => (
                  <MultiSelectField
                    label={'country'}
                    onChange={handleOnCountryChange}
                    value={value}
                    placeholder={'Select country'}
                    options={countriesData?.map((country) => ({ value: country?.id, label: country?.name }))}
                    noOptionsMessage={'Please create country before adding member'}
                    error={fieldState?.error?.message}
                  />
                )}
              />
            </div>
            <div className="col-md-4 mb-4 ps-0">
              <Controller
                name="state"
                control={control}
                render={({ field: { value }, fieldState }) => (
                  <MultiSelectField
                    label={'state'}
                    onChange={handleOnStateChange}
                    value={value}
                    placeholder={'Select state'}
                    options={statesData?.map((state) => ({ value: state?.id, label: state?.name }))}
                    noOptionsMessage={'Please select country before selecting state'}
                    error={fieldState?.error?.message}
                  />
                )}
              />
            </div>
            <div className="col-md-4 mb-4 ps-0">
              <Controller
                name="city"
                control={control}
                render={({ field: { onChange, value }, fieldState }) => {
                  return (
                    <MultiSelectField
                      label={'city'}
                      onChange={onChange}
                      value={value}
                      placeholder={'Select city'}
                      options={citiesData?.map((city) => ({ value: city?.id, label: city?.name }))}
                      noOptionsMessage={'Please select state before selecting city'}
                      error={fieldState?.error?.message}
                    />
                  );
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 mb-3 ps-0">
              <InputField
                placeholder="Enter Address"
                label="Address"
                type="text"
                name={'address'}
                register={register}
                error={errors.address?.message}
                maxLength={80}
              />
            </div>
          </div>

          {/* package info */}
          <div className="row">
            <div className="col-md-4 my-2 p-0">
              <h1 className="header-7">Package Info</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 mb-4 ps-0">
              <Controller
                name="batch"
                control={control}
                render={({ field: { onChange, value }, fieldState }) => (
                  <MultiSelectField
                    label={'Batch'}
                    onChange={onChange}
                    value={value}
                    placeholder={'Select Batch'}
                    options={batchListData?.map((batch) => ({ value: batch?.id, label: batch?.name }))}
                    noOptionsMessage={'Please create batch before adding member'}
                    error={fieldState?.error?.message}
                  />
                )}
              />
            </div>


            <div className="col-md-4 mb-4 ps-0">
              <Controller
                name="package"
                control={control}
                render={({ field: { onChange, value }, fieldState }) => (
                  <MultiSelectField
                    label={'package'}
                    onChange={handleOnPackageChange}
                    value={value}
                    placeholder={'Select package'}
                    options={packagesListData?.map((pack) => ({ value: pack?.id, label: pack?.title }))}
                    noOptionsMessage={'Please create package before adding member'}
                    error={fieldState?.error?.message}
                  />
                )}
              />
            </div>

            {packageId && <div className="col-md-4 mb-4 ps-0">
              <InputField
                placeholder="Start Date"
                label="Start Date"
                type="date"
                name={'startDate'}
                min={minStartDate}
                register={register}
                error={errors.startDate?.message}
              />
            </div>}

            {packageId && (
              <div className="col-md-4 mb-4 ps-0">
                <InputField
                  placeholder="1000"
                  label="Package Discount"
                  type="number"
                  name={'discountFeeNumber'}
                  register={register}
                  error={errors.discountFeeNumber?.message}
                />
              </div>
            )}

            {packageId && (
              <div className="col-md-4 mb-4 ps-0">
                <Controller
                  name="trainer"
                  control={control}
                  render={({ field: { onChange, value }, fieldState }) => (
                    <MultiSelectField
                      label={'trainer'}
                      onChange={handleOnTrainerChange}
                      value={value}
                      placeholder={'Select trainer'}
                      options={trainerListData?.map((trainer) => ({ value: trainer?.id, label: trainer?.name }))}
                      noOptionsMessage={'Please create trainer before adding member'}
                      error={fieldState?.error?.message}
                    />
                  )}
                />
              </div>
            )}

            {trainerId && (
              <div className="col-md-4 mb-4 ps-0">
                <InputField
                  placeholder="2000"
                  label="Trainer Discount"
                  type="number"
                  name={'discountTrainerNumber'}
                  register={register}
                  error={errors.discountTrainerNumber?.message}
                />
              </div>
            )}

            <div className={'col-md-4 ps-0 ' + (isPrevDue ? 'mt-2' : '')}>
              <InputField
                placeholder="500"
                label="Due Amount"
                type="number"
                name={'dueAmount'}
                register={register}
                error={errors.dueAmount?.message}
              />
            </div>
            <div className={'col-md-4 ps-0 ' + (!isPrevDue ? 'd-flex align-items-center' : '')}>
              <div className="form-check">
                <CheckBox label={'Any Previous Due Amount ?'} checked={isPrevDue} handleChange={() => setIsPrevDue(!isPrevDue)} />
              </div>
              <div className="lh-0">
                {isPrevDue && (
                  <InputField
                    placeholder="500"
                    label=""
                    type="number"
                    name={'prevDueAmount'}
                    register={register}
                    error={errors.prevDueAmount?.message}
                  />
                )}
              </div>
            </div>
          </div>

          {paymentCostData?.total ? <h1 className="header-7">₹{parseFloat(paymentCostData?.total).toFixed(0)}</h1> : null}

          <div className="row justify-content-center mt-4">
            <div className="col-md-3">
              <Button label={'Add'} class="btn-primary me-2 w-100" isLoading={addMemberLoading} isDisabled={addMemberLoading} type="submit" />
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
