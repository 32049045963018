import { getResponseError, handleUseFormError } from '../../../utils/utils';
import { handleRequest, handleSuccess, handleFailure } from '../../services/handleData/handleData.ts';
import { GET_PLAN_DISTRIBUTION_REQUEST,GET_PLAN_DISTRIBUTION_SUCCESS,GET_PLAN_DISTRIBUTION_FAILED,GET_STATISTCS_REQUEST,GET_STATISTCS_SUCCESS,GET_STATISTCS_FAILED,GET_TODAYS_BIRTHDAY_LIST_REQUEST,GET_TODAYS_BIRTHDAY_LIST_SUCCESS,GET_TODAYS_BIRTHDAY_LIST_FAILED,GET_USERS_STATS_REQUEST,GET_USERS_STATS_SUCCESS,GET_USERS_STATS_FAILED,GET_VISITORS_STATS_REQUEST,GET_VISITORS_STATS_SUCCESS,GET_VISITORS_STATS_FAILED
} from './dashboard.constants';
import { getPlanDistributionService, getStatisticsService, getTodaysBirthdaysService, getRegisteredUsersStatsService, getRegisteredVisitorsStatsService } from './dashboard.services';

// get plan distribution
export const getPlanDistributionAction = () => async (dispatch) => {
  dispatch(handleRequest(GET_PLAN_DISTRIBUTION_REQUEST));
  try {
    const res = await getPlanDistributionService();
    if (res?.statusCode === 200) {
      dispatch(handleSuccess(GET_PLAN_DISTRIBUTION_SUCCESS, res?.data));
    } else {
      let error = getResponseError(res);
      dispatch(handleFailure(GET_PLAN_DISTRIBUTION_FAILED, error));
    }
    return res;
  } catch (ex) {
    console.log('API FAILED', ex);
    dispatch(handleFailure(GET_PLAN_DISTRIBUTION_FAILED, ex?.message));
    return ex;
  }
};


// get statistics
export const getStatisticsAction = () => async (dispatch) => {
  dispatch(handleRequest(GET_STATISTCS_REQUEST));
  try {
    const res = await getStatisticsService();
    if (res?.statusCode === 200) {
      dispatch(handleSuccess(GET_STATISTCS_SUCCESS, res?.data));
    } else {
      let error = getResponseError(res);
      dispatch(handleFailure(GET_STATISTCS_FAILED, error));
    }
    return res;
  } catch (ex) {
    console.log('API FAILED', ex);
    dispatch(handleFailure(GET_STATISTCS_FAILED, ex?.message));
    return ex;
  }
};


// get todays birthday list
export const getTodaysBirthdaysAction = () => async (dispatch) => {
  dispatch(handleRequest(GET_TODAYS_BIRTHDAY_LIST_REQUEST));
  try {
    const res = await getTodaysBirthdaysService();
    if (res?.statusCode === 200) {
      dispatch(handleSuccess(GET_TODAYS_BIRTHDAY_LIST_SUCCESS, res?.data));
    } else {
      let error = getResponseError(res);
      dispatch(handleFailure(GET_TODAYS_BIRTHDAY_LIST_FAILED, error));
    }
    return res;
  } catch (ex) {
    console.log('API FAILED', ex);
    dispatch(handleFailure(GET_TODAYS_BIRTHDAY_LIST_FAILED, ex?.message));
    return ex;
  }
};

// get users stats
export const getRegisteredUsersStatsAction = () => async (dispatch) => {
  dispatch(handleRequest(GET_USERS_STATS_REQUEST));
  try {
    const res = await getRegisteredUsersStatsService();
    if (res?.statusCode === 200) {
      dispatch(handleSuccess(GET_USERS_STATS_SUCCESS, res?.data));
    } else {
      let error = getResponseError(res);
      dispatch(handleFailure(GET_USERS_STATS_FAILED, error));
    }
    return res;
  } catch (ex) {
    console.log('API FAILED', ex);
    dispatch(handleFailure(GET_USERS_STATS_FAILED, ex?.message));
    return ex;
  }
};

// get visitors stats
export const getRegisteredVisitorsStatsAction = () => async (dispatch) => {
  dispatch(handleRequest(GET_VISITORS_STATS_REQUEST));
  try {
    const res = await getRegisteredVisitorsStatsService();
    if (res?.statusCode === 200) {
      dispatch(handleSuccess(GET_VISITORS_STATS_SUCCESS, res?.data));
    } else {
      let error = getResponseError(res);
      dispatch(handleFailure(GET_VISITORS_STATS_FAILED, error));
    }
    return res;
  } catch (ex) {
    console.log('API FAILED', ex);
    dispatch(handleFailure(GET_VISITORS_STATS_FAILED, ex?.message));
    return ex;
  }
};



