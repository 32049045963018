import React from 'react';
import './expiredPlanMembersTable.css';
import { LinkButton } from '../button/button';
import Avatar1 from '../../assets/avatar1.png';
import { Link, useNavigate } from 'react-router-dom';
import NameInitialsAvatar from '../molecule/name-initials-avatar/nameInitialsAvatar';

DuePlanMembersTable.defaultProps = {
  showHeader: true
};

export default function DuePlanMembersTable(props) {
  const { data } = props;
  const navigate = useNavigate();

  function onClick(id) {
    navigate('/dashboard/member-detail', { state: { memberId: id } });
  }

  const handleViewAllClick = () =>{
    navigate('/dashboard/member-list',{ state: { paymentStatus: "DUE" }});
  }

  return (
    <div className="table-wrapper">
      {props.showHeader && (
        <div className="table-title-wrapper d-flex justify-content-between">
          <h4 className="header-3 mb-0">Top 5 Due Members</h4>
          <LinkButton label="View All" class="text-primary subHeading"  handleBtnClick={handleViewAllClick} />
        </div>
      )}

      <div className="table-responsive">
        <table className="table table-borderless text-center">
          <thead className={'table-header-area'}>
            <tr>
              <th scope="col">
                <p className="label fw-bold mb-0 text-start ps-4">NAME</p>
              </th>
              <th scope="col">
                <p className="label fw-bold  mb-0 text-center text-nowrap">DUE AMOUNT</p>
              </th>
              <th scope="col">
                <p className="label fw-bold mb-0 text-center">STATUS</p>
              </th>
              <th scope="col">
                <p className="label fw-bold mb-0 text-center">ACTIONS</p>
              </th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, index) => {
              return (
                <tr className={'table-row'} key={index}>
                  <td>
                    <div className="d-flex ps-4">
                      {item?.user?.profilePic && (
                        <div className="small-avatar">
                          <img src={item?.user?.profilePic} />
                        </div>
                      )}
                      {!item?.user?.profilePic && <NameInitialsAvatar nameInitials={item?.user?.name[0]} />}
                      <div className="ms-3">
                        <p className="subHeading fw-bold  mb-0 text-primary text-start text-nowrap">{item?.user?.name}</p>
                        <p className="label mb-0 text-start">{item?.user?.mobile}</p>
                      </div>
                    </div>
                  </td>
                  <td>
                    <p className="package-type  mb-0 text-center">₹{item?.dueAmount}</p>
                  </td>
                  <td>
                    <p className="package-type  mb-0 text-center">{item?.status}</p>
                  </td>
                  <td>
                    <p className="label flex-grow-1 mb-0 text-center">
                      <LinkButton label="View Profile" class="text-primary label" handleBtnClick={() => onClick(item?.id)} />
                    </p>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
