import React, { useState } from 'react'
import { OutlineButton } from '../button/button'
import Avatar1 from '../../assets/avatar1.png'
import Avatar2 from '../../assets/avatar2.png'
import Avatar3 from '../../assets/avatar3.png'
import Avatar4 from '../../assets/avatar4.png'
import Avatar5 from '../../assets/avatar5.png'
import NameInitialsAvatar from '../molecule/name-initials-avatar/nameInitialsAvatar'
import SmsTemplateModal from '../molecule/sms-template-modal/smsTemplateModal'

export default function BirthdayCard({ members }) {
  // const users = [Avatar1, Avatar2, Avatar3, Avatar4, Avatar5]
  const [smsTemplateModal, setSmsTemplateModal] = useState(false);

  const handleSetSmsTemplateModal = () => {
    setSmsTemplateModal(true);
  };

  const handleClose = () => {
    setSmsTemplateModal(false);
  }
  return (
    <div className='card'>
      <div className='d-flex align-items-center justify-content-between mb-4'>
        <h3 className='header-3 mb-0'>Today's Birthday</h3>
        <OutlineButton label='Send Message' class="btn-outline-primary" handleBtnClick={handleSetSmsTemplateModal} />
      </div>
      <div>
        {members.map((member) => {
          return <div className='d-flex align-items-center mb-4' key={member?.id}>
            <div className="d-flex">
              {member?.profilePic && (
                <div className="small-avatar">
                  <img src={member?.profilePic} />
                </div>
              )}
              {!member?.profilePic && <NameInitialsAvatar nameInitials={member?.name[0]} />}
              <div className="ms-3">
                <p className="subHeading fw-bold  mb-0 text-primary text-start text-nowrap">{member?.name}</p>
                <p className="label mb-0 text-start">{member?.mobile}</p>
              </div>
            </div>
          </div>
        })
        }

      </div>
      <SmsTemplateModal
        active={smsTemplateModal}
        onClose={handleClose}
        recipients={members.map((member) => member?.id)}
        recipientType={'member'}
      />
    </div>
  )
}
