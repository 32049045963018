import "./button.css";
import loaderGif from '../../assets/loader.gif'

export function Button(props) {
  function onClick(e) {
    if (props.preventDefault) {
      e?.preventDefault();
    }
    props.handleBtnClick && props.handleBtnClick();
  }
  const defaultClass = "btn py-2 px-4 subHeading text-light";
  return (
    <button disabled={props.isLoading || props.isDisabled} onClick={onClick} className={defaultClass + ' ' + props.class} type={props.type ?? 'button'}>
      {props.isLoading ? <img src={loaderGif} height="22" width="22" /> : props.label}
    </button>
  );
}

export function LinkButton(props) {
  function onClick(e) {
    if (props.preventDefault) {
      e?.preventDefault();
    }
    props.handleBtnClick && props.handleBtnClick();
  }
  return (
    <span className={`subHeading text-primary  ${props.class}`} onClick={onClick}>
      {props.label}
    </span>
  );
}

export function OutlineButton(props) {
  function onClick(e) {
    if (props.preventDefault) {
      e?.preventDefault();
    }
    props.handleBtnClick && props.handleBtnClick();
  }
  const defaultClass = "btn p-2 ";
  return (
    <button
      disabled={props.isDisabled}
      className={props.class + " " + defaultClass}
      onClick={onClick}
      type="button"
    >
      {props.label}
    </button>
  );
}
