import React from 'react'
import './bigAmount.css'


export default function BigAmount({price = 0}) {
  return (
    <span className='amount-row'>
      <span className='rupee-symbol'>₹</span> <span className='big-amount l-h-0'>{price}</span>
    </span>
  )
}
