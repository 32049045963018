import Compressor from 'compressorjs';


export function compressFile(file) {
    return new Promise((resolve, reject) => {
        new Compressor(file, {
            quality: 0.6,
            success(result) {
                resolve(new File([result], file.name, {type: result.type}));
            },
            error(err) {
                console.log('error from compressor', err.message);
                reject(err);
            },
        });
    })

}