import React from 'react';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import { Navigate, Route, Routes } from 'react-router-dom';
import Login from './pages/auth/login/login';
import Referral from './pages/onboarding/referral/referral';
import Dashboard, { DashboardContent } from './pages/dashboard/dashboard';
import VisitorList from './pages/dashboard/visitors/visitor-list/visitorList';
import Register from './pages/onboarding/register/register';
import ErrorBoundary from './ErrorBoundary';
import AddMember from './pages/dashboard/members/add-member/addMember';
import PricingList from './pages/onboarding/pricing-list/pricingList';
import MobileOtp from './pages/onboarding/mobile-otp/mobileOtp';
import AddTrainer from './pages/dashboard/trainers/add-trainer/addTrainer';
import GymAddress from './pages/onboarding/gym-address/gymAddress';
import GymLogo from './pages/onboarding/gym-logo/gymLogo';
import EditProfile from './pages/dashboard/gym-profile/edit-profile/editProfile';
import TrainerList from './pages/dashboard/trainers/trainer-list/trainerList';
import PlanList from './pages/dashboard/plans/plan-list/planList';
import MemberList from './pages/dashboard/members/member-list/memberList';
import MemberDetail from './pages/dashboard/members/member-detail/memberDetail';
import AddPlan from './pages/dashboard/plans/add-plan/addPlan';
import Profile from './pages/dashboard/gym-profile/profile/profile';
import AddVisitor from './pages/dashboard/visitors/add-visitor/addVisitor';

import { ToastContainer } from 'react-toastify';
import EditMember from './pages/dashboard/members/add-member/editMember';
import { loadData } from './redux/services/handleData/localStorage.ts';

function App() {
  const isToken = loadData('token');
  const isPaymentDone = loadData('isPaymentDone');

  function routeList() {
    if (isToken && isPaymentDone) {
      return (
        <ErrorBoundary>
          <ToastContainer />
          <Routes>
            <Route path="dashboard" element={<Dashboard />}>
              <Route path="" element={<DashboardContent />} />
              <Route path="member-list" element={<MemberList />} />
              <Route path="member-detail" element={<MemberDetail />} />
              <Route path="visitor-list" element={<VisitorList />} />
              <Route path="add-visitor" element={<AddVisitor />} />
              <Route path="add-member" element={<AddMember />} />
              <Route path="edit-member" element={<EditMember />} />
              <Route path="trainer-list" element={<TrainerList />} />
              <Route path="add-trainer" element={<AddTrainer />} />
              <Route path="plan-list" element={<PlanList />} />
              <Route path="add-plan" element={<AddPlan />} />
              <Route path="profile" element={<Profile />} />
              <Route path="pricing-list" element={<PricingList />} />
              <Route path="edit-profile" element={<EditProfile />} />
              <Route path="*" element={<DashboardContent />} />
            </Route>
            <Route path="*" element={<Navigate to="dashboard" replace />}></Route>
          </Routes>
        </ErrorBoundary>
      );
    } else if (isToken && !isPaymentDone) {
      return (
        <ErrorBoundary>
          <ToastContainer />

          <Routes>
            <Route path="gym-address" element={<GymAddress />} />
            <Route path="referral" element={<Referral />} />
            <Route path="pricing-list" element={<PricingList />} />
            <Route path="gym-logo" element={<GymLogo />} />
            <Route path="*" element={<GymAddress />} />
          </Routes>
        </ErrorBoundary>
      );
    } else if (!isToken && !isPaymentDone) {
      return (
        <ErrorBoundary>
          <ToastContainer />

          <Routes>
            <Route path="" element={<Login />} />
            <Route path="register" element={<Register />} />
            <Route path="mobile-otp" element={<MobileOtp />} />
            <Route path="*" element={<Login />} />
          </Routes>
        </ErrorBoundary>
      );
    }
  }
  return routeList();
}

export default App;
