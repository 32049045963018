import React, { useState } from 'react';
import { AlertModal, ConfirmationModal, InfoModal } from '../../../../component/molecule/modal/modal';
import style from './packageCard.module.css';
import { MdModeEditOutline, MdOutlineLockClock } from 'react-icons/md';
import { Button } from '../../../../component/button/button';
import AddPackageModal from '../add-package-modal/addPackageModal';
import { useDispatch, useSelector } from 'react-redux';
import { getPackageAction } from '../../../../redux/states/plan/plan.actions';
import EmptyState from '../../../../component/molecule/empty-state/emptyState';
import ErrorState from '../../../../component/molecule/error-state/errorState';

export default function PackageCard(props) {
  const { tableContent, onClose } = props;
  const dispatch = useDispatch();

  const { data: planListsData } = useSelector((state) => state?.plan?.plansList);

  const [packageModal, setPackageModal] = useState(false);
  const [packageId, setPackageId] = useState('');

  function openPackageModal() {
    setPackageModal(true);
  }

  function handleClose() {
    setPackageId('');
    setPackageModal(false);
    if (onClose) onClose();
  }

  const handlePackageEdit = (id) => {
    setPackageId(id);
    dispatch(getPackageAction(id));
    openPackageModal();
  };

  return (
    <>
      <div className={style.card}>
        <div className={style.cardHeader}>
          <h4 className="header-3 mb-0">Package</h4>

          {tableContent?.length === 0 && planListsData?.length > 0 ? null : (
            <Button label={'Add Package'} class="btn btn-primary btn-block" handleBtnClick={openPackageModal} />
          )}
        </div>
        {tableContent?.length === 0 && planListsData?.length > 0 ? (
          <EmptyState message={'No package added yet'} btnTitle={'Add Package'} handleBtnClick={openPackageModal} />
        ) : (
          <div className="table-responsive">
            <table className="table table-borderless text-center">
              <thead className={style.tableHeader}>
                <tr>
                  <th scope="col">
                    <p className="label fw-bold mb-0 text-start ps-3">PLAN</p>
                  </th>
                  <th scope="col">
                    <p className="label fw-bold mb-0">MEMBERSHIPS</p>
                  </th>
                  <th scope="col">
                    <p className="label fw-bold mb-0">FEE</p>
                  </th>
                  <th scope="col">
                    <p className="label fw-bold mb-0">ACTION</p>
                  </th>
                </tr>
              </thead>
              <tbody>
                {tableContent?.map((data, index) => {
                  return (
                    <tr className={style.tableDataRow} key={index}>
                      <td>
                        <p className="label fw-bold mb-0 text-nowrap text-start ps-3">{data?.plan?.name || 'plan name'}</p>
                      </td>
                      <td>
                        <p className="label  mb-0 text-nowrap">{`${
                          data?.membershipDuration === 1 ? `${data?.membershipDuration} month` : `${data?.membershipDuration} months`
                        }`}</p>
                      </td>
                      <td>
                        <p className="label fw-bold mb-0 text-primary text-nowrap">₹{data?.fee}</p>
                      </td>
                      <td>
                        <MdModeEditOutline size={20} className="text-primary" onClick={() => handlePackageEdit(data?.id)} />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}

      </div>
      <AddPackageModal active={packageModal} onClose={handleClose} packageId={packageId} setPackageId={setPackageId} />
    </>
  );
}
