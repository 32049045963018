import { get, post } from '../../services/api/api';
import {
  baseUrl,
  trainerUrl
} from '../../services/api/apiConstants';

export const getTrainerListService = () => get(baseUrl + trainerUrl);

export const addTrainerService = (data) => post(baseUrl + trainerUrl, data);

export const updateTrainerService = (trainerId, data) => post(baseUrl + trainerUrl + trainerId , data);


