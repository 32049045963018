/* eslint-disable default-param-last */
import { combineReducers } from 'redux';
import * as Types from './member.constants';

function makeRequestReducer(actionType, defaultData = [], state = {}) {
  const initialState = {
    data: defaultData,
    error: false,
    loading: false,
    ...state
  };
  return (state = initialState, action) => {
    switch (action?.type) {
      case `${actionType}_REQUEST`:
        return {
          ...state,
          loading: true,
          error: false
        };
      case `${actionType}_SUCCESS`:
        return {
          ...state,
          loading: false,
          error: false,
          data: action?.payload?.data
        };
      case `${actionType}_FAILED`:
        return {
          ...state,
          loading: false,
          error: true,
          data: defaultData
        };
      case `${actionType}_RESET`:
        return state;
      default:
        return state;
    }
  };
}

export default combineReducers({
  membershipDurations: makeRequestReducer(Types.GET_MEMBERSHIP_DURATION),
  memberFilters: makeRequestReducer(Types.GET_MEMBER_FILTER),
  addMember: makeRequestReducer(Types.ADD_MEMBER),
  updateMember:  makeRequestReducer(Types.UPDATE_MEMBER),
  bulkAddMember: makeRequestReducer(Types.BULK_ADD_MEMBER),
  member: makeRequestReducer(Types.GET_MEMBER),
  memberList: makeRequestReducer(Types.GET_MEMBER_LIST),
  paymentCost: makeRequestReducer(Types.CALCULATE_PAYMENT, {}),
  gymPayment: makeRequestReducer(Types.GET_GYM_PAYMENT, [])
});
