import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, OutlineButton } from '../../../../component/button/button';
import CurrentPlan from '../current-plan/currentPlan';
import AttendanceQr from '../attendance-qr/attendanceQr';
import BatchCard from '../batch-card/batchCard';
import GymProfileDetail from '../profile-detail/profileDetail';
import { getProfileAction } from '../../../../redux/states/profile/profile.actions';
import Loader from '../../../../component/loader/loader';
import ErrorState from '../../../../component/molecule/error-state/errorState';
import { removeData } from '../../../../redux/services/handleData/localStorage.ts';
import { MdLogout } from 'react-icons/md';
import { ConfirmationModal } from '../../../../component/molecule/modal/modal';

export default function Profile() {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLogoutModalActive, updateLogoutModal] = useState(false);

  const handleLogout = () => {
    removeData('token');
    removeData('isPaymentDone');
    navigate('/');
    window.location.reload();
  };

  const {
    data: profileData,
    loading: profileLoading,
    error: profileError,
    errorMsg: profileErrorMsg
  } = useSelector((state) => state?.profile?.profileData);

  useEffect(() => {
    dispatch(getProfileAction());
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-lg-9">
          <div className="d-flex justify-content-between px-3 flex-wrap mb-4 align-items-center">
            <h3 className="header-2 mb-0">Profile</h3>
            <div className="d-flex ">
              {!profileLoading && (
                <OutlineButton
                  label="Edit"
                  class="btn-outline-primary px-4"
                  handleBtnClick={() => navigate('/dashboard/edit-profile', { state: { profileData } })}
                />
              )}
              <Button label={'Logout'} class="btn-primary ms-3" handleBtnClick={() => updateLogoutModal(true)} />
            </div>
          </div>
          <div className="row gx-0 gx-md-3">
            <div className="col-12">
              {profileLoading ? <Loader /> : profileError ? <ErrorState message={profileErrorMsg} /> : <GymProfileDetail profileData={profileData} />}
            </div>
          </div>
          <div className="row mt-4 gx-0 gx-md-3">
            {/* <div className="col-md-6 mb-4 mb-md-0">
              <AttendanceQr />
            </div> */}
            <div className="col-md-6 mb-4 mb-md-0">
              <BatchCard />
            </div>
          </div>
        </div>
        <div className="col-lg-3">
          {profileData?.gym?.plan && <CurrentPlan planDetails={profileData?.gym?.plan} expiredAt={profileData?.gym?.planExpiresAt} />}
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-md-9"></div>
      </div>

      <ConfirmationModal
        title={'Are you sure?'}
        description={'You want to logout'}
        active={isLogoutModalActive}
        onClose={() => {
          updateLogoutModal(false);
        }}
        modalIcon={<MdLogout size={50} className="text-primary" />}
        primaryBtnClick={handleLogout}
        primaryBtnTitle={'Logout'}
        secondaryBtnTite={'Cancel'}
        secondaryBtnClick={() => {
          updateLogoutModal(false);
        }}
      />
    </>
  );
}
