import { getResponseError } from '../../../utils/utils';
import { handleRequest, handleSuccess, handleFailure } from '../../services/handleData/handleData.ts';
import { ADD_TRAINER_FAILED, ADD_TRAINER_REQUEST, ADD_TRAINER_SUCCESS, GET_TRAINER_LIST_FAILED, GET_TRAINER_LIST_REQUEST, GET_TRAINER_LIST_SUCCESS, UPDATE_TRAINER_REQUEST, UPDATE_TRAINER_SUCCESS, UPDATE_TRAINER_FAILED } from './trainer.constants';
import { addTrainerService, getTrainerListService, updateTrainerService } from './trainer.services';

// get Trainer list
export const getTrainerListAction = () => async (dispatch) => {
  dispatch(handleRequest(GET_TRAINER_LIST_REQUEST));
  try {
    const res = await getTrainerListService();
    if (res?.statusCode === 200) {
      dispatch(handleSuccess(GET_TRAINER_LIST_SUCCESS, res?.data));
    } else {
      let error = getResponseError(res);
      dispatch(handleFailure(GET_TRAINER_LIST_FAILED, error));
    }
    return res;
  } catch (ex) {
    console.log('API FAILED', ex);
    dispatch(handleFailure(GET_TRAINER_LIST_FAILED, ex?.message));
    return ex;
  }
};



// add Trainer
export const addTrainerAction = (data) => async (dispatch) => {
  dispatch(handleRequest(ADD_TRAINER_REQUEST));
  try {
    const res = await addTrainerService(data);
    if (res?.statusCode === 201) {
      dispatch(handleSuccess(ADD_TRAINER_SUCCESS, res));
    } else {
      let error = getResponseError(res);
      dispatch(handleFailure(ADD_TRAINER_FAILED, error));
    }
    return res;
  } catch (ex) {
    console.log('API FAILED', ex);
    dispatch(handleFailure(ADD_TRAINER_FAILED, ex?.message));
    return ex;
  }
};

// update Trainer
export const updateTrainerAction = (trainerId, data) => async (dispatch) => {
  dispatch(handleRequest(UPDATE_TRAINER_REQUEST));
  try {
    const res = await updateTrainerService(trainerId, data);
    if (res?.statusCode === 200) {
      dispatch(handleSuccess(UPDATE_TRAINER_SUCCESS, res));
    } else {
      let error = getResponseError(res);
      dispatch(handleFailure(UPDATE_TRAINER_FAILED, error));
    }
    return res;
  } catch (ex) {
    console.log('API FAILED', ex);
    dispatch(handleFailure(UPDATE_TRAINER_FAILED, ex?.message));
    return ex;
  }
};


