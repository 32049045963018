import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { MdClose } from 'react-icons/md';
import InputField from '../../../../atoms/inputField/inputField';
import { Button } from '../../../../component/button/button';
import * as yup from 'yup';
import { getGymPaymentAction, getMemberAction, postGymPaymentAction } from '../../../../redux/states/member/member.actions';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { handleUseFormError } from '../../../../utils/utils';

const schema = yup
  .object({
    amount: yup.string().required(),
    notes: yup.string()
  })
  .required();

export default function AddPaymentModal({ onClose, active, memberId }) {
  const dispatch = useDispatch();

  const {
    register,
    formState: { errors, values },
    handleSubmit,
    reset
  } = useForm({
    resolver: yupResolver(schema)
  });

  function handleModalClose() {
    onClose();
  }

  const onSubmit = async (data) => {
    const addRes = await dispatch(postGymPaymentAction({ ...data, memberId }));
    if (addRes?.statusCode === 201) {
      toast.success(addRes?.message);
      reset();
      handleModalClose(false);
      dispatch(getGymPaymentAction(memberId));
      dispatch(getMemberAction(memberId));
    } else {
      handleUseFormError(addRes);
    }
  };

  return (
    <>
      <div className="modal" id="staticBackdrop" style={{ display: active ? 'block' : 'none' }}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <MdClose size={30} onClick={() => handleModalClose(false)} className="modal__icon" />
            <div className="modal-header">
              <h5 className="modal-title header-4" id="staticBackdropLabel">
                {'Add Payment'}
              </h5>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit(onSubmit)}>
                <InputField
                  placeholder="Enter Mobile Number"
                  label="Amount"
                  type="number"
                  name={'amount'}
                  register={register}
                  error={errors.number?.message}
                />

                <InputField
                  placeholder="Enter Remarks"
                  label="Remarks (Optional)"
                  type="text"
                  name={'notes'}
                  register={register}
                  error={errors.text?.message}
                />

                <Button label={'Add'} type="submit" class="btn-primary w-100 mt-4" />
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
