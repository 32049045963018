import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '../../../../component/button/button';
import Loader from '../../../../component/loader/loader';
import EmptyState from '../../../../component/molecule/empty-state/emptyState';
import ErrorState from '../../../../component/molecule/error-state/errorState';
import { formatDate } from '../../../../utils/utils';
import AddPaymentModal from '../add-payment-modal/addPaymentModal';
import './paymentListCard.css';

export default function PaymentListCard({ memberId, isDisabled }) {
  const [addPaymentModal, setAddPaymentModal] = useState(false);

  const { data: gymPaymentData = [], error: gymPaymentError, loading: gymPaymentLoading } = useSelector((state) => state?.member?.gymPayment);

  function handleClose() {
    setAddPaymentModal(false);
  }

  return (
    <>
      <div className="card-1">
        <div className="row justify-content-between gx-1 mb-5">
          <div className="col-md-6">
            <h1 className="header-7">Payments</h1>
          </div>
          {gymPaymentData?.length > 0 ? (
            <div className="col-md-6">
              <Button label="Add Payments" isDisabled={isDisabled} class="btn-primary w-100" handleBtnClick={() => setAddPaymentModal(true)} />
            </div>
          ) : null}
        </div>

        {gymPaymentLoading ? (
          <Loader />
        ) : gymPaymentError ? (
          <ErrorState />
        ) : gymPaymentData?.length === 0 ? (
          <EmptyState message={'No payment added yet'} btnTitle={'Add payment'} handleBtnClick={() => setAddPaymentModal(true)} isDisabled={isDisabled} />
        ) : (
          gymPaymentData?.map((item) => (
            <div className="row justify-content-between txn-wrapper mt-4">
              <div className="col-6 p-0">
                <p className="label fw-bold m-0">Cash</p>
                <p className="label m-0">{formatDate(item?.createdAt)}</p>
              </div>
              <div className="col-6 p-0">
                <p className="text-end paid-wrapper m-0">₹{item?.amount}</p>
              </div>
              {item?.notes ? <p className="label mb-0 mt-1 fw-bold">*Remark: {item?.notes}</p> : null}
            </div>
          ))
        )}

        {/* <div className="row justify-content-between txn-wrapper mt-4">
          <div className="col-6 p-0">
            <p className="label fw-bold m-0">Online</p>
            <p className="label m-0">24/02/2022</p>
          </div>
          <div className="col-6 p-0">
            <p className="text-end paid-wrapper m-0">$500</p>
            <p className="text-end label m-0">Txn Id: 23459irndmd</p>
          </div>
          <p className="label mb-0 mt-1 fw-bold">*Remark: This payment is added just to make 5 days fees to zero and to start new plan </p>
        </div> */}

        {/* <div className="row justify-content-between txn-wrapper mt-4">
          <div className="col-6 p-0">
            <p className="label fw-bold m-0">Online</p>
            <p className="label m-0">24/02/2022</p>
          </div>
          <div className="col-6 p-0">
            <p className="text-end paid-wrapper m-0">$500</p>
            <p className="text-end label m-0">Txn Id: 23459irndmd</p>
          </div>
        </div> */}
      </div>
      <AddPaymentModal active={addPaymentModal} onClose={handleClose} memberId={memberId} />
    </>
  );
}
