export const GET_MEMBERSHIP_DURATION = 'GET_MEMBERSHIP_DURATION';
export const GET_MEMBERSHIP_DURATION_REQUEST = 'GET_MEMBERSHIP_DURATION_REQUEST';
export const GET_MEMBERSHIP_DURATION_SUCCESS = 'GET_MEMBERSHIP_DURATION_SUCCESS';
export const GET_MEMBERSHIP_DURATION_FAILED = 'GET_MEMBERSHIP_DURATION_FAILED';

export const GET_MEMBER_FILTER = 'GET_MEMBER_FILTER';
export const GET_MEMBER_FILTER_REQUEST = 'GET_MEMBER_FILTER_REQUEST';
export const GET_MEMBER_FILTER_SUCCESS = 'GET_MEMBER_FILTER_SUCCESS';
export const GET_MEMBER_FILTER_FAILED = 'GET_MEMBER_FILTER_FAILED';

export const GET_MEMBER_LIST = 'GET_MEMBER_LIST';
export const GET_MEMBER_LIST_REQUEST = 'GET_MEMBER_LIST_REQUEST';
export const GET_MEMBER_LIST_SUCCESS = 'GET_MEMBER_LIST_SUCCESS';
export const GET_MEMBER_LIST_FAILED = 'GET_MEMBER_LIST_FAILED';

export const GET_MEMBER = 'GET_MEMBER';
export const GET_MEMBER_REQUEST = 'GET_MEMBER_REQUEST';
export const GET_MEMBER_SUCCESS = 'GET_MEMBER_SUCCESS';
export const GET_MEMBER_FAILED = 'GET_MEMBER_FAILED';

export const ADD_MEMBER = 'ADD_MEMBER';
export const ADD_MEMBER_REQUEST = 'ADD_MEMBER_REQUEST';
export const ADD_MEMBER_SUCCESS = 'ADD_MEMBER_SUCCESS';
export const ADD_MEMBER_FAILED = 'ADD_MEMBER_FAILED';

export const BULK_ADD_MEMBER = 'BULK_ADD_MEMBER';
export const BULK_ADD_MEMBER_REQUEST = 'BULK_ADD_MEMBER_REQUEST';
export const BULK_ADD_MEMBER_SUCCESS = 'BULK_ADD_MEMBER_SUCCESS';
export const BULK_ADD_MEMBER_FAILED = 'BULK_ADD_MEMBER_FAILED';

export const UPDATE_MEMBER = 'UPDATE_MEMBER';
export const UPDATE_MEMBER_REQUEST = 'UPDATE_MEMBER_REQUEST';
export const UPDATE_MEMBER_SUCCESS = 'UPDATE_MEMBER_SUCCESS';
export const UPDATE_MEMBER_FAILED = 'UPDATE_MEMBER_FAILED';

export const CALCULATE_PAYMENT = 'CALCULATE_PAYMENT';
export const CALCULATE_PAYMENT_REQUEST = 'CALCULATE_PAYMENT_REQUEST';
export const CALCULATE_PAYMENT_SUCCESS = 'CALCULATE_PAYMENT_SUCCESS';
export const CALCULATE_PAYMENT_FAILED = 'CALCULATE_PAYMENT_FAILED';

export const GET_GYM_PAYMENT = 'GET_GYM_PAYMENT';
export const GET_GYM_PAYMENT_REQUEST = 'GET_GYM_PAYMENT_REQUEST';
export const GET_GYM_PAYMENT_SUCCESS = 'GET_GYM_PAYMENT_SUCCESS';
export const GET_GYM_PAYMENT_FAILED = 'GET_GYM_PAYMENT_FAILED';

export const UPDATE_MEMBER_BATCH = 'UPDATE_MEMBER_BATCH';
export const UPDATE_MEMBER_BATCH_REQUEST = 'UPDATE_MEMBER_BATCH_REQUEST';
export const UPDATE_MEMBER_BATCH_SUCCESS = 'UPDATE_MEMBER_BATCH_SUCCESS';
export const UPDATE_MEMBER_BATCH_FAILED = 'UPDATE_MEMBER_BATCH_FAILED';

export const UPDATE_MEMBER_PACKAGE = 'UPDATE_MEMBER_PACKAGE';
export const UPDATE_MEMBER_PACKAGE_REQUEST = 'UPDATE_MEMBER_PACKAGE_REQUEST';
export const UPDATE_MEMBER_PACKAGE_SUCCESS = 'UPDATE_MEMBER_PACKAGE_SUCCESS';
export const UPDATE_MEMBER_PACKAGE_FAILED = 'UPDATE_MEMBER_PACKAGE_FAILED';

export const UPDATE_MEMBER_TRAINER = 'UPDATE_MEMBER_TRAINER';
export const UPDATE_MEMBER_TRAINER_REQUEST = 'UPDATE_MEMBER_TRAINER_REQUEST';
export const UPDATE_MEMBER_TRAINER_SUCCESS = 'UPDATE_MEMBER_TRAINER_SUCCESS';
export const UPDATE_MEMBER_TRAINER_FAILED = 'UPDATE_MEMBER_TRAINER_FAILED';