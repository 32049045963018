import { get,post } from '../../services/api/api';
import {
  baseUrl,
  profileUrl
} from '../../services/api/apiConstants';

export const getProfileService = () => get(baseUrl + profileUrl);

export const updateProfileService = (data) => post(baseUrl + profileUrl, data);




