import React from 'react';

const SelectField = ({ label, firstOption, options, register, name, error, handleOnChange, top=false }) => {

  function onChange(e) {
    handleOnChange && handleOnChange(e);
  }

  return (
    <div className="mb-2">
      <label htmlFor="exampleFormControlInput1" className="form-label label-resize">
        {label}
      </label>
      <select
        {...register(name)}
        className={error ? 'form-control form-error form-select ' : 'form-select form-control1'}
        id="exampleFormControlInput1"
        onChange={onChange}
      >
        {(top || firstOption) && <option value="">{firstOption || 'Please Select'}</option>}
        {options?.map((item) => (
          <option key={item.value} value={item.value}>
            {item?.label}
          </option>
        ))}
      </select>
      {error ? <span className="error text-danger label">{error}</span> : null}
    </div>
  );
};

export default SelectField;
