//get sms templates
export const GET_SMS_TEMPLATES = 'GET_SMS_TEMPLATES';
export const GET_SMS_TEMPLATES_REQUEST = 'GET_SMS_TEMPLATES_REQUEST';
export const GET_SMS_TEMPLATES_SUCCESS = 'GET_SMS_TEMPLATES_SUCCESS';
export const GET_SMS_TEMPLATES_FAILED = 'GET_SMS_TEMPLATES_FAILED';

// send sms
export const SEND_SMS = 'SEND_SMS';
export const SEND_SMS_REQUEST = 'SEND_SMS_REQUEST';
export const SEND_SMS_SUCCESS = 'SEND_SMS_SUCCESS';
export const SEND_SMS_FAILED = 'SEND_SMS_FAILED';

//get sms pricing
export const GET_SMS_PRICING = 'GET_SMS_PRICING';
export const GET_SMS_PRICING_REQUEST = 'GET_SMS_PRICING_REQUEST';
export const GET_SMS_PRICING_SUCCESS = 'GET_SMS_PRICING_SUCCESS';
export const GET_SMS_PRICING_FAILED = 'GET_SMS_PRICING_FAILED';


//get available sms count
export const GET_SMS_COUNT = 'GET_SMS_COUNT';
export const GET_SMS_COUNT_REQUEST = 'GET_SMS_COUNT_REQUEST';
export const GET_SMS_COUNT_SUCCESS = 'GET_SMS_COUNT_SUCCESS';
export const GET_SMS_COUNT_FAILED = 'GET_SMS_COUNT_FAILED';
