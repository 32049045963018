import React from 'react';
import { MdClose } from 'react-icons/md';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Button } from '../../../../component/button/button';
import InputField from '../../../../atoms/inputField/inputField';
import { useSelector } from 'react-redux';
import SelectField from '../../../../atoms/selectField/selectField';
import { useEffect } from 'react';
import { addPackagesAction, getPackagesListAction, updatePackageAction } from '../../../../redux/states/plan/plan.actions';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { getMembershipDurationsAction } from '../../../../redux/states/member/member.actions';
import { handleUseFormError } from '../../../../utils/utils';

const schema = yup
  .object({
    plan: yup.number().required(),
    membership: yup.number().required(),
    fee: yup.number().required()
  })
  .required();

export default function AddPackageModal({ onClose, active, packageId }) {
  const dispatch = useDispatch();

  const { plansList: { data: planListsData = [] } = {}, package: { data: packageData, error: packageError } = {} } = useSelector(
    (state) => state.plan
  );

  const { data: membershipDurationsData } = useSelector((state) => state?.member?.membershipDurations);

  const {loading: addPackageLoading} = useSelector(state => state?.plan?.addPackage);

  const {loading: updatePackageLoading} = useSelector(state => state?.plan?.updatePackage);

  useEffect(() => {
    // get all membership duration
    dispatch(getMembershipDurationsAction());
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset
  } = useForm({
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    if (!packageError && packageId) {
      setValue('plan', packageData?.planId);
      setValue('fee', packageData?.fee);
      setValue('membership', packageData?.membershipDuration);
    } else {
      reset();
    }
  }, [packageId, packageError, packageData]);

  const onSubmit = async (data) => {
    const obj = {
      planId: data?.plan,
      membershipDuration: data?.membership,
      fee: data?.fee
    };

    if(packageId){
      const res = await dispatch(updatePackageAction(packageId, obj));
      if (res?.statusCode === 201) {
        dispatch(getPackagesListAction());
        toast.success('Package updated successfully.');
        onClose();
      } else {
        handleUseFormError(res);
      }
      return
    }

    const res = await dispatch(addPackagesAction(obj));
    if (res?.statusCode === 201) {
      toast.success('Packages added successfully.');
      reset();
      onClose();
      dispatch(getPackagesListAction());
    } else {
      handleUseFormError(res);
    }
  };

  const handlePlanChange = (e) => {
    setValue(e.target.name, e.target.value, { shouldValidate: true });
  };

  return (
    <div className="modal" id="staticBackdrop" style={{ display: active ? 'block' : 'none' }}>
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <MdClose size={30} onClick={onClose} className="modal__icon" />
          <div className="modal-header">
            <h5 className="modal-title header-4" id="staticBackdropLabel">
              {packageId ? 'Update Package' : 'Create Package'}
            </h5>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-md-12">
                  <SelectField
                    label={'Plan'}
                    options={planListsData.map((planItem) => ({ value: planItem?.id, label: planItem?.name }))}
                    name={'plan'}
                    register={register}
                    error={errors.plan?.message}
                    handleOnChange={handlePlanChange}
                    top={true}
                  />
                </div>

                <div className="col-md-12">
                  <SelectField
                    label={'Membership'}
                    options={membershipDurationsData?.map((duration) => ({ value: duration?.value, label: duration?.key }))}
                    name={'membership'}
                    register={register}
                    error={errors.membership?.message}
                    handleOnChange={handlePlanChange}
                    top={true}
                  />
                </div>

                <div className="col-md-12 mb-3">
                  <InputField placeholder="1000" label="Fee" type="text" name={'fee'} register={register} error={errors.fee?.message} />
                </div>

                <div className="col-md-12 mb-2">
                  <Button label={packageId ? 'Update' : 'Create'} isLoading={addPackageLoading || updatePackageLoading } isDisabled={addPackageLoading || updatePackageLoading } type="submit" class="btn-primary w-100" />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
