import axios from 'axios';
import { BASE_URL } from '../../../utils/config';
import { loadData } from '../../services/handleData/localStorage.ts';

export function post(url, data) {
  delete axios.defaults.headers.common['Origin'];
  return makeAPIRequest('POST', url, data)
    .then((res) => res?.data)
    .catch((e) => e?.response?.data);
}

export function put(url, data) {
  return makeAPIRequest('PUT', url, data)
    .then((res) => res?.data)
    .catch((e) => e?.response?.data);;
}

export function patch(url, data) {
  return makeAPIRequest('PATCH', url, data)
    .then((res) => res?.data)
    .catch((e) => e?.response?.data);
}


export function get(url, data) {
  return makeAPIRequest('GET', url, data)
    .then((res) => res?.data)
    .catch((e) => e?.response?.data);
}

export function delReq(url) {
  return makeAPIRequest('DELETE', url).then((res) => res?.data);
}

async function makeAPIRequest(method, url, data) {
  const token = loadData('token');
  const tokenBearer = token ? `Bearer ${token}`: '';

  const config = {
    method,
    url: `${BASE_URL}/${url}`,
    headers: tokenBearer
      ? {
          Authorization: tokenBearer,
          'Content-Type': 'application/json',
          // 'Access-Control-Allow-Origin': '*',
          // 'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
        }
      : {
          'Content-Type': 'application/json'
          // 'Access-Control-Allow-Origin': '*',
          // 'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
        },
    data
  };
  return axios(config);
}

