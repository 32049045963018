import React from 'react';
import Avatar0 from '../../../assets/avatar0.png';
import PlaceHolderUser from '../../../assets/placeHolderUser.png';
import Right0 from '../../../assets/right0.png';
import SmallIcon from '../../../assets/smallicon.png';
import { formatDate } from '../../../utils/utils';
import { Button } from '../../button/button';
import './memberProfile.css';

const MEMBER_STATUS = {
  LEFT: 'Left',
  ACTIVE: 'Active',
  WON: 'Won'
};

export default function MemberProfile({ height, weight, data, status, createdAt } = {}) {
  const { name, email, mobile, profilePic } = data;
  return (
    <>
      <div className="card-1">
        <div className="row">
          <div className="col-lg-8 p-0">
            <div className="row">
              <div className="col-lg-3 mb-4 mt-lg-0">
                <div className="trainer-img-wrapper">
                  <img src={profilePic || PlaceHolderUser} className="trainer-img" />
                </div>
              </div>
              <div className="col-lg-8">
                <div className="">
                  <h1 className="header-7 m-0">{name}</h1>
                  <p className="subHeading">{email}</p>
                  <div className="row">
                    <div className="col-lg-5 d-flex p-0">
                      <div>
                        <img src={Right0} alt="" className="right0" />
                      </div>
                      <div className="ps-2">
                        <p className="header-7 m-0">{MEMBER_STATUS[status]}</p>
                        <p className="label">Status</p>
                      </div>
                    </div>

                    <div className="col-lg-5 d-flex p-0">
                      <div>
                        <img src={SmallIcon} className="small-icon" />
                      </div>
                      <div className="ps-2">
                        <p className="header-7 m-0">{mobile}</p>
                        <p className="label">Mobile</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {data?.address?.addressLine1 && <div className="row">
              <div className="col-8 ps-3 ">
                <p className="header-8 text-start mb-1">Address</p>
                <p className="package-type text-start">{data?.address?.addressLine1}</p>
              </div>
            </div>}
          </div>

          <div className="col-lg-4 p-0">
            <div className="d-flex justify-content-between"></div>
            <div className="row gx-0">
             
              {data?.address?.city?.name && <div className="col-6">
                <p className="header-8 ps-2 text-start">City</p>
              </div>}
              {data?.address?.city?.name && <div className="col-6 ">
                <p className="package-type text-end">{data?.address?.city?.name}</p>
              </div>}
              {data?.address?.state?.name && <div className="col-6">
                <p className="header-8 ps-2 text-start">State</p>
              </div>}
              {data?.address?.state?.name && <div className="col-6 ">
                <p className="package-type text-end">{data?.address?.state?.name}</p>
              </div>}
              {data?.address?.country?.name && <div className="col-6">
                <p className="header-8 ps-2 text-start">Country</p>
              </div>}
              {data?.address?.country?.name && <div className="col-6 ">
                <p className="package-type text-end">{data?.address?.country?.name}</p>
              </div>}

             <div className="col-6">
                <p className="header-8 ps-2 text-start">Height</p>
              </div>
             <div className="col-6 ">
                <p className="package-type text-end">{height} cm</p>
              </div>

               <div className="col-6">
                <p className="header-8 ps-2 text-start">Weight</p>
              </div>
               <div className="col-6 ">
                <p className="package-type text-end">{weight} kg</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
