import { combineReducers } from 'redux';
import * as Types from './profile.constants';

function makeRequestReducer(actionType, defaultData = [], state = {}) {
  const initialState = {
    data: defaultData,
    error: false,
    loading: false,
    errorMsg: '',
    ...state,
  };
  return (state = initialState, action) => {
    switch (action?.type) {
      case `${actionType}_REQUEST`:
        return {
          ...state,
          loading: true,
          error: false,
        };
      case `${actionType}_SUCCESS`:
        return {
          ...state,
          loading: false,
          error: false,
          data: action?.payload?.data,
          errorMsg: ''
        };
      case `${actionType}_FAILED`:
        return {
          ...state,
          loading: false,
          error: true,
          data: defaultData,
          errorMsg: action?.payload?.error
        };
      case `${actionType}_RESET`:
        return state;
      default:
        return state;
    }
  };
}

export default combineReducers({
  profileData: makeRequestReducer(Types.GET_PROFILE,{}),
  updateProfile: makeRequestReducer(Types.UPDATE_PROFILE,{})
});
