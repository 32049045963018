import React, { useState } from 'react';
import './visitorMembersTable.css';
import { MdOutlineMode, MdMailOutline, MdOutlineModeEditOutline, MdArrowDropDown } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { LinkButton } from '../../../../component/button/button';
import VisitorStatusUpdateModal from '../visitor-status-update-modal/visitorStatusUpdateModal';
import NameInitialsAvatar from '../../../../component/molecule/name-initials-avatar/nameInitialsAvatar';
import { VisitorStatus } from '../../../../utils/enums.ts';

VisitorMembersTable.defaultProps = {
  showHeader: true
};

export default function VisitorMembersTable({ data, onClose, onClick, showHeader, handleSms }) {
  const navigate = useNavigate();

  const [isModalActive, updateModal] = useState(false);
  const [updateId, setUpdateId] = useState('');

  function handleClose() {
    updateModal(false);
    if (onClose) onClose();
  }

  function openVisitorStatusModal(id) {
    setUpdateId(id);
    updateModal(true);
    if (onClick) onClick();
  }

  const handleEditVistor = (id) => {
    navigate('/dashboard/add-visitor', { state: { visitorId: id } });
  };

  const handleSingleSms = (itemId) => {
    handleSms(itemId);
  };

  return (
    <div className="table-wrapper">
      {showHeader && (
        <div className="table-title-wrapper d-flex justify-content-between">
          <h4 className="header-3 mb-0">Expired Plan Members</h4>
          <LinkButton label="View All" class="text-primary subHeading" />
        </div>
      )}
      <div className="table-responsive">
        <table className="table table-borderless text-center">
          <thead className={'table-header-area'}>
            <tr>
              <th scope="col">
                <p className="label fw-bold mb-0 text-start ps-4">NAME</p>
              </th>
              <th scope="col">
                <p className="label fw-bold  mb-0 text-center">PLAN</p>
              </th>
              <th scope="col">
                <p className="label fw-bold mb-0 text-center">STATUS</p>
              </th>
              <th scope="col">
                <p className="label fw-bold mb-0 text-center">ACTIONS</p>
              </th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, index) => {
              return (
                <tr className={'table-row'} key={index}>
                  <td>
                    <div className="d-flex ps-4">
                      <NameInitialsAvatar nameInitials={item?.name[0]} />
                      <div className="ms-3">
                        <p className="subHeading fw-bold  mb-0 text-primary text-start text-nowrap">{item?.name}</p>
                        <p className="label mb-0 text-start">{item?.mobile}</p>
                      </div>
                    </div>
                  </td>
                  <td>
                    <p className="package-type text-nowrap mb-0 text-center">Cardio</p>
                  </td>
                  <td>
                    <div className={`status-edit-wrapper ${VisitorStatus[item?.status] === VisitorStatus.ACTIVE ? 'active-status': ''} ${VisitorStatus[item?.status] === VisitorStatus.WON ? 'won-status': ''} ${VisitorStatus[item?.status] === VisitorStatus.CLOSED ? 'closed-status': ''}`} onClick={() => openVisitorStatusModal(item?.id)}>
                      <p className="mb-0 text-center text-nowrap">
                        {VisitorStatus[item?.status]} <MdArrowDropDown size={24} className="light-gray-text" />
                      </p>
                    </div>
                  </td>
                  <td className="text-nowrap">
                    <MdMailOutline size={24} onClick={() => handleSingleSms(item?.id)} className=" mx-2" />
                    <MdOutlineModeEditOutline onClick={() => handleEditVistor(item?.id)} size={24} className="mx-2" />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <VisitorStatusUpdateModal active={isModalActive} onClose={handleClose} updateId={updateId} />
    </div>
  );
}
