import { combineReducers } from 'redux';
import * as Types from './auth.constants';

function makeRequestReducer(actionType, defaultData = [], state = {}) {
  const initialState = {
    data: defaultData,
    error: false,
    loading: false,
    ...state
  };
  return (state = initialState, action) => {
    switch (action?.type) {
      case `${actionType}_REQUEST`:
        return {
          ...state,
          loading: true,
          error: false
        };
      case `${actionType}_SUCCESS`:
        return {
          ...state,
          loading: false,
          error: false,
          data: action?.payload?.data
        };
      case `${actionType}_FAILED`:
        return {
          ...state,
          loading: false,
          error: true,
          data: action?.payload?.error
        };
      case `${actionType}_RESET`:
        return initialState;
      default:
        return state;
    }
  };
}

export default combineReducers({
  alertData: makeRequestReducer(Types.LOAD_ALERT_DATA),
  validateOtp: makeRequestReducer(Types.VALIDATE_OTP),
  registertUser: makeRequestReducer(Types.REGISTER_USER),
  postLogin: makeRequestReducer(Types.LOGIN_USER, {}),
  gymAddressCountries: makeRequestReducer(Types.GYM_ADDRESS_COUNTRIES,[]),
  gymAddressStates:makeRequestReducer(Types.GYM_ADDRESS_STATES,[]),
  gymAddressCities: makeRequestReducer(Types.GYM_ADDRESS_CITIES,[]),
  saveAddress: makeRequestReducer(Types.SAVE_ADDRESS,{}),
  saveGymLogo: makeRequestReducer(Types.GYM_LOGO,{}),
  pricingListData: makeRequestReducer(Types.PRICING_LIST),
  validateReferralData: makeRequestReducer(Types.VALIDATE_REFERRAL,{}),
  createOrderData: makeRequestReducer(Types.CREATE_ORDER,{})
});
