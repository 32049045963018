import React from 'react'
import './planCard.css'
import Edit1 from '../../../assets/edit1.png'
import { MdModeEditOutline } from 'react-icons/md'

export default function PlanCard({ id, title, planBenefitList, handlePlanEdit }) {
  return (
    <div className="plan-card">
      <div className="d-flex justify-content-between">
        <div className="d-flex align-items-center">
          <p className="plan-type-circle me-2"></p>
          <p className="plan-type">{title}</p>
        </div>
        <MdModeEditOutline size={20} className="text-primary" onClick={() => handlePlanEdit(id)} />
      </div>

      {planBenefitList.map((item, index) => {
        return (
          <div className="d-flex align-items-center mb-1" key={index}>
            <p className="plan-type-circle1 me-2"></p>
            <p className="package-type">{item?.title}</p>
          </div>
        );
      })}

      {/* {props.planBenefitList.map((data) => {
        return <p>{data}</p>
      })} */}
    </div>
  );
}
