import React from 'react';
import InputField from '../../../../atoms/inputField/inputField';
import { MdClose } from 'react-icons/md';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Button } from '../../../../component/button/button';
import SelectField from '../../../../atoms/selectField/selectField';
import { useDispatch, useSelector } from 'react-redux';
import { getVisitorAction, getVisitorsListAction, updateStatusAction } from '../../../../redux/states/visitor/visitor.actions';
import { useEffect } from 'react';
import MultiSelectField from '../../../../atoms/multiSelectField/multiSelectField';
import { toast } from 'react-toastify';

const schema = yup
  .object({
    status: yup.object().required()
  })
  .required();

  const visitorState = [
    { label: 'Active', value: 'ACTIVE' },
    { label: 'Won', value: 'WON' },
    { label: 'Closed', value: 'CLOSED' }
  ]

export default function VisitorStatusUpdateModal({ updateId, active, onClose }) {
  const dispatch = useDispatch();

  const { data: visitorData } = useSelector((state) => state?.visitor?.visitor);

  useEffect(() => {
    if (updateId) {
      dispatch(getVisitorAction(updateId));
    }
  }, [updateId]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control
  } = useForm({
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    if (updateId) {
      const tempLabel = visitorState?.find((item) => item?.value === visitorData?.status);
      setValue('status', {label:tempLabel?.label, value: visitorData?.status});
    }
  }, [updateId, visitorData]);

  const onSubmit = async (data) => {
    if (updateId) {
      const res = await dispatch(updateStatusAction(updateId, data?.status?.value));
      if(res?.statusCode === 200){
        toast.success('Status updated successfully')
        onClose();
        dispatch(getVisitorsListAction());
      }else{
        toast.error('Something went wrong');
      }
    }
  };

  return (
    <>
      <div className="modal" id="staticBackdrop" style={{ display: active ? 'block' : 'none' }}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <MdClose size={30} onClick={onClose} className="modal__icon" />
            <div className="modal-header">
              <h5 className="modal-title header-4" id="staticBackdropLabel">
                Update Visitor
              </h5>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <Controller
                      name="status"
                      control={control}
                      render={({ field: { onChange, value }, fieldState }) => {
                        return (
                          <MultiSelectField
                            label={'status'}
                            onChange={onChange}
                            value={value}
                            placeholder={'Select status'}
                            options={visitorState}
                            noOptionsMessage={'Please select status.'}
                            error={fieldState?.error?.message}
                          />
                        );
                      }}
                    />
                  </div>

                  <div className="col-md-12 mb-2">
                    <Button label="Update" type="submit" class="btn-primary w-100" />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
