import * as React from 'react';
import './modal.css';
import { MdClose } from 'react-icons/md';
import { Button, OutlineButton } from '../../button/button';

export function InfoModal(props) {
  return (
    <div className={`modal text-center`} role="dialog" style={{ display: props.active ? 'block' : 'none' }}>
      <div className={`modal-dialog`}>
        <div className="modal-content p-md-5 p-4">
          {!props.removeCloseBtn && <MdClose size={30} onClick={props.onClose} className="modal__icon" />}
          <div>
            <div>
              <div className="my-4">{props.modalIcon && props.modalIcon}</div>
              {props.title && <h4 className="heading-4 mb-2">{props.title}</h4>}
              <p className="subHeading">{props.description}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function AlertModal(props) {
  return (
    <div className={`modal`} role="dialog" style={{ display: props.active ? 'block' : 'none' }}>
      <div className={`modal-dialog`}>
        <div className="modal-content p-md-5 p-4">
          {!props.removeCloseBtn && <MdClose size={30} onClick={props.onClose} className="modal__icon" />}
          <div>
            <div className="text-center">
              <div className="my-4">{props.modalIcon && props.modalIcon}</div>
              {props.title && <h4 className="heading-4 mb-2">{props.title}</h4>}
              <p className="subHeading">{props.description}</p>
            </div>
          </div>
          <Button label={props.primaryBtnTitle} class="btn btn-primary mt-md-4 mt-3" handleBtnClick={props.primaryBtnClick} />
        </div>
      </div>
    </div>
  );
}

export function ConfirmationModal(props) {
  return (
    <div className={`modal`} role="dialog" style={{ display: props.active ? 'block' : 'none' }}>
      <div className={`modal-dialog`}>
        <div className="modal-content p-md-5 p-4">
          {!props.removeCloseBtn && <MdClose size={30} onClick={props.onClose} className="modal__icon" />}
          <div>
            <div className="text-center">
              <div className="my-4">{props.modalIcon && props.modalIcon}</div>
              {props.title && <h4 className="heading-4 mb-2">{props.title}</h4>}
              <p className="subHeading">{props.description}</p>
            </div>
          </div>
          <div className="row mt-md-4 mt-3">
            <div className="col-lg-6 mb-lg-0 mb-3">
              <Button
                label={props.primaryBtnTitle}
                class={`${props?.type === 'danger' ? 'btn-danger' : 'btn-primary'} btn w-100`}
                handleBtnClick={props.primaryBtnClick}
              />
            </div>
            <div className="col-lg-6">
              <OutlineButton label={props.secondaryBtnTite} class="btn-outline-primary w-100" handleBtnClick={props.secondaryBtnClick} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
