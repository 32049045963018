import React from 'react';
import PackageDetail from '../package-detail/packageDetail';
import ProfileDetail from '../../../dashboard/gym-profile/profile-detail/profileDetail';
import { Button, OutlineButton } from '../../../../component/button/button';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getGymPaymentAction, getMemberAction, updateMemberStatusAction } from '../../../../redux/states/member/member.actions';
import { useSelector } from 'react-redux';
import MemberProfile from '../../../../component/molecule/member-profile/memberProfile';
import TrainerDetail from '../trainer-detail/trainerDetail';
import PaymentListCard from '../payment-list-card/paymentListCard';
import SmsTemplateModal from '../../../../component/molecule/sms-template-modal/smsTemplateModal';
import { toast } from 'react-toastify';
import UpcomingPackageDetail from '../package-detail/upcomingPackageDetail';
import { ConfirmationModal } from '../../../../component/molecule/modal/modal';
import { FaTrash } from 'react-icons/fa';

export default function MemberDetail() {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [smsTemplateModal, setSmsTemplateModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const { data: memberData } = useSelector((state) => state?.member?.member);

  const memberId = state?.memberId;

  useEffect(() => {
    dispatch(getMemberAction(memberId));
    dispatch(getGymPaymentAction(memberId));
  }, [memberId]);

  const handleEditMember = (id) => {
    navigate('/dashboard/edit-member', { state: { memberId: id } });
  };

  const handleConfirmRemoveMember = () => {
    setShowConfirmModal(true);
  };

  const handleRemoveMember = async (id) => {
    const res = await dispatch(updateMemberStatusAction(id, 'LEFT'));
    if (res?.statusCode === 201) {
      dispatch(getMemberAction(memberId));
      toast.success('Member removed sucessfully');
    }
    setShowConfirmModal(false);
  };

  const handleSetSmsTemplateModal = () => {
    setSmsTemplateModal(true);
  };

  function handleClose() {
    setSmsTemplateModal(false);
  }

  return (
    <div className="row">
      <div className="col-lg-9 mb-4">
        <div className="row  mb-4">
          <div className="col-lg-6">
            <h3 className="header-2 mb-0">Member Detail</h3>
          </div>
          <div className="col-lg-6 mt-4 mt-lg-0">
            <div className="d-flex justify-content-end flex-lg-row flex-column">
              <OutlineButton
                label="Edit"
                isDisabled={memberData?.status === 'LEFT'}
                class="btn-outline-primary px-4 me-lg-2 me-0 mb-lg-0 mb-2"
                handleBtnClick={() => handleEditMember(memberId)}
              />
              <Button label="Send Message" class="btn-tertiary me-lg-2 me-0  mb-lg-0 mb-2" handleBtnClick={handleSetSmsTemplateModal} />
              <Button label="Remove" class="btn-danger" isDisabled={memberData?.status === 'LEFT'} handleBtnClick={handleConfirmRemoveMember} />
            </div>
          </div>
        </div>
        <MemberProfile
          height={memberData?.height}
          weight={memberData?.weight}
          data={memberData?.user || {}}
          createdAt={memberData?.createdAt}
          status={memberData?.status}
        />
        <div className="row mt-4 p-0">
          <div className="col-lg-6">
            <PaymentListCard memberId={memberId} isDisabled={memberData?.status === 'LEFT'} />
          </div>
          <div className="col-lg-6 mt-4 mt-lg-0">
            {memberData?.currentPlan?.trainerId ? (
              <TrainerDetail
                trainerName={memberData?.currentPlan?.trainer?.user?.name}
                trainerPriceTotal={memberData?.currentPlan?.trainerPriceTotal}
              />
            ) : null}
          </div>
        </div>
      </div>
      <div className="col-lg-3 mb-4">
        <PackageDetail
          memberId={memberId}
          currentPlan={memberData?.currentPlan}
          dueAmount={memberData?.dueAmount}
          memberPackageHistoriesId={memberData?.memberPackageHistoriesId}
          packagePriceTotal={memberData?.packagePriceTotal}
          isDisabled={memberData?.status === 'LEFT'}
        />
        {memberData?.upcomingPlan ? <UpcomingPackageDetail upcomingPlan={memberData?.upcomingPlan} /> : null}
      </div>
      <SmsTemplateModal active={smsTemplateModal} onClose={handleClose} recipients={[memberId]} recipientType={'member'} />

      <ConfirmationModal
        title={'Are you sure?'}
        description={'You want to Remove member'}
        active={showConfirmModal}
        onClose={() => {
          setShowConfirmModal(false);
        }}
        type="danger"
        modalIcon={<FaTrash size={50} className="text-danger" />}
        primaryBtnClick={() => handleRemoveMember(memberId)}
        primaryBtnTitle={'Remove'}
        secondaryBtnTite={'Cancel'}
        secondaryBtnClick={() => {
          setShowConfirmModal(false);
        }}
      />
    </div>
  );
}
