import { get, patch, post } from '../../services/api/api';
import { baseUrl, packagesUrl, plansUrl } from '../../services/api/apiConstants';

export const getPlansService = () => get(baseUrl + plansUrl);
export const getPlanByIdService = (PlanId) => get(baseUrl + plansUrl + PlanId);
export const addPlanService = (data) => post(baseUrl + plansUrl, data);
export const updatePlanService = (planId, data) => patch(baseUrl + plansUrl + planId, data);

// packages
export const addPackagesService = (data) => post(baseUrl + packagesUrl, data);
export const getPackageService = (packageId) => get(baseUrl + packagesUrl + packageId);
export const getPackagesService = () => get(baseUrl + packagesUrl);
export const updatePackageService = (packageId, data) => patch(baseUrl + packagesUrl + packageId, data);
