import React, { useState } from 'react';
import './addMember.css';
import Avatar6 from '../../../../assets/avatar6.png';
import PlaceholderUser from '../../../../assets/placeHolderUser.png';

import Camera from '../../../../assets/camera.png';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import InputField from '../../../../atoms/inputField/inputField';
import { Button } from '../../../../component/button/button';
import MultiSelectField from '../../../../atoms/multiSelectField/multiSelectField';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getBatchListAction } from '../../../../redux/states/batch/batch.actions';
import { useDispatch } from 'react-redux';
import { addTrainerAction, getTrainerListAction } from '../../../../redux/states/trainer/trainer.actions';
import { getCitiesAction, getCountriesAction, getStatesAction } from '../../../../redux/states/auth/auth.actions';
import { getCurrentDate, handleUseFormError } from '../../../../utils/utils';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { getPackagesListAction } from '../../../../redux/states/plan/plan.actions';
import { addMemberAction, calculatePaymentAction, getMemberAction, updateMemberAction } from '../../../../redux/states/member/member.actions';
import { compressFile } from '../../../../utils/compressor';
import { genderOptions } from '../../../../utils/constants';

const schema = yup
  .object({
    name: yup.string().required(),
    email: yup.string().email('Must be a valid email'),
    mobile: yup.string().max(10).min(10).required(),
    dob: yup.string().nullable(),
    genderVal: yup.object().required(),
    weight: yup.string().nullable(),
    height: yup.string().nullable(),
    city: yup.object().nullable(),
    state: yup.object().nullable(),

    dueAmount: yup.string(),
    country: yup.object().nullable(),
    startDate: yup.string(),
    address: yup.string(),
    discountFee: yup.string(),
    discountTrainer: yup.string()
  })
  .required();

const MAX_LOGO_SIZE = 1048576;

export default function EditMember() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();

  const updateId = state?.memberId || false;

  const { data: countriesData, loading: countriesLoading } = useSelector((state) => state?.auth?.gymAddressCountries);
  const { data: statesData, loading: statesLoading } = useSelector((state) => state?.auth?.gymAddressStates);
  const { data: citiesData, loading: citiesLoading } = useSelector((state) => state?.auth?.gymAddressCities);
  const { data: memberData = {} } = useSelector((state) => state?.member?.member);

  const { loading: updateMemberLoading } = useSelector((state) => state?.member?.updateMember);
  


  const [logoSrc, setLogoSrc] = useState(PlaceholderUser);
  const [compressedLogoToSave, setCompressedLogoToSave] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors, values },
    control,
    setValue,
    reset,
    getValues,
    watch
  } = useForm({
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    setValue('startDate', getCurrentDate());
    dispatch(getCountriesAction());
  }, []);

  useEffect(() => {
    if (updateId) {
      dispatch(getMemberAction(updateId));
    }
  }, [updateId]);

  useEffect(() => {
    //on unmount revokeObjectURL (to free memory).
    return () => {
      if (compressedLogoToSave != null) {
        URL.revokeObjectURL(logoSrc);
      }
    };
  }, [compressedLogoToSave, logoSrc]);

  const packageId = watch('package')?.value;
  const trainerId = watch('trainer')?.value;
  const dueAmount = watch('dueAmount');
  const discountFee = watch('discountFee');
  const discountTrainer = watch('discountTrainer');

  useEffect(() => {
    const body = {
      packageId,
      trainerId,
      dueAmount,
      discountFee,
      discountTrainer
    };
    if (!packageId) delete body?.packageId;
    if (!trainerId) delete body?.trainerId;
    if (!dueAmount) delete body?.dueAmount;
    if (!discountFee) delete body?.discountFee;
    if (!discountTrainer) delete body?.discountTrainer;
    Object.keys(body)?.length && dispatch(calculatePaymentAction(body));
  }, [packageId, trainerId, dueAmount, discountFee, discountTrainer]);

  const handleOnCountryChange = (obj) => {
    const countryId = obj?.value;
    setValue('country', obj, { shouldValidate: true });
    setValue('state', {}, { shouldValidate: true });
    if (countryId) {
      dispatch(getStatesAction(countryId));
    }
  };

  const handleOnStateChange = (obj) => {
    const stateId = obj?.value;
    setValue('state', obj, { shouldValidate: true });
    setValue('city', {}, { shouldValidate: true });
    if (stateId) {
      dispatch(getCitiesAction(stateId));
    }
  };

  useEffect(() => {
    if (updateId) {
      const genderLabel = genderOptions?.find((item) => item?.value === memberData?.user?.gender);

      setValue('name', memberData?.user?.name);
      setValue('email', memberData?.user?.email || "");
      setValue('mobile', memberData?.user?.mobile);
      setValue('genderVal', { value: memberData?.user?.gender, label: genderLabel?.label });
      setValue('height', memberData?.height);
      setValue('weight', memberData?.weight);
      setValue('dob', memberData?.user?.dob);

      setValue('city', { label: memberData?.user?.address?.city?.name, value: memberData?.user?.address?.city?.id });
      setValue('country', { label: memberData?.user?.address?.country?.name, value: memberData?.user?.address?.country?.id });
      setValue('state', { label: memberData?.user?.address?.state?.name, value: memberData?.user?.address?.state?.id });
      setValue('address', memberData?.user?.address?.addressLine1);

      if (compressedLogoToSave && compressedLogoToSave?.size > 0) {
        setValue('image', compressedLogoToSave, compressedLogoToSave?.name);
      }
    }
  }, [memberData, updateId]);

  const onSubmit = async (data) => {
    const formData = new FormData();

    if (data?.address) formData.append('address', data?.address);
    if (data?.dob) formData.append('dob', data?.dob);
    formData.append('email', data?.email);
    if (data?.height) formData.append('height', data?.height);
    formData.append('mobile', data?.mobile);
    if (data?.weight) formData.append('weight', data?.weight);
    formData.append('name', data?.name);
    formData.append('startDate', data?.startDate);
    if (data?.city?.value) formData.append('cityId', data?.city?.value);
    if (data?.country?.value) formData.append('countryId', data?.country?.value);
    if (data?.state?.value) formData.append('stateId', data?.state?.value);
    formData.append('gender', data?.genderVal?.value);
    formData.append('_method', 'PUT');

    if (compressedLogoToSave && compressedLogoToSave?.size > 0) {
      formData.append('image', compressedLogoToSave, compressedLogoToSave?.name);
    }

    delete data?.genderVal;
    delete data?.country;
    delete data?.state;
    delete data?.city;
    delete data?.batch;
    delete data?.trainer;
    delete data?.package;

    if (updateId) {
      const res = await dispatch(updateMemberAction(updateId, formData));
      if (res?.statusCode === 200) {
        toast.success('Member updated successfully.');
        navigate('/dashboard/member-list');
      } else {
        handleUseFormError(res);
      }
    }
  };

  const handleOnLogoChange = async (e) => {
    const rawLogo = e.target.files[0];
    if (rawLogo && rawLogo?.size > 0) {
      try {
        const compressedLogo = await compressFile(rawLogo);
        if (compressedLogo?.size > MAX_LOGO_SIZE) {
          toast.error(`Logo size exceeding the limit. Please pick a smaller one!`);
        } else {
          setCompressedLogoToSave(compressedLogo);
          setLogoSrc(URL.createObjectURL(compressedLogo));
        }
      } catch (error) {
        toast.error(error?.message || 'Something went wrong!');
      }
    }
  };

  return (
    <>
      <div className="d-flex justify-content-start px-3">
        <h3 className="header-2 mb-3">{updateId ? 'Update Member' : 'Add Member'}</h3>
      </div>
      <div className="card mx-3">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="avatar6-wrapper">
            <img src={logoSrc} className="avatar6-img" alt="img" />
            <label htmlFor="upload-profile">
              <img src={Camera} className="camera" alt="" />
            </label>
            <input id="upload-profile" type="file" className="upload-file-input" accept="image/*" onChange={handleOnLogoChange} />
          </div>

          <div className="row ">
            <div className="col-md-4 p-0">
              <h1 className="header-7 mt-5">Personal Info</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 mb-3 ps-0">
              <InputField placeholder="Enter Name" label="Name" type="text" name={'name'} register={register} error={errors.name?.message} />
            </div>

            <div className="col-md-4 mb-3 ps-0">
              <InputField
                placeholder="Enter Mobile Number"
                label="Mobile"
                type="number"
                name={'mobile'}
                register={register}
                error={errors.mobile?.message}
              />
            </div>
            <div className="col-md-4 mb-3 ps-0">
              <InputField
                placeholder="Enter Email Address"
                label="Email"
                type="email"
                name={'email'}
                register={register}
                error={errors.email?.message}
                maxLength={40}
              />
            </div>
            <div className="col-md-4 mb-3 ps-0">
              <InputField placeholder="Enter DOB" label="DOB" type="date" name={'dob'} register={register} error={errors.dob?.message} />
            </div>
            <div className="col-md-4 mb-3 ps-0">
              <Controller
                name="genderVal"
                control={control}
                render={({ field: { onChange, value }, fieldState }) => {
                  return (
                    <MultiSelectField
                      label={'gender'}
                      onChange={onChange}
                      value={value}
                      placeholder={'Select Gender'}
                      options={[
                        { label: 'Male', value: 'MALE' },
                        { label: 'Female', value: 'FEMALE' },
                        { label: 'Other', value: 'ITS COMPLICATED' }
                      ]}
                      noOptionsMessage={'Please select gender.'}
                      error={fieldState?.error?.message}
                    />
                  );
                }}
              />
            </div>
            <div className="col-md-4 mb-3 ps-0">
              <InputField
                placeholder="Enter Weight"
                label="Weight (in kg)"
                type="weight"
                name={'weight'}
                register={register}
                error={errors.weight?.message}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 mb-3 ps-0">
              <InputField
                placeholder="Enter Height"
                label="Height (in cm)"
                type="height"
                name={'height'}
                register={register}
                error={errors.height?.message}
              />
            </div>
          </div>

          {/* address section */}
          <div className="row">
            <div className="col-md-4 my-2 p-0">
              <h1 className="header-7">Address</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 mb-4 ps-0">
              <Controller
                name="country"
                control={control}
                render={({ field: { value }, fieldState }) => (
                  <MultiSelectField
                    label={'country'}
                    onChange={handleOnCountryChange}
                    value={value}
                    placeholder={'Select country'}
                    options={countriesData?.map((country) => ({ value: country?.id, label: country?.name }))}
                    noOptionsMessage={'Please create country before adding member'}
                    error={fieldState?.error?.message}
                  />
                )}
              />
            </div>
            <div className="col-md-4 mb-4 ps-0">
              <Controller
                name="state"
                control={control}
                render={({ field: { value }, fieldState }) => (
                  <MultiSelectField
                    label={'state'}
                    onChange={handleOnStateChange}
                    value={value}
                    placeholder={'Select state'}
                    options={statesData?.map((state) => ({ value: state?.id, label: state?.name }))}
                    noOptionsMessage={'Please select country before selecting state'}
                    error={fieldState?.error?.message}
                  />
                )}
              />
            </div>
            <div className="col-md-4 mb-4 ps-0">
              <Controller
                name="city"
                control={control}
                render={({ field: { onChange, value }, fieldState }) => {
                  return (
                    <MultiSelectField
                      label={'city'}
                      onChange={onChange}
                      value={value}
                      placeholder={'Select city'}
                      options={citiesData?.map((city) => ({ value: city?.id, label: city?.name }))}
                      noOptionsMessage={'Please select state before selecting city'}
                      error={fieldState?.error?.message}
                    />
                  );
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 mb-3 ps-0">
              <InputField
                placeholder="Enter Address"
                label="Address"
                type="address"
                name={'address'}
                register={register}
                error={errors.address?.message}
                maxLength={80}
              />
            </div>
          </div>

          <div className="row justify-content-center mt-4">
            <div className="col-md-3">
              <Button label={updateId ? 'Update' : 'Add'} class="btn-primary me-2 w-100" isLoading={updateMemberLoading} isDisabled={updateMemberLoading} type="submit" />
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
