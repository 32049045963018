import React, { useState } from 'react';
import BigAmount from '../big-amount/bigAmount';
import { Button, OutlineButton } from '../button/button';
import './pricing.css';

const Pricing = (props) => {
  const { title, price, offerPrice, discount, benefits, description } = props.plan;
  return (
    <div className="col-md-4">
      <div className="plan-card mb-4 mb-md-0">
        <p className="header-2">{title}</p>
        <p className="subHeading text-primary mb-0">
          <del>{parseInt(price)}</del> <span className='text-success fw-bold'>{parseInt(discount)}%</span>
        </p>
        <div className="d-flex justify-content-center align-items-end">
          <BigAmount price={parseInt(offerPrice)} />
          <p className="subHeading"> /{description}</p>
        </div>
        <div className="py-4">
          <ul className="text-start">
            {benefits.map((benefit) => (
              <li className="header-6 pb-1">{benefit.title}</li>
            ))}
          </ul>
        </div>
        <div className="d-grid gap-2">
          <OutlineButton label="Select" handleBtnClick={props.handleOnSelect} class="btn-outline-primary" />
        </div>
      </div>
    </div>
  );
};

export default Pricing;
