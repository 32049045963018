import React, { useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { getRegisteredVisitorsStatsAction, getRegisteredUsersStatsAction } from '../../redux/states/dashboard/dashboard.actions';
import Select from 'react-select';
import './activeMemberGraph.css';
import Loader from '../loader/loader';
import ErrorState from '../molecule/error-state/errorState';


ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip,
    Legend,
);

export const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: false
        },
    }
};

const createBarData = (usersStats) => {
    const labels = usersStats?.labels;
    const data = usersStats?.data;
    return {
        labels: labels,
        datasets: [{
            label: 'Members',
            data: data,
            backgroundColor: 'rgba(40, 218, 198, 100%)',
            borderRadius: 20,
            barThickness: 12,
        }]
    };
}

export default function ActiveMemberGraph() {
    const [barData, setBarData] = useState();
    const selectOptions = [{ label: 'Registered Members', value: 1 }, { label: 'Registered Visitors', value: 2 }]
    const { data: { registeredUsersData: usersStats }, loading: usersStatsLoading, error: usersStatsError } = useSelector(state => state?.dashboard?.usersStats);
    const { data: { registeredVisitorssData: visitorsStats }, loading: visitorsStatsLoading, error: visitorsStatsError } = useSelector(state => state?.dashboard?.visitorsStats);

    const dispatch = useDispatch();

    const handleOnChange = async (item) => {
        if (item?.label === selectOptions[1]?.label) {
            const res = await dispatch(getRegisteredVisitorsStatsAction());
            setBarData(createBarData(res?.data?.registeredVisitorssData));
        } else if (item?.label === selectOptions[0]?.label) {
            const res = await dispatch(getRegisteredUsersStatsAction());
            setBarData(createBarData(res?.data?.registeredUsersData));
        }
    }

    useEffect( async () => {
        const res = await dispatch(getRegisteredUsersStatsAction());
        setBarData(createBarData(res?.data?.registeredUsersData));
    }, [])


    return (
        <div className='card pb-5' style={{ height: "400px" }}>
            <Select
                options={selectOptions}
                id="stats-select"
                className='stats-select'
                defaultValue={selectOptions[0]}
                onChange={handleOnChange}
                isMulti={false}
                isClearable={false}
                isSearchable={false}
            />
            {usersStatsLoading || visitorsStatsLoading ? (
                <Loader />
            ) : usersStatsError || visitorsStatsError ? (
                <ErrorState />
            ) : (
               barData && <Bar options={options} data={barData} />
            )}

        </div>
    )
}
