import './inputField.css';

export default function InputField({ 
  label, 
  type, 
  placeholder, 
  isDisabled, 
  error, 
  maxLength = 24,
  name,
  register,
  accept,
  min
}) {
  return (
    <div className="mb-2">
      <label className="label">{label}</label>
      <div className="mt-1">
        <input
          // value={value}
          type={type}
          className={error ? 'form-control form-error' : 'form-control'}
          placeholder={placeholder}
          disabled={isDisabled}
          // onChange={(e) => onChange && onChange(e.target.value)}
          maxLength={maxLength}
          accept = {accept}
          min={min}
          {...register(name)}
        />
        {error ? <span className="error text-danger label">{error}</span> : null}
      </div>
    </div>
  );
}
