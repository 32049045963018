import React from 'react';
import './statisticsCard.css';
import { MdPersonOutline, MdOutlineWarningAmber, MdPeopleAlt, MdGroups, MdVerified, MdPerson, MdWarning } from 'react-icons/md';

export default function StatisticsCard({stats}) {
  return (
    <div className="card">
      <h3 className="header-3 mb-5">Statistics</h3>
      <div className="d-flex justify-content-between flex-wrap">
      <div className="d-flex align-items-center mb-3">
          <div className="rounded-icon primary me-3">
            <MdGroups size={24} />
          </div>
          <div>
            <h4 className="header-3 m-0">{stats?.totalMembers}</h4>
            <p className="label m-0">Total Members</p>
          </div>
        </div>
        <div className="d-flex align-items-center mb-3">
          <div className="rounded-icon skyBlue me-3">
            <MdVerified size={24} />
          </div>
          <div>
            <h4 className="header-3 m-0">{stats?.activeMembers}</h4>
            <p className="label m-0">Active Members</p>
          </div>
        </div>

        <div className="d-flex align-items-center  mb-3">
          <div className="rounded-icon green me-3">
            <MdPerson size={24} />
          </div>
          <div>
            <h4 className="header-3 m-0">{stats?.currentMonthVisitors}</h4>
            <p className="label m-0">Visitors This Month</p>
          </div>
        </div>

        <div className="d-flex align-items-center  mb-3">
          <div className="rounded-icon red me-3">
            <MdWarning size={24} />
          </div>
          <div>
            <h4 className="header-3 m-0">{stats?.dueMembers}</h4>
            <p className="label m-0">Members Having Due</p>
          </div>
        </div>

        {/* <div className="d-flex align-items-center">
          <div className="rounded-icon green me-3">
            <MdPeopleAlt size={24} />
          </div>
          <div>
            <h4 className="header-3 m-0">9</h4>
            <p className="label m-0">Staff Present Today</p>
          </div>
        </div> */}
      </div>
    </div>
  );
}
