export const baseUrl = 'gym';

// auth
export const userRegisterUrl = '/register';
export const userValidateUrl = '/login/validate';
export const requestLoginUrl = '/login';
export const gymAddressCountriesUrl = 'countries';
export const gymAddressStatesUrl = 'states';
export const gymAddressCitiesUrl = 'cities';
export const saveGymAddressUrl = '/save-address';
export const saveGymLogoUrl = '/save-logo';
export const pricingListUrl = 'pricing';
export const validateReferralUrl = '/validate-referral-code';
// payment
export const createOrderUrl = '/payment/create-order';
export const razorpaySuccessUrl = '/payment/${referenceId}/success';
export const razorpayFailedUrl = '/payment/${referenceId}/failed';

// plans
export const plansUrl = '/plans/';
export const membershipDurationsUrl = '/membership-durations';

// packages
export const packagesUrl = '/packages/';

// member
export const memberListUrl = '/members';
export const memberStatusUrl = '/status/';
export const membersFilterUrl = '/members/filter';
export const calculatePaymentUrl = '/members/payment/calculate';
export const GymPaymentUrl = '/payment/member/';
export const updateMemberBatchUrl = '/members/${memberId}/update/batch';
export const updateMemberPackageUrl = '/members/${memberId}/update/package';
export const updateMemberTrainerUrl = '/members/${memberId}/update/trainer';
export const bulkAddMemberUrl = '/member/add/excel';


//batch
export const batchUrl = '/batches/';

// visitor
export const visitorsUrl = '/visitors';
export const visitorsStatusUrl = '/status/';
export const visitorsFiltersUrl = '/visitors/filter';

//profile
export const profileUrl = '/profile';

//trainer
export const trainerUrl = '/trainers/';

//sms
export const smsTemplatesUrl = '/sms-templates';
export const sendBulkSmsUrl = '/send-bulk-sms';
export const smsPricingUrl = '/sms-pricings';
export const availableSmsCountUrl = '/available-sms-count';
export const razorpaySmsOrderUrl = '/sms-payment/create-order';
export const razorpaySmsSuccessUrl = '/sms-payment/${referenceId}/success';
export const razorpaySmsFailedUrl = '/sms-payment/${referenceId}/failed';

//analytics
export const planDistributionUrl = '/plan-distribution';

export const statisticsUrl = '/get-stats';

export const todaysBirthdayUrl = '/todays-members-birthday-list';

export const registeredUsersStatsUrl = '/get-registered-users-stats-per-month';

export const registeredVisitorsStatsUrl = '/get-registered-visitors-stats-per-month';
