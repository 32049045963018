import React, { useEffect, useState } from 'react';
import Avatar6 from '../../../../assets/avatar6.png';
import PlaceHolderUser from '../../../../assets/placeHolderUser.png';
import Camera from '../../../../assets/camera.png';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import InputField from '../../../../atoms/inputField/inputField';
import { Button } from '../../../../component/button/button';
import MultiSelectField from '../../../../atoms/multiSelectField/multiSelectField';
import { useDispatch, useSelector } from 'react-redux';
import { addBatchAction, getBatchListAction } from '../../../../redux/states/batch/batch.actions';
import { addTrainerAction, updateTrainerAction } from '../../../../redux/states/trainer/trainer.actions';
import { toast } from 'react-toastify';
import { handleUseFormError } from '../../../../utils/utils';
import { useNavigate, useLocation } from 'react-router-dom';
import Loader from '../../../../component/loader/loader';
import ErrorState from '../../../../component/molecule/error-state/errorState';
import { compressFile } from '../../../../utils/compressor';

const MAX_LOGO_SIZE = 1048576 * 2;


const schema = yup
  .object({
    name: yup.string().required(),
    mobile: yup.string().required().length(10),
    email: yup.string().email().required(),
    batches: yup.array().min(1).required(),
    traineeFee: yup.string().required(),
    // traineeFeePercentage: yup.string()
  })
  .required();

export default function AddTrainer() {
  const {
    register,
    control,
    reset,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const selectedTrainer = location?.state?.selectedTrainer;

  const [trainerButtonLabel, setTrainerButtonLabel] = useState("Add");

  const [profileSrc, setProfileSrc] = useState(selectedTrainer?.profilePicture ?? PlaceHolderUser);
  const [compressedProfileToSave, setCompressedProfileToSave] = useState(null);
  const [profileLoading, setProfileLoading] = useState(false);



  const { data: batchList, loading: batchListLoading, error: batchListError, errorMsg: batchListErrorMsg } = useSelector(state => state?.batch?.batchList);
  const { loading: addTrainerLoading } = useSelector(state => state?.trainer?.addTrainer);
  const { loading: updateTrainerLoading } = useSelector(state => state?.trainer?.updateTrainer);


  const onSubmit = async (trainerToSave) => {

    const formDataToSave = new FormData();

    formDataToSave.append('name', trainerToSave?.name);
    formDataToSave.append('mobile', trainerToSave?.mobile);
    formDataToSave.append('email', trainerToSave?.email);
    formDataToSave.append('traineeFee', trainerToSave?.traineeFee);
    // formDataToSave.append('traineeFeePercentage',trainerToSave?.traineeFeePercentage);
    trainerToSave?.batches?.forEach(batch => {
      formDataToSave.append('batches[]', batch?.value);
    });
   
    if (compressedProfileToSave && compressedProfileToSave?.size > 0) {
        formDataToSave.append('profilePicture', compressedProfileToSave, compressedProfileToSave?.name);
    }
    if (selectedTrainer) {
      const updateRes = await dispatch(updateTrainerAction(selectedTrainer?.id, formDataToSave));
      if (updateRes?.statusCode === 200) {
        toast.success(updateRes?.message);
        reset();
        navigate('/dashboard/trainer-list')
      } else {
        handleUseFormError(updateRes);
      }

    } else {
      const addRes = await dispatch(addTrainerAction(formDataToSave));
      if (addRes?.statusCode === 201) {
        toast.success(addRes?.message);
        reset();
        navigate('/dashboard/trainer-list')
      } else {
        handleUseFormError(addRes);
      }
    }
  };

  useEffect(() => {
    dispatch(getBatchListAction());
  }, []);

  useEffect(() => {
    if (selectedTrainer) {
      setValue('name', selectedTrainer?.name);
      setValue('mobile', selectedTrainer?.mobile);
      setValue('email', selectedTrainer?.email);
      setValue('batches', selectedTrainer?.batches?.map(batch => ({ value: batch?.id, label: batch?.name })));
      setValue('traineeFee', selectedTrainer?.traineeFee);
      // setValue('traineeFeePercentage', selectedTrainer?.traineeFeePercentage);
      setTrainerButtonLabel("Update");
    }
  }, [selectedTrainer]);

  useEffect(() => {
    //on unmount revokeObjectURL (to free memory).
    return () => {
        if (compressedProfileToSave != null) {
            URL.revokeObjectURL(profileSrc);
        }
    }

}, [compressedProfileToSave, profileSrc]);

  const handleOnProfileChange = async (e) => {
    const rawProfile = e.target.files[0];
    if (rawProfile && rawProfile?.size > 0) {
        try {
            setProfileLoading(true);
            const compressedProfile = await compressFile(rawProfile);
            if (compressedProfile?.size > MAX_LOGO_SIZE) {
                setProfileLoading(false);

                toast.error(`Profile size exceeding the limit. Please pick a smaller one!`);
            } else {
                setCompressedProfileToSave(compressedProfile);
                setProfileSrc(URL.createObjectURL(compressedProfile));
            }
        } catch (error) {
            setProfileLoading(false)
            toast.error(error?.message || 'Something went wrong!');
        }

    }
}


  return (
    <>
      <div className="d-flex justify-content-start px-3">
        <h3 className="header-2 mb-4">{trainerButtonLabel === "Update" ? "Edit" : "Add"} Trainer</h3>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="card px-3">
          <div className="avatar6-wrapper">
          {profileLoading && <span className='spinner-edit-profile'></span>}
            <img src={profileSrc} className="avatar6-img" alt="profile pic" onLoad={() => setProfileLoading(false)} onError={() => setProfileLoading(false)} />
            <label htmlFor="upload-profile">
              <img src={Camera} className="camera" alt="" />
            </label>
            <input id="upload-profile" type="file" accept="image/*" onChange={handleOnProfileChange} className="upload-file-input" />
          </div>

          <div className="row ">
            <div className="col-md-4 p-0">
              <h1 className="header-7 mt-5">Personal Info</h1>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4 mb-3 ps-0">
              <InputField placeholder="Enter Name" label="Name" type="text" name={'name'} register={register} error={errors.name?.message} />
            </div>

            <div className="col-md-4 mb-3 ps-0">
              <InputField
                placeholder="Enter Mobile Number"
                label="Mobile"
                type="number"
                name={'mobile'}
                register={register}
                error={errors.mobile?.message}
              />
            </div>
            <div className="col-md-4 mb-3 ps-0">
              <InputField
                placeholder="Enter Email Address"
                label="Email"
                type="email"
                name={'email'}
                register={register}
                error={errors.email?.message}
                maxLength={40}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-4 my-2 p-0">
              <h1 className="header-7">Package Info</h1>
            </div>
          </div>

          <div className="row align-items-end">
            <div className="col-md-4 mb-3 ps-0">
              <Controller
                name="batches"
                control={control}
                render={({ field: { onChange, value }, fieldState }) => (
                  <MultiSelectField
                    isMulti={true}
                    label={'Batches'}
                    onChange={onChange}
                    value={value}
                    placeholder={'Select Batch'}
                    options={batchList.map(batch => ({ value: batch?.id, label: batch?.name }))}
                    noOptionsMessage={'Please create a batch before adding your trainer.'}
                    error={fieldState?.error?.message}
                  />
                )}
              />
            </div>
            <div className="col-md-4 mb-3 ps-0">
              <InputField placeholder="Staff" label="Per Trainee Fee " type="number" name={'traineeFee'} register={register} error={errors.traineeFee?.message} />
            </div>

            {/* <div className="col-md-4 mb-3 ps-0">
              <InputField
                placeholder="Trainee Fee %"
                label="Trainee Fee  %"
                type="number"
                name={'traineeFeePercentage'}
                register={register}
                error={errors.traineeFeePercentage?.message}
              />
            </div> */}
          </div>
          <div className="row mt-2">
            <div className="col-md-4 ps-0">
              {batchListLoading ? <Loader /> :
                batchListError ? (<ErrorState message={batchListErrorMsg} />) :
                  <Button label={trainerButtonLabel} type="submit" isLoading={addTrainerLoading || updateTrainerLoading} isDisabled={addTrainerLoading || updateTrainerLoading} class="btn-primary w-100" />}
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
