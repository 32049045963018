import { convertPascal } from '../../../utils/utils';
import { handleRequest, handleSuccess, handleFailure } from '../../services/handleData/handleData.ts';
import {
  ADD_VISITOR_FAILED,
  ADD_VISITOR_REQUEST,
  ADD_VISITOR_SUCCESS,
  GET_VISITOR_FAILED,
  GET_VISITOR_FILTER_FAILED,
  GET_VISITOR_FILTER_REQUEST,
  GET_VISITOR_FILTER_SUCCESS,
  GET_VISITOR_LIST_FAILED,
  GET_VISITOR_LIST_REQUEST,
  GET_VISITOR_LIST_SUCCESS,
  GET_VISITOR_REQUEST,
  GET_VISITOR_SUCCESS,
  UPDATE_VISITOR_FAILED,
  UPDATE_VISITOR_REQUEST,
  UPDATE_VISITOR_SUCCESS
} from './visitor.constants';
import {
  addVisitorService,
  getVisitorService,
  getVisitorsFiltersService,
  getVisitorsListService,
  updateVisitorService,
  updateVisitorsStatusService
} from './visitor.services';

// get member lists
export const getVisitorsListAction = (filters, searchVal, page) => async (dispatch) => {
  dispatch(handleRequest(GET_VISITOR_LIST_REQUEST, true));
  let urlParams = '';
  if (filters?.length) {
    filters?.map((item, index) =>
      index === 0
        ? (urlParams += `?${convertPascal(item?.type?.toLowerCase())}[]=${item?.id}`)
        : (urlParams += `&${convertPascal(item?.type?.toLowerCase())}[]=${item?.id}`)
    );
  }
  if (searchVal) {
    if (!urlParams) {
      urlParams = `?q=${searchVal}`;
    } else {
      urlParams = `&q=${searchVal}`;
    }
  }
  let pageParams = '';
  if (page && urlParams) {
    pageParams = `&page=${page}`;
  } else if (page) {
    pageParams = `?page=${page}`;
  }
  try {
    const res = await getVisitorsListService(urlParams + pageParams);
    if (res?.statusCode === 200) {
      dispatch(handleSuccess(GET_VISITOR_LIST_SUCCESS, res?.data));
    } else {
      let error = (res || {}).error;
      dispatch(handleFailure(GET_VISITOR_LIST_FAILED, error));
    }
    return res;
  } catch (ex) {
    console.log('API FAILED', ex);
    dispatch(handleFailure(GET_VISITOR_LIST_FAILED, ex));
    return ex;
  }
};

// get visitor by id
export const getVisitorAction = (id) => async (dispatch) => {
  dispatch(handleRequest(GET_VISITOR_REQUEST, true));
  try {
    const res = await getVisitorService(id);
    if (res?.statusCode === 200) {
      dispatch(handleSuccess(GET_VISITOR_SUCCESS, res?.data));
    } else {
      let error = (res || {}).error;
      dispatch(handleFailure(GET_VISITOR_FAILED, error));
    }
    return res;
  } catch (ex) {
    console.log('API FAILED', ex);
    dispatch(handleFailure(GET_VISITOR_FAILED, ex));
    return ex;
  }
};

// add member
export const addVisitorAction = (data) => async (dispatch) => {
  dispatch(handleRequest(ADD_VISITOR_REQUEST, true));
  try {
    const res = await addVisitorService(data);
    if (res?.statusCode === 201) {
      dispatch(handleSuccess(ADD_VISITOR_SUCCESS, res?.data));
    } else {
      let error = (res || {}).error;
      dispatch(handleFailure(ADD_VISITOR_FAILED, error));
    }
    return res;
  } catch (ex) {
    console.log('API FAILED', ex);
    dispatch(handleFailure(ADD_VISITOR_FAILED, ex));
    return ex;
  }
};

// update member
export const updateVisitorAction = (id, data) => async (dispatch) => {
  dispatch(handleRequest(UPDATE_VISITOR_REQUEST, true));
  try {
    const res = await updateVisitorService(id, data);
    if (res?.statusCode === 201) {
      dispatch(handleSuccess(UPDATE_VISITOR_SUCCESS, res?.data));
    } else {
      let error = (res || {}).error;
      dispatch(handleFailure(UPDATE_VISITOR_FAILED, error));
    }
    return res;
  } catch (ex) {
    console.log('API FAILED', ex);
    dispatch(handleFailure(UPDATE_VISITOR_FAILED, ex));
    return ex;
  }
};

// get visitor filter
export const getVisitorsFiltersAction = () => async (dispatch) => {
  dispatch(handleRequest(GET_VISITOR_FILTER_REQUEST, true));
  try {
    const res = await getVisitorsFiltersService();
    if (res?.statusCode === 200) {
      dispatch(handleSuccess(GET_VISITOR_FILTER_SUCCESS, res?.data));
    } else {
      let error = (res || {}).error;
      dispatch(handleFailure(GET_VISITOR_FILTER_FAILED, error));
    }
    return res;
  } catch (ex) {
    console.log('API FAILED', ex);
    dispatch(handleFailure(GET_VISITOR_FILTER_FAILED, ex));
    return ex;
  }
};

// update status of member
export const updateStatusAction = (id, status) => async (dispatch) => {
  console.log('id, status', id, status);
  try {
    const res = await updateVisitorsStatusService(id, status);
    return res;
  } catch (ex) {
    console.log('API FAILED', ex);
    return ex;
  }
};
