import React, { useEffect } from 'react'
import './trainerCard.css'
import TrainerImg from './../../../../assets/trainer.png'
import PlaceholderUser from '../../../../assets/placeHolderUser.png';
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { MdOutlineCall, MdOutlineEmail } from 'react-icons/md'
import { getMemberListAction } from '../../../../redux/states/member/member.actions';
import { OutlineButton } from '../../../../component/button/button';
import { FaRupeeSign } from 'react-icons/fa';



export default function TrainerCard({ trainer }) {

    const navigate = useNavigate();

    const batchesLength = trainer?.batches?.length;

    const handleCommaSeparation = (name, index) => batchesLength && index !== (batchesLength - 1) ? name + ", " : name;

    return (
        <>
            <div className='trainer-card'>
                <div className='row'>
                    <div className="col-md-4 p-0">
                        <div className='trainer-img-wrapper'>
                          <img src={trainer?.profilePicture ?? PlaceholderUser} alt="" className='trainer-img' />
                        </div>
                    </div>
                    <div className='col-md-8 ps-3 p-0'>
                        <h1 className='header-7 text-primary mt-3'>{trainer?.name}</h1>
                        <div className='d-flex my-3'>
                            <MdOutlineEmail size={24} className='light-gray-text' />
                            <p className='subHeading m-0 ps-3'>{trainer?.email}</p>
                        </div>
                        <div className='d-flex'>
                            <MdOutlineCall size={24} className='light-gray-text' />
                            <p className='subHeading m-0 ps-3'>{trainer?.mobile}</p>
                        </div>

                    </div>
                </div>
                <div className='d-flex justify-content-between mt-4'>
                    <p className='header-8 '>Trainee Fee</p>
                    <p className='subHeading'><FaRupeeSign size={10} />{trainer?.traineeFee}</p>
                </div>
                {/* <div className='d-flex justify-content-between'>
                    <p className='header-8'>Trainee Fee Percentage</p>
                    <p className='subHeading'>{trainer?.traineeFeePercentage}</p>
                </div> */}
                <div className='d-flex justify-content-between mb-3'>
                    <p className='header-8'>Batch</p>
                    <p className='subHeading'>{trainer?.batches?.map((batch, index) => handleCommaSeparation(batch?.name, index))}</p>
                </div>
                <OutlineButton label='Edit Detail' class="btn-outline-primary w-100" handleBtnClick={() => navigate('/dashboard/add-trainer', { state: { selectedTrainer: trainer } })} />
            </div>
        </>
    )
}
