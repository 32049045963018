import React from 'react';
import './sideBar.css';
import ClientLogo from '../../../assets/gold-gym.png';
import { MdHome, MdPeopleAlt, MdPerson, MdSettings, MdVerified, MdVerticalSplit } from 'react-icons/md';

import { NavLink } from 'react-router-dom';

export default function SideBar({gymLogo, gymName}) {
  return (
    <div className="side-bar">
      <NavLink className="brand-area" to="/dashboard/profile">
        <div className='gym-logo-wrapper'>
          <img src={gymLogo} />
        </div>
        <p className="header-4 mt-3 mb-0 d-lg-block d-none text-center">{gymName}</p>
      </NavLink>
      <div className="navigation-area">
        <NavLink
          className="navigation-item"
          to="/dashboard"
          end
        >
          <MdHome size={18} />
          <p className="header-6 m-0 d-lg-block d-none">Dashboard</p>
        </NavLink>

        <NavLink
          className="navigation-item"
          to="/dashboard/visitor-list"
        >
          <MdPeopleAlt size={18} />
          <p className="header-6 m-0 d-lg-block d-none">Visitors</p>
        </NavLink>

        <NavLink
          className="navigation-item"
          to="/dashboard/member-list"
        >
          <MdVerified size={18} />
          <p className="header-6 m-0 d-lg-block d-none">Members</p>
        </NavLink>

        <NavLink
          className="navigation-item"
          to="/dashboard/trainer-list"
        >
          <MdPerson size={18} />
          <p className="header-6 m-0 d-lg-block d-none">Trainers</p>
        </NavLink>

        <NavLink className="navigation-item"
          to="/dashboard/plan-list">
          <MdVerticalSplit size={18} />
          <p className="header-6 m-0 d-lg-block d-none">Plans</p>
        </NavLink>

        <NavLink className="navigation-item"
          to="/dashboard/profile">
          <MdSettings size={18} />
          <p className="header-6 m-0 d-lg-block d-none">Settings</p>
        </NavLink>
      </div>
    </div>
  );
}
