import { get, post } from '../../services/api/api';
import {
  baseUrl,
  smsTemplatesUrl,
  sendBulkSmsUrl,
  smsPricingUrl,
  availableSmsCountUrl,
  razorpaySmsOrderUrl,
  razorpaySmsSuccessUrl,
  razorpaySmsFailedUrl
} from '../../services/api/apiConstants';

export const getSmsTemplatesService = () => get(baseUrl + smsTemplatesUrl);

export const sendBulkSmsService = (data, urlParams) => post(baseUrl + sendBulkSmsUrl + urlParams, data);

export const getSmsPricingService = () => get(baseUrl + smsPricingUrl);

export const getAvailableSmsCountService = () => get(baseUrl + availableSmsCountUrl);

// sms payment
export const razorpaySmsCreateService = (data) => post(baseUrl + razorpaySmsOrderUrl, data);
export const razorpaySmsSuccessService = (referenceId, data) =>
  post(String(baseUrl + razorpaySmsSuccessUrl).replace('${referenceId}', referenceId), data);
export const razorpaySmsFailureService = (referenceId) => post(String(baseUrl + razorpaySmsFailedUrl).replace('${referenceId}', referenceId));
