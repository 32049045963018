import React from 'react';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import { AiOutlineClockCircle } from 'react-icons/ai';
import './timeField.css';

const TimeField = ({ label,
    error,
    name, 
    placeholder, onChange, value }) => {

    return (
        <div className="mb-2">
            <label className="label">{label}</label>
            <div className="mt-1">
                <TimePicker
                    className={error ? 'form-control form-error' : 'form-control'}
                    showSecond={false}
                    name={name}
                    onChange={onChange}
                    use12Hours={true}
                    value={value}
                    inputReadOnly={true}
                    placeholder={placeholder}
                    inputIcon={<AiOutlineClockCircle className="iconClass" />}
                    allowEmpty={false}
                />
                {error ? <span className="error text-danger label">{error}</span> : null}
            </div>
        </div>
    );
}

export default TimeField;
