import React, { useCallback, useEffect, useState } from 'react';
import { Button } from '../../../../component/button/button';
import FilterCard from '../../../../component/filter-card/filterCard';
import { useNavigate } from 'react-router-dom';
import { getVisitorsFiltersAction, getVisitorsListAction } from '../../../../redux/states/visitor/visitor.actions';
import { useDispatch, useSelector } from 'react-redux';
import EmptyState from '../../../../component/molecule/empty-state/emptyState';
import ErrorState from '../../../../component/molecule/error-state/errorState';
import Loader from '../../../../component/loader/loader';
import SmsTemplateModal from '../../../../component/molecule/sms-template-modal/smsTemplateModal';
import SearchBar from '../../../../component/molecule/search-bar/searchBar';
import { debounce } from '../../../../utils/utils';
import VisitorMembersTable from '../visitor-members-table/visitorMembersTable';
import CustomPagination from '../../../../component/custom-pagination/customPagination';

export default function VisitorList(props) {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    data: { data: visitorsListData, total },
    loading: visitorLoading,
    error: visitorError
  } = useSelector((state) => state?.visitor?.visitorsList);
  const { data: visitorsFiltersData, loading: visitorFilterLoading } = useSelector((state) => state?.visitor?.visitorsFilters);

  const [checkedFilter, setCheckedFilter] = useState([]);
  const [smsTemplateModal, setSmsTemplateModal] = useState(false);
  const [recipient, setRecipient] = useState(null);
  const [searchedValue, setSearchedValue] = useState('');
  const [sendMessageVisible, setSendMessageVisible] = useState(false);

  const [pageNum, setPageNum] = useState(1);

  useEffect(() => {
    dispatch(getVisitorsFiltersAction());
  }, []);

  useEffect(() => {
    dispatch(getVisitorsListAction(checkedFilter, '', pageNum));
    if (checkedFilter?.length === 0) {
      setSendMessageVisible(false);
    } else {
      setSendMessageVisible(true);
    }
  }, [checkedFilter, pageNum]);

  function handleClose() {
    setSmsTemplateModal(false);
  }

  const handleDebounceFn = (inputValue) => {
    dispatch(getVisitorsListAction(checkedFilter, inputValue));
  };

  const debounceFn = useCallback(debounce(handleDebounceFn, 1000), []);

  const handleSearchChange = (val) => {
    setSearchedValue(val);
    debounceFn(val);
  };

  const handleSms = (itemId) => {
    const recipient = visitorsListData.filter((visitor) => visitor?.id === itemId).map((visitor) => visitor?.id);
    setRecipient(recipient);
    setSmsTemplateModal(true);
  };

  const handleSetSmsTemplateModal = () => {
    setSmsTemplateModal(true);
    setRecipient(null);
  };

  return (
    <>
      <div className="d-flex justify-content-between px-3 flex-wrap">
        <h3 className="header-2 mb-md-0 mb-3">Visitor List</h3>
        <div className="d-flex">
          {visitorsListData?.length > 0 && (
            <Button
              label={`Send Message ${sendMessageVisible ? `(${total})` : ''}`}
              isDisabled={!sendMessageVisible}
              class="btn-tertiary me-2"
              handleBtnClick={handleSetSmsTemplateModal}
            />
          )}
          <Button label="Add Visitor" class="btn-primary" handleBtnClick={() => navigate('/dashboard/add-visitor')} />
        </div>
      </div>

      <div className="row">
        <div className="col-md-8">
          <div className="search-bar-wrapper">
            <SearchBar searchedValue={searchedValue} handleSearchChange={handleSearchChange} />
          </div>
        </div>
      </div>

      <div className="row mt-5">
        <div className="col-md-8 order-md-1 order-2">
          {visitorLoading ? (
            <Loader />
          ) : visitorError ? (
            <ErrorState />
          ) : checkedFilter?.length > 0 && visitorsListData?.length === 0 ? (
            <EmptyState message={'No visitor found'} />
          ) : visitorsListData?.length === 0 ? (
            <EmptyState message={'No visitor added yet'} btnTitle={'Add Visitor'} handleBtnClick={() => navigate('/dashboard/add-visitor')} />
          ) : (
            <>
              <VisitorMembersTable showHeader={false} data={visitorsListData} handleSms={handleSms} />
              {total > 10 ? <CustomPagination pageNum={pageNum} setPageNum={setPageNum} totalPage={Math.ceil(total / 10)} /> : null}
            </>
          )}
        </div>
        <div className="col-md-4 order-md-2 order-1">
          <FilterCard data={visitorsFiltersData} loader={visitorFilterLoading} checkedFilter={checkedFilter} setCheckedFilter={setCheckedFilter} />
        </div>
      </div>
      {visitorsListData?.length > 0 && (
        <SmsTemplateModal
          active={smsTemplateModal}
          onClose={handleClose}
          recipients={recipient ?? visitorsListData.map((visitor) => visitor?.id)}
          recipientType={'visitor'}
          checkedFilter={checkedFilter}
          totalMember={total}
        />
      )}
    </>
  );
}
