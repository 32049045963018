import React, { useState, useEffect } from 'react'
import { MdClose } from 'react-icons/md'
import { MdAlarmAdd } from 'react-icons/md'
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import InputField from '../../../../atoms/inputField/inputField';
import TimeField from '../../../../atoms/timeField/timeField';
import { Button } from '../../../../component/button/button';
import './createBatchModal.css'
import { toast } from 'react-toastify';
import moment from 'moment';

import { useDispatch, useSelector } from 'react-redux';
import { addBatchAction, updateBatchAction } from '../../../../redux/states/batch/batch.actions';
import { handleUseFormError } from '../../../../utils/utils';

const schema = yup
  .object({
    name: yup.string().required(),
    startsAt: yup.object().required(),
    endsAt: yup.object().required()
  })
  .required();

export default function CreateBatchModal(props) {

  const dispatch = useDispatch();

  const {
    loading: createBatchLoading } = useSelector(state => state?.batch?.addBatch);

  const {
    loading: updateBatchLoading } = useSelector(state => state?.batch?.updateBatch);

  const {
    register,
    control,
    reset,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmit = async (batchInfo) => {
    const batchToSave = {name: batchInfo?.name, startsAt: batchInfo?.startsAt?.format("HH:mm"), endsAt: batchInfo?.endsAt?.format("HH:mm")}
    if (props?.selectedBatch) {
      const updateRes = await dispatch(updateBatchAction(props?.selectedBatch?.id, batchToSave));
      if (updateRes?.statusCode === 201) {
        toast.success(updateRes?.message);
      } else {
        handleUseFormError(updateRes);
      }
    } else {
      const addRes = await dispatch(addBatchAction(batchToSave));
      if (addRes?.statusCode === 201) {
        toast.success(addRes?.message);
      } else {
        handleUseFormError(addRes);
      }
    }
    handleClose();
  };

  const handleClose = () => {
    reset();
    props.onClose();
  }

  useEffect(() => {
    if (props?.selectedBatch) {
      const selectedBatch = props?.selectedBatch;
      setValue('name', selectedBatch?.name);
      setValue('startsAt', moment(selectedBatch?.startsAt, "HH:mm:ss"));
      setValue('endsAt', moment(selectedBatch?.endsAt, "HH:mm:ss"));
    }
  }, [props?.selectedBatch])

  return (
    <div className="modal" id="staticBackdrop" style={{ display: props.active ? 'block' : 'none' }}>
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <MdClose size={30} onClick={handleClose} className="modal__icon" />
          <div className="modal-header">
            <h5 className="modal-title header-4" id="staticBackdropLabel">
              Create Batch
            </h5>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-md-12">
                  <InputField placeholder="Morning" label="Name" type="text" name={'name'} register={register} error={errors.name?.
                    message} />
                </div>
                <div className="col-md-12">
                  <Controller control={control} name={'startsAt'} render={({ field, fieldState }) => (
                    <TimeField placeholder="6:00 am" label="From Time" onChange={(value) => field.onChange(value)} value={field.value} error={fieldState?.error?.message} />
                  )} />
                </div>
                <div className="col-md-12 mb-3">
                  <Controller control={control} name={'endsAt'} render={({ field, fieldState }) => (
                    <TimeField placeholder="6:00 pm" label="To Time" onChange={(value) => field.onChange(value)} value={field.value} error={fieldState?.error?.message} />
                  )} />
                </div>
                <div className="col-md-12 mb-2">
                  <Button label={props.label} type="submit" isLoading={createBatchLoading || updateBatchLoading} isDisabled={createBatchLoading || updateBatchLoading} class="btn-primary w-100" />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
