//get batch list
export const GET_PLAN_DISTRIBUTION = 'GET_PLAN_DISTRIBUTION';
export const GET_PLAN_DISTRIBUTION_REQUEST = 'GET_PLAN_DISTRIBUTION_REQUEST';
export const GET_PLAN_DISTRIBUTION_SUCCESS = 'GET_PLAN_DISTRIBUTION_SUCCESS';
export const GET_PLAN_DISTRIBUTION_FAILED = 'GET_PLAN_DISTRIBUTION_FAILED';


//get statistics 
export const GET_STATISTCS = 'GET_STATISTCS';
export const GET_STATISTCS_REQUEST = 'GET_STATISTCS_REQUEST';
export const GET_STATISTCS_SUCCESS = 'GET_STATISTCS_SUCCESS';
export const GET_STATISTCS_FAILED = 'GET_STATISTCS_FAILED';




//get todays birthday list
export const GET_TODAYS_BIRTHDAY_LIST = 'GET_TODAYS_BIRTHDAY_LIST';
export const GET_TODAYS_BIRTHDAY_LIST_REQUEST = 'GET_TODAYS_BIRTHDAY_LIST_REQUEST';
export const GET_TODAYS_BIRTHDAY_LIST_SUCCESS = 'GET_TODAYS_BIRTHDAY_LIST_SUCCESS';
export const GET_TODAYS_BIRTHDAY_LIST_FAILED = 'GET_TODAYS_BIRTHDAY_LIST_FAILED';

//get users stats
export const GET_USERS_STATS = 'GET_USERS_STATS';
export const GET_USERS_STATS_REQUEST = 'GET_USERS_STATS_REQUEST';
export const GET_USERS_STATS_SUCCESS = 'GET_USERS_STATS_SUCCESS';
export const GET_USERS_STATS_FAILED = 'GET_USERS_STATS_FAILED';


//get visitors stats
export const GET_VISITORS_STATS = 'GET_VISITORS_STATS';
export const GET_VISITORS_STATS_REQUEST = 'GET_VISITORS_STATS_REQUEST';
export const GET_VISITORS_STATS_SUCCESS = 'GET_VISITORS_STATS_SUCCESS';
export const GET_VISITORS_STATS_FAILED = 'GET_VISITORS_STATS_FAILED';





