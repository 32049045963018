import { get, post } from '../../services/api/api';
import { baseUrl, createOrderUrl, pricingListUrl, razorpayFailedUrl, razorpaySuccessUrl, requestLoginUrl, userRegisterUrl,userValidateUrl, validateReferralUrl } from '../../services/api/apiConstants';
import { gymAddressCountriesUrl, gymAddressStatesUrl, gymAddressCitiesUrl, saveGymAddressUrl,saveGymLogoUrl } from '../../services/api/apiConstants';


export const postUserRegisterService = (data) => post(baseUrl + userRegisterUrl, data);

export const postValidateUserService = (data) => post(baseUrl + userValidateUrl, data);

export const postLoginRequestService = (data) => post(baseUrl + requestLoginUrl, data);



export const getCountrieService = () => get(gymAddressCountriesUrl);
export const getStatesService = (countryId) => get(`${gymAddressStatesUrl}/${countryId}`);
export const getCitiesService = (stateId) => get(`${gymAddressCitiesUrl}/${stateId}`);
export const saveGymAddressService = (data) => post(baseUrl + saveGymAddressUrl, data);
export const saveGymLogoService = (file) => post(baseUrl + saveGymLogoUrl, file);
export const getPricingListService = () => get(pricingListUrl);
export const validateReferralService = (data) => post(baseUrl + validateReferralUrl, data);

// payment
export const createOrderService = (data) => post(baseUrl + createOrderUrl, data);
export const razorpaySuccessService = (referenceId, data) => post(String(baseUrl + razorpaySuccessUrl).replace('${referenceId}', referenceId), data);
export const razorpayFailureService = (referenceId) => post(String(baseUrl + razorpayFailedUrl).replace('${referenceId}', referenceId));

