import React from 'react';
import { MdClose } from 'react-icons/md';
import { Button } from '../../button/button';
import { useDispatch, useSelector } from 'react-redux';
import { sendBulkSmsAction } from '../../../redux/states/sms/sms.actions';
import { toast } from 'react-toastify';
import { handleUseFormError } from '../../../utils/utils';

export default function SmsModal({ onClose, active, recipients, recipientType, template, checkedFilter, totalMember }) {
  const dispatch = useDispatch();

  const { loading: sendSmsLoading } = useSelector((state) => state?.sms?.bulkSms);

  const sendSms = async (e) => {
    e.preventDefault();
    const bulkSmsData = { templateId: template?.id, recipientType };
    const bulkSmsRes = await dispatch(sendBulkSmsAction(bulkSmsData, checkedFilter));
    if (bulkSmsRes?.statusCode === 200) {
      toast.success(bulkSmsRes?.message);
      onClose();
    } else {
      handleUseFormError(bulkSmsRes);
    }
  };

  return (
    <div className="modal" id="staticBackdrop" style={{ display: active ? 'block' : 'none' }}>
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <MdClose size={30} onClick={onClose} className="modal__icon" />
          <div className="modal-header">
            <h5 className="modal-title header-4" id="staticBackdropLabel">
              {'Select Message'}
            </h5>
          </div>
          <div className="modal-body">
            <form onSubmit={sendSms}>
              <div className="form-group">
                <textarea rows="7" className="form-control" value={template?.content} disabled={true}></textarea>
              </div>
              <span className="error text-danger label">{`Note: SMS will be sent to ${totalMember} person`}</span>
              <Button label={'Send Message'} isLoading={sendSmsLoading} type="submit" class="btn-primary w-100 mt-4" />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
