import React, { useState, useEffect } from 'react';
import { Button } from '../../../component/button/button';
import InputField from '../../../atoms/inputField/inputField';
import RegisterImage from '../../../assets/register.svg';
import Logo from '../../../assets/logo.svg';
import '../../auth/login/login.css';
import BigAmount from '../../../component/big-amount/bigAmount';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { createOrderAction, validateReferralAction } from '../../../redux/states/auth/auth.actions';
import { toast } from 'react-toastify';

import useRazorpay from '../../../hooks/use-payment-razorpay';
import usePaymentRazorpay from '../../../hooks/use-payment-razorpay';

import { handleUseFormError } from '../../../utils/utils';
import { removeData } from './../../../redux/services/handleData/localStorage.ts';
import { ConfirmationModal } from './../../../component/molecule/modal/modal';
import { MdLogout } from 'react-icons/md';

const schema = yup
  .object({
     code: yup.string()
  })
  .required();


export default function Referral() {
  const {
    register,
    watch,
    handleSubmit,
    resetField,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  });

  const buttonState = {
    1:'Continue Payment',
    2:'Validate Referral Code',
    3:'Continue Payment With Referral',
    4:'Continue Payment Without Referral'
  };

  const location = useLocation();
  const { selectedId, price } = location?.state;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [ handlePayment ] = usePaymentRazorpay();

  const [payableAmount, setPayableAmount] = useState(price);
  const [buttonText, setButtonText] = useState(buttonState[1]);
  const [isLogoutModalActive, updateLogoutModal] = useState(false);


  const handleLogout = () => {
    removeData('token');
    removeData('isPaymentDone');
    navigate('/')
    window.location.reload();
  }

  const {data: referralResData, loading: referralLoading, error: referralError} = useSelector(state => state?.auth?.validateReferralData);
  const {data: orderData, loading: orderLoading, error: orderError} = useSelector(state => state?.auth?.createOrderData);


  const onSubmit = async (data) => {
    const inputReferral = data?.code;
    if(inputReferral?.length && buttonText === buttonState[2]){
      const referralRes = await dispatch(validateReferralAction({pricingId: selectedId, referralCode:inputReferral}));
      if(referralRes?.statusCode === 200){
        toast.success(referralRes?.message);
        setPayableAmount(referralRes?.data?.payableAmount);
        setButtonText(buttonState[3]);
      }else{
        resetField('code');
        setButtonText(buttonState[4]);
        handleUseFormError(referralRes);
      }
    }else {
      const orderRes = await dispatch(createOrderAction({pricingId: selectedId, referralCode:inputReferral}));
      if(orderRes?.statusCode === 200){
        const { paymentReferenceId, payableAmount, razorpayOrderId } = orderRes?.data;
        handlePayment(paymentReferenceId, payableAmount, razorpayOrderId);
      }else{
        setButtonText(buttonState[1]);
        setPayableAmount(price);
        resetField('code');
        handleUseFormError(orderRes);
      }
      
    }

  };

  
  useEffect(() => {
    const watchSubscription = watch((value, { name, type }) => {
      if(name === 'code' && value?.code?.length){
        setButtonText(buttonState[2]);
      }else{
        setButtonText(buttonState[1]);
      }
    });
    return () => watchSubscription.unsubscribe();
  }, [watch]);


  return (
    <div className="row gx-0">
      <div className="col-md-8">
        <div className="illustration-area-wrapper">
          <div className="header">
            <img alt="logo" src={Logo} />
          </div>
          <div className="illustration-area">
            <img alt="register" src={RegisterImage} />
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <div className="main-content-wrapper position-relative">
         <MdLogout size="24" className="logout-icon-btn" onClick={() => updateLogoutModal(true)}/>

          <h5 className="header-3">Welcome to GymHeight!</h5>
          <p className="subHeading">By adding referral code you will get some discount</p>
          <br />
          <form onSubmit={handleSubmit(onSubmit)}>
            <InputField
              placeholder="Code"
              label="ReferralCode (Optional)"
              type="text"
              name={'code'}
              register={register}
              error={errors.code?.message}
            />

            <div className="d-flex justify-content-center align-items-end">
              <p className="header-6"> You need to pay total</p>
              <BigAmount price={payableAmount} />
            </div>
            <Button class="btn-primary mt-2 w-100" label={buttonText} isLoading={referralLoading|| orderLoading} type="submit" />
          </form>
        </div>
      </div>
      <ConfirmationModal
        title={'Are you sure?'}
        description={'You want to logout'}
        active={isLogoutModalActive}
        onClose={() => {
          updateLogoutModal(false);
        }}
        modalIcon={<MdLogout size={50} className="text-primary" />}
        primaryBtnClick={handleLogout}
        primaryBtnTitle={'Logout'}
        secondaryBtnTite={'Cancel'}
        secondaryBtnClick={() => {
          updateLogoutModal(false);
        }}
      />
    </div>
  );
}
