export const GET_VISITOR_LIST = 'GET_VISITOR_LIST';
export const GET_VISITOR_LIST_REQUEST = 'GET_VISITOR_LIST_REQUEST';
export const GET_VISITOR_LIST_SUCCESS = 'GET_VISITOR_LIST_SUCCESS';
export const GET_VISITOR_LIST_FAILED = 'GET_VISITOR_LIST_FAILED';

export const GET_VISITOR = 'GET_VISITOR';
export const GET_VISITOR_REQUEST = 'GET_VISITOR_REQUEST';
export const GET_VISITOR_SUCCESS = 'GET_VISITOR_SUCCESS';
export const GET_VISITOR_FAILED = 'GET_VISITOR_FAILED';

export const ADD_VISITOR = 'ADD_VISITOR';
export const ADD_VISITOR_REQUEST = 'ADD_VISITOR_REQUEST';
export const ADD_VISITOR_SUCCESS = 'ADD_VISITOR_SUCCESS';
export const ADD_VISITOR_FAILED = 'ADD_VISITOR_FAILED';

export const UPDATE_VISITOR = 'UPDATE_VISITOR';
export const UPDATE_VISITOR_REQUEST = 'UPDATE_VISITOR_REQUEST';
export const UPDATE_VISITOR_SUCCESS = 'UPDATE_VISITOR_SUCCESS';
export const UPDATE_VISITOR_FAILED = 'UPDATE_VISITOR_FAILED';

export const GET_VISITOR_FILTER = 'GET_VISITOR_FILTER';
export const GET_VISITOR_FILTER_REQUEST = 'GET_VISITOR_FILTER_REQUEST';
export const GET_VISITOR_FILTER_SUCCESS = 'GET_VISITOR_FILTER_SUCCESS';
export const GET_VISITOR_FILTER_FAILED = 'GET_VISITOR_FILTER_FAILED';
