import { handleRequest, handleSuccess, handleFailure } from '../../services/handleData/handleData.ts';
import {
  ADD_PACKAGES_FAILED,
  ADD_PACKAGES_REQUEST,
  ADD_PACKAGES_SUCCESS,
  ADD_PLAN_FAILED,
  ADD_PLAN_REQUEST,
  ADD_PLAN_SUCCESS,
  GET_PACKAGES_LIST_FAILED,
  GET_PACKAGES_LIST_REQUEST,
  GET_PACKAGES_LIST_SUCCESS,
  GET_MEMBERSHIP_DURATION_FAILED,
  GET_MEMBERSHIP_DURATION_REQUEST,
  GET_MEMBERSHIP_DURATION_SUCCESS,
  GET_PLANS_LIST_FAILED,
  GET_PLANS_LIST_REQUEST,
  GET_PLANS_LIST_SUCCESS,
  GET_PLAN_FAILED,
  GET_PLAN_REQUEST,
  GET_PLAN_SUCCESS,
  UPDATE_PLAN_FAILED,
  UPDATE_PLAN_REQUEST,
  UPDATE_PLAN_SUCCESS,
  GET_PACKAGE_REQUEST,
  GET_PACKAGE_SUCCESS,
  GET_PACKAGE_FAILED,
  UPDATE_PACKAGES_REQUEST,
  UPDATE_PACKAGES_SUCCESS,
  UPDATE_PACKAGES_FAILED
} from './plan.constants';
import {
  addPackagesService,
  addPlanService,
  getPackageService,
  getPackagesService,
  getPlanByIdService,
  getPlansService,
  membershipDurationsService,
  updatePackageService,
  updatePlanService
} from './plan.services';

// get plans list
export const getPlansListAction = () => async (dispatch) => {
  dispatch(handleRequest(GET_PLANS_LIST_REQUEST, true));
  try {
    const res = await getPlansService();
    if (res?.statusCode === 200) {
      dispatch(handleSuccess(GET_PLANS_LIST_SUCCESS, res?.data));
    } else {
      let error = (res || {}).error || 'something went wrong';
      dispatch(handleFailure(GET_PLANS_LIST_FAILED, error));
    }
    return res;
  } catch (ex) {
    console.log('API FAILED', ex);
    dispatch(handleFailure(GET_PLANS_LIST_FAILED, ex));
    return ex;
  }
};

// get plan by Id
export const getPlanByIdAction = (planId) => async (dispatch) => {
  dispatch(handleRequest(GET_PLAN_REQUEST, true));
  try {
    const res = await getPlanByIdService(planId);
    if (res?.statusCode === 200) {
      dispatch(handleSuccess(GET_PLAN_SUCCESS, res?.data));
    } else {
      let error = (res || {}).error;
      dispatch(handleFailure(GET_PLAN_FAILED, error));
    }
    return res;
  } catch (ex) {
    console.log('API FAILED', ex);
    dispatch(handleFailure(GET_PLAN_FAILED, ex));
    return ex;
  }
};

// add plan
export const addPlanAction = (data) => async (dispatch) => {
  dispatch(handleRequest(ADD_PLAN_REQUEST, true));
  try {
    const res = await addPlanService(data);
    if (res?.statusCode === 201) {
      dispatch(handleSuccess(ADD_PLAN_SUCCESS, res));
    } else {
      dispatch(handleFailure(ADD_PLAN_FAILED, res));
    }
    return res;
  } catch (ex) {
    console.log('API FAILED', ex);
    dispatch(handleFailure(ADD_PLAN_FAILED, ex));
    return ex;
  }
};

// update plan
export const updatePlanAction = (planId, data) => async (dispatch) => {
  dispatch(handleRequest(UPDATE_PLAN_REQUEST, true));
  try {
    const res = await updatePlanService(planId, data);
    if (res?.statusCode === 200) {
      dispatch(handleSuccess(UPDATE_PLAN_SUCCESS, res));
    } else {
      dispatch(handleFailure(UPDATE_PLAN_FAILED, res));
    }
    return res;
  } catch (ex) {
    console.log('API FAILED', ex);
    dispatch(handleFailure(UPDATE_PLAN_FAILED, ex));
    return ex;
  }
};

// add packages
export const addPackagesAction = (data) => async (dispatch) => {
  dispatch(handleRequest(ADD_PACKAGES_REQUEST, true));
  try {
    const res = await addPackagesService(data);
    if (res?.statusCode === 201) {
      dispatch(handleSuccess(ADD_PACKAGES_SUCCESS, res));
    } else {
      dispatch(handleFailure(ADD_PACKAGES_FAILED, res));
    }
    return res;
  } catch (ex) {
    console.log('API FAILED', ex);
    dispatch(handleFailure(ADD_PACKAGES_FAILED, ex));
    return ex;
  }
};

// get packages list
export const getPackagesListAction = () => async (dispatch) => {
  dispatch(handleRequest(GET_PACKAGES_LIST_REQUEST, true));
  try {
    const res = await getPackagesService();
    if (res?.statusCode === 200) {
      dispatch(handleSuccess(GET_PACKAGES_LIST_SUCCESS, res?.data));
    } else {
      let error = (res || {}).error;
      dispatch(handleFailure(GET_PACKAGES_LIST_FAILED, error));
    }
    return res;
  } catch (ex) {
    console.log('API FAILED', ex);
    dispatch(handleFailure(GET_PACKAGES_LIST_FAILED, ex));
    return ex;
  }
};

// get packages
export const getPackageAction = (packageId) => async (dispatch) => {
  dispatch(handleRequest(GET_PACKAGE_REQUEST));
  try {
    const res = await getPackageService(packageId);
    if (res?.statusCode === 200) {
      dispatch(handleSuccess(GET_PACKAGE_SUCCESS, res?.data));
    } else {
      let error = (res || {}).error;
      dispatch(handleFailure(GET_PACKAGE_FAILED, error));
    }
    return res;
  } catch (ex) {
    console.log('API FAILED', ex);
    dispatch(handleFailure(GET_PACKAGE_FAILED, ex));
    return ex;
  }
};

// update packages
export const updatePackageAction = (packageId, data) => async (dispatch) => {
  dispatch(handleRequest(UPDATE_PACKAGES_REQUEST, true));
  try {
    const res = await updatePackageService(packageId, data);
    if (res?.statusCode === 201) {
      dispatch(handleSuccess(UPDATE_PACKAGES_SUCCESS, res));
    } else {
      dispatch(handleFailure(UPDATE_PACKAGES_FAILED, res));
    }
    return res;
  } catch (ex) {
    console.log('API FAILED', ex);
    dispatch(handleFailure(UPDATE_PACKAGES_FAILED, ex));
    return ex;
  }
};