import React from 'react'
import ErrorIcon from '../../../assets/error-icon.svg'

export default function ErrorState({message = 'Something went wrong'}) {
  return (
    <div className='no-data-wrapper'>
      <img src={ErrorIcon} className='no-data-img mb-4' alt='no data img'/>
      <h4 className='header-6 light-gray-text'>{message}</h4>
    </div>
  )
}
