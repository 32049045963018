//get trainer list
export const GET_TRAINER_LIST = 'GET_TRAINER_LIST';
export const GET_TRAINER_LIST_REQUEST = 'GET_TRAINER_LIST_REQUEST';
export const GET_TRAINER_LIST_SUCCESS = 'GET_TRAINER_LIST_SUCCESS';
export const GET_TRAINER_LIST_FAILED = 'GET_TRAINER_LIST_FAILED';

// add trainer
export const ADD_TRAINER = 'ADD_TRAINER';
export const ADD_TRAINER_REQUEST = 'ADD_TRAINER_REQUEST';
export const ADD_TRAINER_SUCCESS = 'ADD_TRAINER_SUCCESS';
export const ADD_TRAINER_FAILED = 'ADD_TRAINER_FAILED';

// update trainer
export const UPDATE_TRAINER = 'UPDATE_TRAINER';
export const UPDATE_TRAINER_REQUEST = 'UPDATE_TRAINER_REQUEST';
export const UPDATE_TRAINER_SUCCESS = 'UPDATE_TRAINER_SUCCESS';
export const UPDATE_TRAINER_FAILED = 'UPDATE_TRAINER_FAILED';
