export const GET_PLANS_LIST = 'GET_PLANS_LIST';
export const GET_PLANS_LIST_REQUEST = 'GET_PLANS_LIST_REQUEST';
export const GET_PLANS_LIST_SUCCESS = 'GET_PLANS_LIST_SUCCESS';
export const GET_PLANS_LIST_FAILED = 'GET_PLANS_LIST_FAILED';

export const GET_PLAN = 'GET_PLAN';
export const GET_PLAN_REQUEST = 'GET_PLAN_REQUEST';
export const GET_PLAN_SUCCESS = 'GET_PLAN_SUCCESS';
export const GET_PLAN_FAILED = 'GET_PLAN_FAILED';

export const ADD_PLAN = 'ADD_PLAN';
export const ADD_PLAN_REQUEST = 'ADD_PLAN_REQUEST';
export const ADD_PLAN_SUCCESS = 'ADD_PLAN_SUCCESS';
export const ADD_PLAN_FAILED = 'ADD_PLAN_FAILED';

export const UPDATE_PLAN = 'UPDATE_PLAN';
export const UPDATE_PLAN_REQUEST = 'UPDATE_PLAN_REQUEST';
export const UPDATE_PLAN_SUCCESS = 'UPDATE_PLAN_SUCCESS';
export const UPDATE_PLAN_FAILED = 'UPDATE_PLAN_FAILED';

// add packages
export const ADD_PACKAGES = 'ADD_PACKAGES';
export const ADD_PACKAGES_REQUEST = 'ADD_PACKAGES_REQUEST';
export const ADD_PACKAGES_SUCCESS = 'ADD_PACKAGES_SUCCESS';
export const ADD_PACKAGES_FAILED = 'ADD_PACKAGES_FAILED';

// update packages
export const UPDATE_PACKAGES = 'UPDATE_PACKAGES';
export const UPDATE_PACKAGES_REQUEST = 'UPDATE_PACKAGES_REQUEST';
export const UPDATE_PACKAGES_SUCCESS = 'UPDATE_PACKAGES_SUCCESS';
export const UPDATE_PACKAGES_FAILED = 'UPDATE_PACKAGES_FAILED';

// get packages
export const GET_PACKAGES_LIST = 'GET_PACKAGES_LIST';
export const GET_PACKAGES_LIST_REQUEST = 'GET_PACKAGES_LIST_REQUEST';
export const GET_PACKAGES_LIST_SUCCESS = 'GET_PACKAGES_LIST_SUCCESS';
export const GET_PACKAGES_LIST_FAILED = 'GET_PACKAGES_LIST_FAILED';

// get packages
export const GET_PACKAGE = 'GET_PACKAGE';
export const GET_PACKAGE_REQUEST = 'GET_PACKAGE_REQUEST';
export const GET_PACKAGE_SUCCESS = 'GET_PACKAGE_SUCCESS';
export const GET_PACKAGE_FAILED = 'GET_PACKAGE_FAILED';
