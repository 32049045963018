/* eslint-disable default-param-last */
import { combineReducers } from 'redux';
import * as Types from './visitor.constants';

function makeRequestReducer(actionType, defaultData = [], state = {}) {
  const initialState = {
    data: defaultData,
    error: false,
    loading: false,
    ...state
  };
  return (state = initialState, action) => {
    switch (action?.type) {
      case `${actionType}_REQUEST`:
        return {
          ...state,
          loading: true,
          error: false
        };
      case `${actionType}_SUCCESS`:
        return {
          ...state,
          loading: false,
          error: false,
          data: action?.payload?.data
        };
      case `${actionType}_FAILED`:
        return {
          ...state,
          loading: false,
          error: true,
          data: defaultData
        };
      case `${actionType}_RESET`:
        return state;
      default:
        return state;
    }
  };
}

export default combineReducers({
  visitorsList: makeRequestReducer(Types.GET_VISITOR_LIST),
  addVisitor: makeRequestReducer(Types.ADD_VISITOR),
  updateVisitor: makeRequestReducer(Types.UPDATE_VISITOR),
  visitor: makeRequestReducer(Types.GET_VISITOR, {}),
  visitorsFilters: makeRequestReducer(Types.GET_VISITOR_FILTER, {})
});
