import React, { useState, useEffect } from 'react';
import { Button, LinkButton, OutlineButton } from '../../../../component/button/button';
import CreateBatchModal from '../create-batch-modal/createBatchModal';
import style from './batchCard.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { getBatchListAction } from '../../../../redux/states/batch/batch.actions';
import { MdModeEditOutline } from 'react-icons/md';
import moment from 'moment';
import Loader from '../../../../component/loader/loader';
import ErrorState from '../../../../component/molecule/error-state/errorState';
import EmptyState from '../../../../component/molecule/empty-state/emptyState';

export default function BatchCard() {
  const {
    data: batchList,
    error: batchListError,
    loading: batchListLoading,
    errorMsg: batchListErrorMsg
  } = useSelector((state) => state?.batch?.batchList);

  const { data: createBatchData } = useSelector((state) => state?.batch?.addBatch);
  const { data: updateBatchData } = useSelector((state) => state?.batch?.updateBatch);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBatchListAction());
  }, [createBatchData, updateBatchData]);

  const [isModalActive, updateModal] = useState(false);

  const [modalButtonLabel, updateModalButtonLabel] = useState('Add');

  const [selectedBatch, setSelectedBatch] = useState(null);

  const handleClose = () => {
    updateModal(false);
  };

  const openBatchModal = () => {
    updateModalButtonLabel('Add');
    setSelectedBatch(null);
    updateModal(true);
  };

  const updateBatchModal = (batchId) => {
    updateModalButtonLabel('Update');
    const batchToBeUpdated = batchList?.find((batch) => batch?.id === batchId);
    setSelectedBatch({ ...batchToBeUpdated });
    updateModal(true);
  };

  const formatBatchTime = (time) => moment(time, 'HH:mm:ss').format('hh:mm A');

  return (
    <>
      <div className={style.card}>
        {batchList?.length !== 0 && (
          <>
            <div className={style.cardHeader}>
              <h4 className="header-3 mb-0">Batches</h4>
              <Button label={'Add Batch'} class="btn-primary mb-lg-0" handleBtnClick={openBatchModal} />
            </div>
          </>
        )}
        {batchListLoading ? (
          <Loader />
        ) : batchListError ? (
          <ErrorState message={batchListErrorMsg} />
        ) : batchList?.length === 0 ? (
          <EmptyState message={'No batch added yet'} btnTitle={'Add Batch'} handleBtnClick={openBatchModal} />
        ) : (
          <div className="table-responsive">
            <table className="table table-borderless text-center">
              {batchList?.length !== 0 && (
                <>
                  <thead className={style.tableHeader}>
                    <tr>
                      <th scope="col">
                        <p className="label fw-bold mb-0 text-start ps-3">Name</p>
                      </th>
                      <th scope="col">
                        <p className="label fw-bold mb-0">Timing</p>
                      </th>
                      <th scope="col">
                        <p className="label fw-bold mb-0">Action</p>
                      </th>
                    </tr>
                  </thead>
                </>
              )}
              <tbody>
                {batchList?.map((batch) => (
                  <tr className={style.tableDataRow} key={batch?.id}>
                    <td>
                      <p className="label fw-bold mb-0 text-start ps-3">{batch?.name}</p>
                    </td>
                    <td>
                      <p className="label  mb-0">
                        {formatBatchTime(batch?.startsAt)} - {formatBatchTime(batch?.endsAt)}
                      </p>
                    </td>

                    <td>
                      <MdModeEditOutline size={20} className="text-primary" onClick={() => updateBatchModal(batch?.id)} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <CreateBatchModal active={isModalActive} onClose={handleClose} label={modalButtonLabel} selectedBatch={selectedBatch} />
    </>
  );
}
