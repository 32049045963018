import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import './customPagination.css';

function CustomPagination({ setPageNum, pageNum, totalPage }) {
  const Pages = ({ totalPage }) => {
    let pageArray = [];

    for (let i = 1; i <= totalPage; i++) {
      pageArray.push(
        <div
          key={i}
          onClick={() => setPageNum(i)}
          className={ ` pageNo ${pageNum == i && 'active'} ${
            i <= pageNum - 3 || i >= pageNum || i <= pageNum + 3 ? 'page-visible ' : 'page-invisible'
          } cursor-pointer`}
        >
          {i}
        </div>
      );
    }
    return pageArray;
  };

  return (
    <div className="pagination text-xs lg:text-base mt-3">
      {pageNum != 1 &&  <span onClick={() => (pageNum > 1 ? setPageNum((prev) => prev - 1) : '')} className={'cursor-pointer'}>
        <MdChevronLeft size="24"/>
      </span>}
      <div className='page-body'>
        <Pages totalPage={totalPage} />
      </div>
      { !(pageNum === totalPage) && <span
        onClick={() => (pageNum < totalPage ? setPageNum((prev) => prev + 1) : '')}
        className={(pageNum === totalPage ? 'disabled': 'cursor-pointer') || (totalPage == 0 ? 'disabled' : 'cursor-pointer')}
      >
       <MdChevronRight size="24"/>
      </span>}
    </div>
  );
}

export default CustomPagination;
