import { get, post, patch } from '../../services/api/api';
import {
  baseUrl,
  planDistributionUrl,
  statisticsUrl,
  todaysBirthdayUrl,
  registeredUsersStatsUrl,
  registeredVisitorsStatsUrl
} from '../../services/api/apiConstants';

export const getPlanDistributionService = () => get(baseUrl + planDistributionUrl);

export const getStatisticsService = () => get(baseUrl + statisticsUrl);

export const getTodaysBirthdaysService = () => get(baseUrl + todaysBirthdayUrl);

export const getRegisteredUsersStatsService = () => get(baseUrl + registeredUsersStatsUrl );

export const getRegisteredVisitorsStatsService = () => get(baseUrl + registeredVisitorsStatsUrl);



