import randomColor from 'randomcolor';
import React from 'react';
import './nameInitialsAvatar.css';


export default function NameInitialsAvatar({nameInitials}) {
  return (
    <div className="name-initials" style={{backgroundColor: randomColor({luminosity: 'dark', })}}>
      <h3 className="header-3 fw-bold text-capitalize mb-0" style={{color: randomColor({luminosity: 'light'})}}>{nameInitials}</h3>
    </div>
  );
}
