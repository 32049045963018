import React, { useState } from 'react';
import './packageDetail.css';
import { Button } from '../../../../component/button/button';
import WhatToUpdateModal from '../what-to-update-modal/whatToUpdateModal';
import UpdatePackageModal from '../update-package-modal/updatePackageModal';
import UpdateTrainerModal from '../update-trainer-modal/updateTrainerModal';
import UpdateBatchModal from '../update-batch-modal/updateBatchModal';
import UpcomingPackageDetail from './upcomingPackageDetail';

export default function PackageDetail({
  memberId,
  currentPlan: {
    packageId,
    packagePriceDiscount,
    trainerPriceDiscount,
    package: packageData,
    trainerId,
    batch,
    trainer: trainerData,
    expiresAt,
    packagePriceTotal,
  } = {},
  memberPackageHistoriesId,
  dueAmount = 0,
  isDisabled = false
}) {
  const [updatePackageModal, setUpdatePackageModal] = useState(false);
  const [whatToUpdateModal, setWhatToUpdateModal] = useState(false);
  const [updateTrainerModal, setUpdateTrainerModal] = useState(false);
  const [updateBatchModal, setUpdateBatchModal] = useState(false);

  function handleClose() {
    setWhatToUpdateModal(false);
  }

  function onUpdateValueSelected(selectedValue) {
    handleClose();
    console.log(selectedValue);

    if (selectedValue === 'package') {
      setUpdatePackageModal(true);
    } else if (selectedValue === 'trainer') {
      setUpdateTrainerModal(true);
    } else if (selectedValue === 'batch') {
      setUpdateBatchModal(true);
    }
  }

  return (
    <>
      <div className="card-1">
        <div className="d-flex justify-content-between">
          <p className="header-3">Package Detail</p>
        </div>
        <div className="d-flex justify-content-between">
          <p className="header-8">Package</p>
          <p className="package-type text-end">{packageData?.title}</p>
        </div>
        <div className="d-flex justify-content-between">
          <p className="header-8">Membership</p>
          <p className="package-type">{packageData?.membershipDuration} month</p>
        </div>
        <div className="d-flex justify-content-between">
          <p className="header-8">Expire At</p>
          <p className="package-type">{expiresAt}</p>
        </div>
        <div className="d-flex justify-content-between">
          <p className="header-8">Package Discount %</p>
          <p className="package-type">{packagePriceDiscount}%</p>
        </div>
        <div className="d-flex justify-content-between">
          <p className="header-8">Trainer Discount %</p>
          <p className="package-type">{trainerPriceDiscount}%</p>
        </div>
        <div className="d-flex justify-content-between">
          <p className="header-8">Trainer Taken</p>
          <p className="package-type">{trainerId ? 'Yes' : 'No'}</p>
        </div>
        <div className="d-flex justify-content-between">
          <p className="header-8">Package Fee</p>
          <p className="package-type">₹{packagePriceTotal}</p>
        </div>
        <div className="d-flex justify-content-between">
          <p className="header-8">Due Amount</p>
          <p className="package-type">₹{dueAmount}</p>
        </div>
        {batch?.name && <div className="d-flex justify-content-between">
          <p className="header-8">Batch</p>
          <p className="package-type">{batch?.name}</p>
        </div>}

        <Button
          isDisabled={isDisabled}
          label="Update Package"
          class="btn-primary mt-2 mb-4 mb-lg-0 w-100"
          handleBtnClick={() => setWhatToUpdateModal(true)}
        />
      </div>

      <WhatToUpdateModal
        active={whatToUpdateModal}
        onClose={handleClose}
        onUpdateValueSelected={(selectedValue) => {
          onUpdateValueSelected(selectedValue);
        }}
      />

      {updatePackageModal ? (
        <UpdatePackageModal
          memberId={memberId}
          expiresAt={expiresAt}
          packageData={packageData}
          memberPackageHistoriesId={memberPackageHistoriesId}
          dueAmount={dueAmount}
          active={updatePackageModal}
          onClose={() => setUpdatePackageModal(false)}
        />
      ) : null}

      {updateTrainerModal ? (
        <UpdateTrainerModal
          memberId={memberId}
          expiresAt={expiresAt}
          trainerData={trainerData}
          packageId={packageId}
          memberPackageHistoriesId={memberPackageHistoriesId}
          dueAmount={dueAmount}
          active={updateTrainerModal}
          onClose={() => setUpdateTrainerModal(false)}
        />
      ) : null}

      {updateBatchModal ? (
        <UpdateBatchModal
          memberId={memberId}
          active={updateBatchModal}
          batchData={batch}
          memberPackageHistoriesId={memberPackageHistoriesId}
          onClose={() => setUpdateBatchModal(false)}
        />
      ) : null}
    </>
  );
}
