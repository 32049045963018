import React, { useCallback, useState } from 'react';
import FilterCard from '../../../../component/filter-card/filterCard';
import { useNavigate, useLocation} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getMemberFiltersAction, getMemberListAction } from '../../../../redux/states/member/member.actions';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button} from '../../../../component/button/button';
import Loader from '../../../../component/loader/loader';
import ErrorState from '../../../../component/molecule/error-state/errorState';
import EmptyState from '../../../../component/molecule/empty-state/emptyState';
import SearchBar from '../../../../component/molecule/search-bar/searchBar';
import { debounce } from '../../../../utils/utils';
import MemberListTable from '../member-list-table/memberListTable';
import SmsTemplateModal from '../../../../component/molecule/sms-template-modal/smsTemplateModal';
import CustomPagination from '../../../../component/custom-pagination/customPagination';
import BulkImportModal from '../../../../component/molecule/bulk-import-modal/bulkImportModal';



export default function MemberList() {
  let navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();

  const paymentStatusParam = state?.paymentStatus;

  const {
    data: { data: memberListData, total },
    loading: memberLoading,
    error: memberError
  } = useSelector((state) => state?.member?.memberList);
  const { data: memberFiltersData, loading: memberFilterLoading } = useSelector((state) => state?.member?.memberFilters);

  const [checkedFilter, setCheckedFilter] = useState([]);
  const [searchedValue, setSearchedValue] = useState('');
  const [smsTemplateModal, setSmsTemplateModal] = useState(false);
  const [bulkImportModalFlag, setBulkImportModalFlag] = useState(false);
  const [sendMessageVisible, setSendMessageVisible] = useState(false);
  const [pageNum, setPageNum] = useState(1);

  useEffect(() => {
    dispatch(getMemberFiltersAction());
  }, [dispatch]);

  useEffect(() => {
    if (paymentStatusParam) {
      setCheckedFilter([{ name: 'Payment Due', id: paymentStatusParam, type: 'Payment Status' }]);
    }
  }, [paymentStatusParam]);

  useEffect(() => {
    dispatch(getMemberListAction(checkedFilter, '', pageNum));
    if (checkedFilter?.length === 0) {
      setSendMessageVisible(false);
    } else {
      setSendMessageVisible(true);
    }
  }, [checkedFilter, pageNum, dispatch]);

  const handleDebounceFn = (inputValue) => {
    dispatch(getMemberListAction(checkedFilter, inputValue));
  };

  const debounceFn = useCallback(debounce(handleDebounceFn, 1000), []);

  const handleSearchChange = (val) => {
    setSearchedValue(val);
    debounceFn(val);
  };

  const handleSetSmsTemplateModal = () => {
    setSmsTemplateModal(true);
  };

  const handleSetBulkImportModal = () => {
    setBulkImportModalFlag(true);
  };

  function handleClose() {
    setSmsTemplateModal(false);
  }

  function handleBulkClose() {
    setBulkImportModalFlag(false);
  }

  return (
    <>
      <div className="row justify-content-between">
        <div className="col-lg-6">
          <h3 className="header-2 mb-0">Member List</h3>
        </div>

        <div className="col-lg-6 mt-4 mt-lg-0">
          <div className="d-flex justify-content-md-end justify-content-start">
            {/* <Button
                label='Bulk Import'
                class="btn-primary me-2"
                handleBtnClick={handleSetBulkImportModal}
              /> */}
            {memberListData?.length > 0 && (
              <Button
                label={`Send Message ${sendMessageVisible ? `(${total})` : ''}`}
                isDisabled={!sendMessageVisible}
                class="btn-tertiary me-2"
                handleBtnClick={handleSetSmsTemplateModal}
              />
            )}
            <Button label="Add Member" class="btn-primary" handleBtnClick={() => navigate('/dashboard/add-member')} />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-8">
          <div className="search-bar-wrapper">
            <SearchBar searchedValue={searchedValue} handleSearchChange={handleSearchChange} />
          </div>
        </div>
      </div>

      <div className="row mt-5">
        <div className="col-md-8 order-md-1 order-2">
          {memberLoading ? (
            <Loader />
          ) : memberError ? (
            <ErrorState />
          ) : checkedFilter?.length > 0 && memberListData?.length === 0 ? (
            <EmptyState message={'No member found'} />
          ) : memberListData?.length === 0 ? (
            <EmptyState message={'No member added yet'} btnTitle={'Add member'} handleBtnClick={() => navigate('/dashboard/add-member')} />
          ) : (
            <>
              <MemberListTable showHeader={false} data={memberListData} />
              {total > 10 ? <CustomPagination pageNum={pageNum} setPageNum={setPageNum} totalPage={Math.ceil(total / 10)} /> : null}
            </>
          )}
        </div>
        <div className="col-md-4 order-md-2 order-1">
          <FilterCard data={memberFiltersData} loader={memberFilterLoading} checkedFilter={checkedFilter} setCheckedFilter={setCheckedFilter} />
        </div>
      </div>
      {memberListData?.length > 0 && (
        <SmsTemplateModal
          active={smsTemplateModal}
          onClose={handleClose}
          recipients={memberListData.map((member) => member?.id)}
          recipientType={'member'}
          checkedFilter={checkedFilter}
          totalMember={total}
        />
      )}
      <BulkImportModal active={bulkImportModalFlag} onClose={handleBulkClose} />
    </>
  );
}
