import React, { useEffect } from 'react';
import InputField from '../../../../atoms/inputField/inputField';
import { Button, OutlineButton } from '../../../../component/button/button';
import { useForm , Controller} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import SelectField from '../../../../atoms/selectField/selectField';
import { useDispatch, useSelector } from 'react-redux';
import { getMembershipDurationsAction } from '../../../../redux/states/member/member.actions';
import { addVisitorAction, getVisitorAction, updateVisitorAction } from '../../../../redux/states/visitor/visitor.actions';
import { getCurrentDate, handleUseFormError } from '../../../../utils/utils';
import { toast } from 'react-toastify';
import { getBatchListAction } from '../../../../redux/states/batch/batch.actions';
import { useLocation, useNavigate } from 'react-router-dom';
import MultiSelectField from '../../../../atoms/multiSelectField/multiSelectField';
import { getPackageAction, getPackagesListAction } from '../../../../redux/states/plan/plan.actions';
import { genderOptions } from '../../../../utils/constants';

const schema = yup
  .object({
    name: yup.string().required(),
    email: yup.string().email('Must be a valid email'),
    mobile: yup.string().max(10).min(10).required(),
    enquiryAt: yup.string().required(),
    package: yup.object().required(),
    batch: yup.object(),
    genderVal: yup.object().required()
  })
  .required();



export default function AddVisitor() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    onClose,
    control
  } = useForm({
    resolver: yupResolver(schema)
  });

  const { data: packagesListData } = useSelector((state) => state?.plan?.packagesList);
  const { data: batchListData } = useSelector((state) => state?.batch?.batchList);
  const { data: visitorData } = useSelector((state) => state?.visitor?.visitor);

  const { loading: addVisitorLoading } = useSelector((state) => state?.visitor?.addVisitor);

  const { loading: updateVisitorLoading } = useSelector((state) => state?.visitor?.updateVisitor);

  const updateId = state?.visitorId || false;

  useEffect(() => {
    if (updateId) {
      dispatch(getVisitorAction(updateId));
    }
  }, [updateId]);

  useEffect(() => {
    // get all membership duration
    setValue('enquiryAt', getCurrentDate());
    dispatch(getPackagesListAction());
    dispatch(getBatchListAction());
  }, []);

  useEffect(() => {
    if (updateId) {
      const genderLabel = genderOptions?.find((item) => item?.value === visitorData?.gender);

      setValue('name', visitorData?.name);
      setValue('mobile', visitorData?.mobile);
      setValue('email', visitorData?.email);
      setValue('email', visitorData?.email);
      setValue('enquiryAt', visitorData?.enquiryAt?.slice(0, 10));
      setValue('genderVal', { value: visitorData?.gender, label: genderLabel?.label });
      setValue('package', { value: visitorData?.packages?.id, label: visitorData?.packages?.title });
      setValue('batch', { value: visitorData?.batches?.id, label: visitorData?.batches?.name });
    }
  }, [visitorData, updateId]);

  const onSubmit = async (data) => {
    const tempBody = {
      batchId: data?.batch?.value,
      packageId: data?.package?.value,
      gender: data?.genderVal?.value,
      ...data
    };
    delete tempBody.batch;
    delete tempBody.package;
    delete tempBody.genderVal;
    
    if (updateId) {
      const res = await dispatch(updateVisitorAction(updateId, tempBody));
      if (res?.statusCode === 201) {
        toast.success('Visitor updated successfully.');
        navigate('/dashboard/visitor-list');
      } else {
        handleUseFormError(res);
      }
      return;
    }

    const res = await dispatch(addVisitorAction(tempBody));
    if (res?.statusCode === 201) {
      toast.success('Visitor added successfully.');
      reset();
      navigate('/dashboard/visitor-list');
    } else {
      handleUseFormError(res);
    }
  };

  return (
    <>
      <div className="d-flex justify-content-start px-3">
        <h3 className="header-2 mb-3">{updateId ? 'Update Visitor' : 'Add Visitor'}</h3>
      </div>
      <div className="card mx-3">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-md-4 p-0">
              <h1 className="header-7 mt-2">Personal Info</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 mb-3 ps-0">
              <InputField placeholder="Enter Name" label="Name" type="text" name={'name'} register={register} error={errors.name?.message} />
            </div>

            <div className="col-md-4 mb-3 ps-0">
              <InputField
                placeholder="Enter Mobile Number"
                label="Mobile"
                type="number"
                name={'mobile'}
                register={register}
                error={errors.mobile?.message}
                maxLength={10}
              />
            </div>
            <div className="col-md-4 mb-3 ps-0">
              <InputField
                placeholder="Enter Email Address"
                label="Email"
                type="email"
                name={'email'}
                register={register}
                error={errors.email?.message}
                maxLength={40}
              />
            </div>
            <div className="col-md-4 mb-3 ps-0">
              <InputField
                placeholder="Enter Enquiry"
                label="Date of enquiry"
                type="date"
                name={'enquiryAt'}
                register={register}
                error={errors.enquiryAt?.message}
              />
            </div>
            <div className="col-md-4 mb-3 ps-0">
              <Controller
                name="genderVal"
                control={control}
                render={({ field: { onChange, value }, fieldState }) => {
                  return (
                    <MultiSelectField
                      label={'gender'}
                      onChange={onChange}
                      value={value}
                      placeholder={'Select Gender'}
                      options={genderOptions}
                      noOptionsMessage={'Please select gender.'}
                      error={fieldState?.error?.message}
                    />
                  );
                }}
              />
            </div>
            <div className="col-md-4 mb-3 ps-0">
              <Controller
                name="package"
                control={control}
                render={({ field: { onChange, value }, fieldState }) => (
                  <MultiSelectField
                    label={'Interested package'}
                    onChange={onChange}
                    value={value}
                    placeholder={'Select Package'}
                    options={packagesListData?.map((packageItem) => ({ value: packageItem?.id, label: packageItem?.title }))}
                    noOptionsMessage={'Please select package.'}
                    error={fieldState?.error?.message}
                  />
                )}
              />
            </div>
            <div className="col-md-4 mb-3 ps-0">
              <Controller
                name="batch"
                control={control}
                render={({ field: { onChange, value }, fieldState }) => (
                  <MultiSelectField
                    label={'Interested batch'}
                    onChange={onChange}
                    value={value}
                    placeholder={'Select Batch'}
                    options={batchListData?.map((batch) => ({ value: batch?.id, label: batch?.name }))}
                    noOptionsMessage={'Please create batch before adding visitor'}
                    error={fieldState?.error?.message}
                  />
                )}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 mt-3 ps-0">
              <Button label={updateId ? 'Update' : 'Add'} isLoading={addVisitorLoading || updateVisitorLoading} isDisabled={addVisitorLoading || updateVisitorLoading} type="submit" class="btn-primary w-100" />
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
