import React from 'react';
import Avatar0 from '../../../../assets/avatar0.png';
import PlaceHolderUser from '../../../../assets/placeHolderUser.png';
import Right0 from '../../../../assets/right0.png';
import SmallIcon from '../../../../assets/smallicon.png';
import { formatDate } from '../../../../utils/utils';
import './gymProfileDetail.css';

const DATE_FORMAT ="Do MMM, YYYY";

export default function GymProfileDetail({profileData}) {

  const gymData = profileData?.gym;
  const addressData = gymData?.address;

  return (
    <>
      <div className="card-1">
        <div className="row">
          <div className="col-lg-8 p-0">
            <div className="row">
              <div className="col-lg-3 mb-4 mt-lg-0">
                <img src={gymData?.logo ?? PlaceHolderUser } className="avatar0" />
              </div>
              <div className="col-lg-8">
                <div className="">
                  <h1 className="header-7 m-0">{gymData?.name}</h1>
                  <p className="subHeading">{profileData?.email}</p>
                  <div className="row">
                    {profileData?.blocked === 0 &&
                    <div className="col-lg-5 d-flex p-0">
                      <div>
                        <img src={Right0} alt="" className="right0" />
                      </div>
                      <div className="ps-2">
                        <p className="header-7 m-0">Active</p>
                        <p className="label">Status</p>
                      </div>
                    </div>
                    }
                    <div className="col-lg-5 d-flex p-0">
                      <div>
                        <img src={SmallIcon} className="small-icon" />
                      </div>
                      <div className="ps-2">
                        <p className="header-7 m-0">{profileData?.mobile}</p>
                        <p className="label">Mobile</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <Button label="View Attendance" class="btn-primary mt-5 mb-4 mb-lg-0" /> */}
            <div className="row">
              <div className="col-8 ps-3 ">
                <p className="header-8 text-start mb-1">Address</p>
                <p className="package-type text-start">{addressData?.addressLine1}</p>
              </div>
            </div>
          </div>

          <div className="col-lg-4 p-0">
            <div className="d-flex justify-content-between"></div>
            <div className="row gx-0">
              <div className="col-6">
                <p className="header-8 ps-2 text-start">Owner Name</p>
              </div>
              <div className="col-6 ">
                <p className="package-type text-end">{gymData?.ownerName}</p>
              </div>

              <div className="col-6">
                <p className="header-8 ps-2 text-start">Joined at</p>
              </div>
              <div className="col-6 ">
                <p className="package-type text-end">{formatDate(gymData?.createdAt, DATE_FORMAT)}</p>
              </div>

              <div className="col-6">
                <p className="header-8 ps-2 text-start">Expire at</p>
              </div>
              <div className="col-6 ">
                <p className="package-type text-end">{formatDate(gymData?.planExpiresAt, DATE_FORMAT)}</p>
              </div>

              <div className="col-6">
                <p className="header-8 ps-2 text-start">City</p>
              </div>
              <div className="col-6 ">
                <p className="package-type text-end">{addressData?.city?.name}</p>
              </div>
              <div className="col-6">
                <p className="header-8 ps-2 text-start">State</p>
              </div>
              <div className="col-6 ">
                <p className="package-type text-end">{addressData?.state?.name}</p>
              </div>
              <div className="col-6">
                <p className="header-8 ps-2 text-start">Country</p>
              </div>
              <div className="col-6 ">
                <p className="package-type text-end">{addressData?.country?.name}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
