import React, { useState } from 'react';
import TeamDiscussion from '../../../assets/team-discussion.svg';
import Logo from '../../../assets/logo.svg';
import './login.css';
import InputField from '../../../atoms/inputField/inputField';
import { Button, LinkButton } from '../../../component/button/button';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { postLoginAction, validateOtpAction } from '../../../redux/states/auth/auth.actions';
import { saveData } from '../../../redux/services/handleData/localStorage.ts';
import { toast } from 'react-toastify';
import { handleUseFormError } from '../../../utils/utils';

const mobileSchema = yup
  .object({
    mobile: yup.string().length(10).required()
  })
  .required();

const loginSchema = yup
  .object({
    mobile: yup.string().length(10).required(),
    otp: yup.string().length(4).required()
  })
  .required();

export default function Login() {
  const dispatch = useDispatch();
  const { loading: postLoginLoading } = useSelector((state) => state.auth?.postLogin);
  const { loading: validateOtpLoading } = useSelector((state) => state.auth?.validateOtp);

  const [requestOtp, setRequestOtp] = useState(false);
  const [resendOtp, setResendOtp] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors, values },
    getValues
  } = useForm({
    resolver: yupResolver(requestOtp ? loginSchema : mobileSchema)
  });

  const navigate = useNavigate();

  const handleSendOtp = async (data) => {
    const res = await dispatch(postLoginAction({ mobile: data?.mobile }));
    if (res?.statusCode === 200) {
      setRequestOtp(true);
      toast.success(res?.message);
    } else {
      handleUseFormError(res);
    }
  };

  const onSubmit = async (data) => {
    if (!requestOtp || resendOtp) {
      handleSendOtp(data);
    } else {
      const res = await dispatch(validateOtpAction({ mobile: data?.mobile, otp: data?.otp }));
      if (res?.statusCode === 200) {
        saveData('token', res?.data?.token);
        saveData('isPaymentDone', res?.data?.isPaymentDone);
        navigate('/dashboard');
        window.location.reload();
      } else {
        handleUseFormError(res);
      }
    }
  };

  const handleResendOtp = () => {
    setResendOtp(true);
    handleSendOtp({ mobile: getValues('mobile') });
  };

  return (
    <div className="row gx-0">
      <div className="col-md-8">
        <div className="illustration-area-wrapper">
          <div className="header">
            <img alt="header" src={Logo} />
          </div>
          <div className="illustration-area">
            <img alt="team" src={TeamDiscussion} />
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <div className="main-content-wrapper">
          <h5 className="header-3">Welcome to GymHeight!</h5>
          <p className="subHeading">Please sign-in to your account and start the adventure</p>
          <br />

          <form onSubmit={handleSubmit(onSubmit)}>
            <InputField
              placeholder="1234567890"
              label="Mobile"
              type="text"
              name={'mobile'}
              isDisabled={requestOtp}
              register={register}
              error={errors.mobile?.message}
            />

            {requestOtp ? (
              <InputField placeholder="0000" label="OTP" type="text" name={'otp'} maxLength={4} register={register} error={errors.otp?.message} />
            ) : null}

            <Button
              class="btn-primary mt-4 w-100"
              isLoading={requestOtp ? validateOtpLoading : postLoginLoading}
              label={requestOtp ? 'Login' : 'Request Otp'}
              type="submit"
            />
          </form>

          <div className="text-center mt-3">
            <p className="subHeading">
              New on our platform?{' '}
              <Link to="/register">
                <LinkButton label="Create an account" className="text-primary subHeading" />
              </Link>
            </p>
            <LinkButton handleBtnClick={handleResendOtp} label="Resend Otp" className="text-primary subHeading" />
          </div>
        </div>
      </div>
    </div>
  );
}
