import React from 'react'
import { Button } from '../../../../component/button/button'
import { formatDate } from '../../../../utils/utils';
import './currentPlan.css'

const DATE_FORMAT ="Do MMM, YYYY";

export default function CurrentPlan({ planDetails, expiredAt }) {
  return (
    <>
      <div className="card-1 current-plan-box">
        <div className="d-flex justify-content-between mb-4">
          <p className="header-8 fs-6 m-0">Current Plan</p>
          <p className="header-8 plan-date fw-bold text-primary m-0">{formatDate(expiredAt)}</p>
        </div>
        <div className="mb-5">
          {planDetails?.benefits.map((benefit) => (
            <p className="package-type mb-2" key={benefit?.id}>{benefit.title}</p>
          ))}
        </div>
        <Button label="Upgrade Plan " class="btn-primary w-100 mb-4 mb-lg-0" />
      </div>
    </>
  );
}
