import React, { useState } from 'react';
import './memberListTable.css';
import Avatar1 from '../../../../assets/avatar1.png';
import { useNavigate } from 'react-router-dom';
import { LinkButton } from '../../../../component/button/button';
import NameInitialsAvatar from '../../../../component/molecule/name-initials-avatar/nameInitialsAvatar';
import MemberStatusUpdateModal from '../member-status-update-modal/memberStatusUpdateModal';
import { MdOutlineMode } from 'react-icons/md';
import { MemberStatus } from '../../../../utils/enums.ts';

MemberListTable.defaultProps = {
  showHeader: true
};

export default function MemberListTable(props) {
  const { data } = props;
  const navigate = useNavigate();

  function onClick(id) {
    navigate('/dashboard/member-detail', { state: { memberId: id } });
  }

  return (
    <div className="table-wrapper">
      {props.showHeader && (
        <div className="table-title-wrapper d-flex justify-content-between">
          <h4 className="header-3 mb-0">Expired Plan Members</h4>
          <LinkButton label="View All" class="text-primary subHeading" />
        </div>
      )}
      <div className="table-responsive">
        <table className="table table-borderless text-center">
          <thead className={'table-header-area'}>
            <tr>
              <th scope="col">
                <p className="label fw-bold mb-0 text-start ps-4">NAME</p>
              </th>
              <th scope="col">
                <p className="label fw-bold  mb-0 text-center text-nowrap">Due Amount</p>
              </th>
              <th scope="col">
                <p className="label fw-bold mb-0 text-center">Status</p>
              </th>
              <th scope="col">
                <p className="label fw-bold mb-0 text-center">ACTIONS</p>
              </th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, index) => {
              return (
                <tr className={'table-row'} key={index}>
                  <td>
                    <div className="ps-4">
                      <div className="d-flex">
                        {item?.user?.profilePic && (
                          <div className="small-avatar">
                            <img src={item?.user?.profilePic} />
                          </div>
                        )}
                        {!item?.user?.profilePic && <NameInitialsAvatar nameInitials={item?.user?.name[0]} />}
                        <div className="ms-3">
                          <p className="subHeading fw-bold  mb-0 text-primary text-start text-nowrap">{item?.user?.name}</p>
                          <p className="label mb-0 text-start">{item?.user?.mobile}</p>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <p className="package-type  mb-0 text-center">₹{item?.dueAmount}</p>
                  </td>
                  <td>
                    { <div className={`status-edit-wrapper ${MemberStatus[item?.status] === MemberStatus.ACTIVE ? 'active-status': ''} ${MemberStatus[item?.status] === MemberStatus.LEFT ? 'left-status': ''} ${MemberStatus[item?.status] === MemberStatus.FREEZE ? 'freeze-status': ''}`}>
                      <p className="mb-0 text-center text-nowrap"> 
                        {MemberStatus[item?.status]}
                    </p>
                    </div>}
                  </td>
                  <td>
                    <p className="label flex-grow-1 mb-0 text-center">
                      <LinkButton label="View Profile" class="text-primary label" handleBtnClick={() => onClick(item?.id)} />
                    </p>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
