import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../../component/button/button';
import Loader from '../../../../component/loader/loader';
import EmptyState from '../../../../component/molecule/empty-state/emptyState';
import ErrorState from '../../../../component/molecule/error-state/errorState';
import { getTrainerListAction } from '../../../../redux/states/trainer/trainer.actions';
import TrainerCard from '../trainer-card/trainerCard';

export default function TrainerList() {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { data: trainerList, error: trainerListError, loading: trainerListLoading, errorMsg: trainerListErrorMsg } = useSelector(state => state?.trainer?.trainerList)

  useEffect(() => {
    dispatch(getTrainerListAction());
  }, []);


  return (
    <>
      <div className='d-flex justify-content-between px-0 mb-5'>
        <h3 className='header-2 mb-0'>Trainer List</h3>
        {trainerList?.length !== 0 &&
          <Button label="Add Trainer" class="btn-primary" handleBtnClick={() => navigate('/dashboard/add-trainer')} />
        }
      </div>
      <div className="row">
        {trainerListLoading ? (<Loader />) : trainerListError ?
          (<ErrorState message={trainerListErrorMsg} />) : trainerList?.length === 0 ?
            (
              <EmptyState message={'No trainer added yet'} btnTitle={'Add Trainer'} handleBtnClick={() => navigate('/dashboard/add-trainer')} />
            ) :
            trainerList?.map((trainer) => (
              <div className="col-lg-6 col-xl-4  mb-4" key={trainer?.id}>
                <TrainerCard trainer={trainer} />
              </div>

            ))}
      </div>
    </>
  )
}
