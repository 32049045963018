import React from 'react'
import './loader2.css'

export default function Loader2() {
  return (
    <div className='position-relative'>
      <span className='spinner-2'></span>
    </div>
  );
}
