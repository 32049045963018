import React, { useState, useEffect } from 'react';
import { MdClose } from 'react-icons/md';
import { Button } from '../../button/button';
import SmsModal from '../sms-modal/smsModal';
import { useDispatch, useSelector } from 'react-redux';
import { getSmsTemplatesAction } from '../../../redux/states/sms/sms.actions';
import Loader from '../../loader/loader';
import ErrorState from '../error-state/errorState';
import Loader2 from '../../loader2/loader2';


export default function SmsTemplateModal({ onClose, active, recipients, recipientType, checkedFilter, totalMember }) {
  const [smsModal, setSmsModal] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const dispatch = useDispatch();

  const {
    data: smsTemplates,
    error: smsTemplatesError,
    loading: smsTemplatesLoading,
    errorMsg: smsTemplatesErrorMsg
  } = useSelector((state) => state?.sms?.smsTemplates);

  const { data: availableSmsCount } = useSelector((state) => state?.sms?.availableSmsCount);

  useEffect(() => {
    if (active) dispatch(getSmsTemplatesAction());
  }, [active]);

  const onTemplateSelect = (e) => {
    e.preventDefault();
    handleTemplateModalClose(true);
    setSmsModal(true);
  };

  const handleTemplateModalClose = (fromSubmitTemplateSelect) => {
    onClose();
    if (!fromSubmitTemplateSelect) {
      setSelectedTemplate(null);
    }
  };

  const handleSmsModalClose = () => {
    setSmsModal(false);
    setSelectedTemplate(null);
  };

  return (
    <>
      <div className="modal" id="staticBackdrop" style={{ display: active ? 'block' : 'none' }}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <MdClose size={30} onClick={() => handleTemplateModalClose(false)} className="modal__icon" />
            <div className="modal-header">
              <h5 className="modal-title header-4" id="staticBackdropLabel">
                {'Select SMS Template'}
              </h5>
            </div>
            <div className="modal-body">
              <form onSubmit={onTemplateSelect}>
                {smsTemplatesLoading ? (
                  <Loader2 />
                ) : smsTemplatesError ? (
                  <ErrorState message={smsTemplatesErrorMsg} />
                ) : availableSmsCount > 0 ? (
                  smsTemplates?.map((template) => (
                    <div className="form-check mb-3" key={template?.id}>
                      <input
                        className="form-check-input"
                        type="radio"
                        name={'templateRadio'}
                        id={template?.id}
                        onChange={() => setSelectedTemplate(template)}
                      />
                      <label className="form-check-label" htmlFor={template?.id}>
                        {template?.title}
                      </label>
                    </div>
                  ))
                ) : (
                  'You dont have sms balance left. Please recharge !'
                )}
                <Button label={'Continue'} isDisabled={smsTemplatesError || !selectedTemplate} type="submit" class="btn-primary w-100 mt-4" />
              </form>
            </div>
          </div>
        </div>
      </div>
      <SmsModal
        active={smsModal}
        onClose={handleSmsModalClose}
        recipients={recipients}
        recipientType={recipientType}
        template={selectedTemplate}
        checkedFilter={checkedFilter}
        totalMember={totalMember}
      />
    </>
  );
}
