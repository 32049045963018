import { get, patch, post } from '../../services/api/api';
import { baseUrl, visitorsFiltersUrl, visitorsStatusUrl, visitorsUrl } from '../../services/api/apiConstants';

export const getVisitorsListService = (urlParams) => get(baseUrl + visitorsUrl + urlParams);

export const getVisitorService = (id) => get(baseUrl + visitorsUrl + `/${id}`);

export const addVisitorService = (data) => post(baseUrl + visitorsUrl, data);

export const updateVisitorService = (id, data) => patch(baseUrl + visitorsUrl + `/${id}`, data);

// export const updateVisitorsStatusService = (id, status) =>
//   patch(
//     String(baseUrl + visitorsStatusUrl)
//       ?.replace('${id}', id)
//       ?.replace('${status}', status)
//   );

export const updateVisitorsStatusService = (id, status) => patch(baseUrl + visitorsUrl + `/${id}` + visitorsStatusUrl + status);

export const getVisitorsFiltersService = () => get(baseUrl + visitorsFiltersUrl);
