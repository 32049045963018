import React from 'react';
import './trainerDetail.css';

export default function TrainerDetail({ trainerName, trainerPriceTotal }) {
  return (
    <div className="card-1">
      <div className="row">
        <div className="col-lg-12 p-0">
          <p className="header-7">Trainer Details</p>
        </div>
      </div>
      <div className="row justify-content-between">
        <div className="col-6 p-0">
          <p className="header-8">Name</p>
        </div>
        <div className="col-6 p-0">
          <p className="subHeading text-end">{trainerName}</p>
        </div>
      </div>
      <div className="row justify-content-between">
        <div className="col-6 p-0">
          <p className="header-8">Fee</p>
        </div>
        <div className="col-6 p-0">
          <p className="subHeading text-end">₹{trainerPriceTotal}</p>
        </div>
      </div>
    </div>
  );
}
