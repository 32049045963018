import React, { useEffect, useState } from 'react';
import { MdClose } from 'react-icons/md';
import { Button } from '../../../../component/button/button';

export default function WhatToUpdateModal({ onClose, active, onUpdateValueSelected }) {
  const [radioBtnValue, setRadioBtnValue] = useState('package');

  function handleModalClose() {
    onClose();
  }

  return (
    <div className="modal" id="staticBackdrop" style={{ display: active ? 'block' : 'none' }}>
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <MdClose size={30} onClick={() => handleModalClose(false)} className="modal__icon" />
          <div className="modal-header">
            <h5 className="modal-title header-4" id="staticBackdropLabel">
              {'What to Update?'}
            </h5>
          </div>
          <div className="modal-body">
              <div className="form-check mb-3">
                <input className="form-check-input" type="radio" value={radioBtnValue}  checked={radioBtnValue === 'package'} name={'templateRadio'} id="package" onChange={() => setRadioBtnValue('package')} />
                <label className="form-check-label" htmlFor={'package'}>
                  Package
                </label>
              </div>
              <div className="form-check mb-3">
                <input className="form-check-input" type="radio" value={radioBtnValue} checked={radioBtnValue === 'trainer'} name={'templateRadio'} id="trainer" onChange={() => setRadioBtnValue('trainer')} />
                <label className="form-check-label" htmlFor={'trainer'}>
                  Trainer
                </label>
              </div>

              <div className="form-check mb-3">
                <input className="form-check-input" type="radio" value={radioBtnValue} name={'templateRadio'} id="batch" checked={radioBtnValue === 'batch'} onChange={() => setRadioBtnValue('batch')} />
                <label className="form-check-label" htmlFor={'batch'}>
                  Batch
                </label>
              </div>
              <Button label={'Continue'} class="btn-primary w-100 mt-4" handleBtnClick={()=>{onUpdateValueSelected(radioBtnValue)}}/>
          </div>
        </div>
      </div>
    </div>
  );
}
