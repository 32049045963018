import React from 'react'
import NoData from '../../../assets/no-data.svg'
import { Button } from '../../button/button'
import './emptyState.css'

export default function EmptyState(props) {
  return (
    <div className="no-data-wrapper">
      <img src={NoData} className="no-data-img mb-4" alt="no data img" />
      <h4 className="header-6 light-gray-text">{props.message}</h4>
      {props?.btnTitle ? (
        <Button label={props.btnTitle} isDisabled={props?.isDisabled} class="btn btn-primary mt-md-4 mt-3" handleBtnClick={props.handleBtnClick} />
      ) : null}
    </div>
  );
}
