import React, { useEffect, useState } from 'react';
import { Button, LinkButton } from '../../../component/button/button';
import RegisterImage from '../../../assets/register.svg';
import Logo from '../../../assets/logo.svg';
import '../../auth/login/login.css';
import InputField from '../../../atoms/inputField/inputField';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import SelectField from '../../../atoms/selectField/selectField';
import { getCitiesAction, getCountriesAction, getStatesAction, saveGymAddressAction } from '../../../redux/states/auth/auth.actions';
import { toast } from 'react-toastify';
import { handleUseFormError } from '../../../utils/utils';
import MultiSelectField from '../../../atoms/multiSelectField/multiSelectField';
import { MdLogout } from 'react-icons/md';
import { removeData } from './../../../redux/services/handleData/localStorage.ts';
import { ConfirmationModal } from './../../../component/molecule/modal/modal';




const schema = yup
  .object({
    country: yup.object().nullable().required(),
    state: yup.object().nullable().required(),
    city: yup.object().nullable().required(),
    gymAddress: yup.string().required()
  }).required();

export default function GymAddress() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLogoutModalActive, updateLogoutModal] = useState(false);


  const handleLogout = () => {
    removeData('token');
    removeData('isPaymentDone');
    navigate('/')
    window.location.reload();
  }

  const {
    data: countries,
    error: countriesError,
    loading: countriesLoading
  } = useSelector((state) => state?.auth?.gymAddressCountries);
  const {
    data: states,
    error: statesError,
    loading: statesLoading
  } = useSelector((state) => state?.auth?.gymAddressStates);

  const {
    data: cities,
    error: citiesError,
    loading: citiesLoading
  } = useSelector((state) => state?.auth?.gymAddressCities);

  const {
    data: saveAddressData,
    error: saveAddressError,
    loading: saveAddressLoading
  } = useSelector((state) => state?.auth?.saveAddress);


  const {
    register,
    setValue,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmit = async (data) => {
    const gymAddressInfo = { countryId: data?.country?.value, stateId: data?.state?.value, cityId: data?.city?.value, fullAddress: data?.gymAddress };
    const gymAddressRes = await dispatch(saveGymAddressAction(gymAddressInfo));
    if (gymAddressRes?.statusCode === 200) {
      navigate('/gym-logo');
    } else {
      handleUseFormError(gymAddressRes);
    }
  };


  const handleOnCountryChange = (selectedCountry) => {
    if (selectedCountry) {
      dispatch(getStatesAction(selectedCountry?.value));
    }
  }

  const handleOnStateChange = (selectedState) => {
    if (selectedState) {
      setValue('city', null);
      dispatch(getCitiesAction(selectedState?.value));
    }
  }

  useEffect(() => {
    dispatch(getCountriesAction());
  }, []);

  return (
    <div className="row gx-0">
      <div className="col-md-8">
        <div className="illustration-area-wrapper">
          <div className="header">
            <img alt="logo" src={Logo} />
          </div>
          <div className="illustration-area">
            <img alt="register" src={RegisterImage} />
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <div className="main-content-wrapper position-relative">
          <MdLogout size="24" className="logout-icon-btn" onClick={() => updateLogoutModal(true)}/>
          <h5 className="header-3">Gym Address</h5>
          <p className="subHeading">Add your gym address</p>
          <br />
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="country"
              control={control}
              render={({ field: { onChange, value }, fieldState }) => (
                <MultiSelectField
                  label={'Country'}
                  onChange={(selectedCountry) => {
                    handleOnCountryChange(selectedCountry);
                    onChange(selectedCountry);
                  }}
                  value={value}
                  placeholder={'Select Country'}
                  options={countries.map(country => ({ value: country?.id, label: country?.name }))}
                  error={fieldState?.error?.message}
                />
              )}
            />
            <Controller
              name="state"
              control={control}
              render={({ field: { onChange, value }, fieldState }) => (
                <MultiSelectField
                  label={'State'}
                  onChange={(selectedState) => {
                    handleOnStateChange(selectedState);
                    onChange(selectedState);
                  }}
                  value={value}
                  placeholder={'Select State'}
                  options={states.map(state => ({ value: state?.id, label: state?.name }))}
                  error={fieldState?.error?.message}
                />
              )}
            />
            <Controller
              name="city"
              control={control}
              render={({ field: { onChange, value }, fieldState }) => (
                <MultiSelectField
                  label={'City'}
                  onChange={onChange}
                  value={value}
                  placeholder={'Select City'}
                  options={cities.map(city => ({ value: city?.id, label: city?.name }))}
                  error={fieldState?.error?.message}
                />
              )}
            />
            <InputField
              placeholder="Full Address"
              label="Address"
              type="text"
              name={'gymAddress'}
              register={register}
              error={errors.gymAddress?.message}
              maxLength={80}
            />
            <Button class="btn-primary mt-2 w-100" isLoading={countriesLoading || statesLoading || citiesLoading || saveAddressLoading} label="Next" type="submit" />
          </form>
        </div>
      </div>
      <ConfirmationModal
        title={'Are you sure?'}
        description={'You want to logout'}
        active={isLogoutModalActive}
        onClose={() => {
          updateLogoutModal(false);
        }}
        modalIcon={<MdLogout size={50} className="text-primary" />}
        primaryBtnClick={handleLogout}
        primaryBtnTitle={'Logout'}
        secondaryBtnTite={'Cancel'}
        secondaryBtnClick={() => {
          updateLogoutModal(false);
        }}
      />
    </div>
  );
}
