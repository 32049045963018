import React, { useState } from 'react';
import { useEffect } from 'react';
import { MdSearch } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { getMemberListAction } from '../../../redux/states/member/member.actions';
import './searchBar.css';

export default function SearchBar({ searchedValue, handleSearchChange }) {
  return (
    <div className="search-wrapper">
      <div className="search-input-wrapper">
        <input
          type="text"
          className="search-input"
          placeholder="Search by name, email or number"
          value={searchedValue}
          onChange={(e) => {
            handleSearchChange(e.target.value);
          }}
        />
        <MdSearch size="24" className="search-icon" />
      </div>
      {/* {searchedValue && <div className='suggestion-list'>
        <p className='subHeading fw-bold text-dark'>Mohammad Salah</p> 
        <p className='subHeading fw-bold text-dark'> Babar Azam</p> 
        <p className='subHeading fw-bold text-dark'> Virendra Sehwag</p> 
        <p className='subHeading fw-bold text-dark'> Irfan Pathan</p> 
        <p className='subHeading fw-bold text-dark'> Rohit Sharma</p> 
      </div>} */}
    </div>
  );
}
