import React, { useState, useEffect } from 'react';
import { MdClose } from 'react-icons/md';
import { FcDownload } from 'react-icons/fc';
import { Button, LinkButton } from '../../button/button';
import { useDispatch, useSelector } from 'react-redux';
import importSample from '../../../assets/sheet/Test_member_import_data.xlsx';
import InputField from '../../../atoms/inputField/inputField';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { handleUseFormError } from '../../../utils/utils';
import { bulkAddMemberAction, getMemberListAction } from '../../../redux/states/member/member.actions';



const schema = yup
  .object({
    importSheet: yup.mixed().test('required',
      'Please select your import sheet!',
      value => {
        return value && value.length;
      })
  })
  .required();


export default function BulkImportModal({ onClose, active }) {


  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading: bulkAddMemberLoading } = useSelector((state) => state?.member?.bulkAddMember);



  const handleTemplateModalClose = () => {
    onClose();
    reset();
  }

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  });


  const onSubmit = async (data) => {
    const selectedSheet = data?.importSheet[0];
      if (selectedSheet?.size > 0) {
        const sheetFormData = new FormData();
        sheetFormData.append('file', selectedSheet, selectedSheet?.name);
        const bulkImportRes = await dispatch(bulkAddMemberAction(sheetFormData));
        if (bulkImportRes?.statusCode === 201) {
          toast.success('Members imported successfully.');
          handleTemplateModalClose();
          dispatch(getMemberListAction([], '', 1));
          navigate('/dashboard/member-list');
        } else {
          handleUseFormError(bulkImportRes);
        }
      }
  };
  return (
    <>
      <div className="modal" id="staticBackdrop" style={{ display: active ? 'block' : 'none' }}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <MdClose size={30} onClick={() => handleTemplateModalClose(false)} className="modal__icon" />
            <div className="modal-header">
              <h5 className="modal-title header-4" id="staticBackdropLabel">
                {'Bulk Import Gym Members'}
              </h5>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-check mb-3">
                  <Link to={importSample} target='_blank' download={true} >
                    <FcDownload size={16} className="me-2" />
                    <LinkButton label="Download Bulk Import Template" class="text-primary subHeading" />
                  </Link>
                  <InputField
                    type="file"
                    name={'importSheet'}
                    register={register}
                    error={errors?.importSheet?.message}
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  />
                </div>
                <Button label={'Bulk Import'} type="submit" isDisabled={bulkAddMemberLoading} isLoading={bulkAddMemberLoading} class="btn-primary w-100 mt-4" />
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
