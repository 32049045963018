import { getResponseError, handleUseFormError } from '../../../utils/utils';
import { handleRequest, handleSuccess, handleFailure } from '../../services/handleData/handleData.ts';
import { ADD_BATCH_FAILED, ADD_BATCH_REQUEST, ADD_BATCH_SUCCESS, GET_BATCH_LIST_FAILED, GET_BATCH_LIST_REQUEST, GET_BATCH_LIST_SUCCESS, UPDATE_BATCH_REQUEST, UPDATE_BATCH_SUCCESS, UPDATE_BATCH_FAILED } from './batch.constants';
import { addBatchService, getBatchListService, updateBatchService } from './batch.services';

// get Batch list
export const getBatchListAction = () => async (dispatch) => {
  dispatch(handleRequest(GET_BATCH_LIST_REQUEST));
  try {
    const res = await getBatchListService();
    if (res?.statusCode === 200) {
      dispatch(handleSuccess(GET_BATCH_LIST_SUCCESS, res?.data));
    } else {
      let error = getResponseError(res);
      dispatch(handleFailure(GET_BATCH_LIST_FAILED, error));
    }
    return res;
  } catch (ex) {
    console.log('API FAILED', ex);
    dispatch(handleFailure(GET_BATCH_LIST_FAILED, ex?.message));
    return ex;
  }
};



// add Batch
export const addBatchAction = (data) => async (dispatch) => {
  dispatch(handleRequest(ADD_BATCH_REQUEST));
  try {
    const res = await addBatchService(data);
    if (res?.statusCode === 201) {
      dispatch(handleSuccess(ADD_BATCH_SUCCESS, res));
    } else {
      let error = getResponseError(res);
      dispatch(handleFailure(ADD_BATCH_FAILED, error));
    }
    return res;
  } catch (ex) {
    console.log('API FAILED', ex);
    dispatch(handleFailure(ADD_BATCH_FAILED, ex?.message));
    return ex;
  }
};

// update Batch
export const updateBatchAction = (batchId, data) => async (dispatch) => {
  dispatch(handleRequest(UPDATE_BATCH_REQUEST));
  try {
    const res = await updateBatchService(batchId, data);
    if (res?.statusCode === 201) {
      dispatch(handleSuccess(UPDATE_BATCH_SUCCESS, res));
    } else {
      let error = getResponseError(res);
      dispatch(handleFailure(UPDATE_BATCH_FAILED, error));
    }
    return res;
  } catch (ex) {
    console.log('API FAILED', ex);
    dispatch(handleFailure(UPDATE_BATCH_FAILED, ex?.message));
    return ex;
  }
};


