import React, { useEffect } from 'react';
import Logo from '../../../assets/logo.svg';
import Pricing from '../../../component/pricing/pricing';
import { useDispatch, useSelector } from 'react-redux';
import { getPricingListAction } from '../../../redux/states/auth/auth.actions';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../component/loader/loader';
import ErrorState from '../../../component/molecule/error-state/errorState';

export default function PricingList() {
  const { data, error, loading } = useSelector(state => state?.auth?.pricingListData)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getPricingListAction());
  }, []);

  const handleOnPricingSelect = (selectedId, price) => {
    navigate('/referral', { state: { selectedId, price } });
  }
  return (
    <div className="p-4 bg-light">
      <section>
        <img alt="logo" src={Logo} />
      </section>
      <section className="container text-center">
        <br /> <br />
        <h2 className="header-2 pt-0 pt-md-0">Pricing Plans</h2>
        <p className="subHeading">Choose the best plan to fit your needs.</p>
        <br /> <br />
        <div className="row mt-0 mt-md-5">
          {loading ? <Loader /> : error ? <ErrorState /> : data?.map((item) => (
            <Pricing key={item?.id} plan={item} handleOnSelect={() => handleOnPricingSelect(item?.id, item?.offerPrice)} />
          ))}
        </div>
        <br /> <br />
      </section>
    </div>
  );
}
