import { toast } from 'react-toastify';
import moment from 'moment';

export const handleUseFormError = (res) => {
  return res?.errors ? Object.keys(res?.errors)?.map((key) => toast.error(res?.errors[key][0])) : toast.error('Something went wrong');
};

export const getResponseError = (res) => (res?.errors && Object.keys(res?.errors).length ? Object.values(res?.errors)[0][0] : 'Something went wrong');

export const formatDate = (date, format = 'Do MMM, YYYY') => date && format && moment(date).format(format);

export const convertPascal = (str) => {
  return typeof str === 'string'
    ? str
        ?.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
          return index === 0 ? word?.toLowerCase() : word?.toUpperCase();
        })
        ?.replace(/\s+/g, '')
    : '';
};

export const getCurrentDate = () => {
  return moment().format("YYYY-MM-DD");
};

export function debounce(func, timeout = 2000) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}
