import React, { useState } from 'react';
import { MdClose } from 'react-icons/md';
import { Button } from '../../../../component/button/button';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import InputField from '../../../../atoms/inputField/inputField';
import { useSelector } from 'react-redux';
import { getPackagesListAction } from '../../../../redux/states/plan/plan.actions';
import MultiSelectField from '../../../../atoms/multiSelectField/multiSelectField';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  calculatePaymentAction,
  getMemberAction,
  updateMemberPackageAction
} from '../../../../redux/states/member/member.actions';
import { toast } from 'react-toastify';
import { getCurrentDate, handleUseFormError } from '../../../../utils/utils';
import RadioButton from '../../../../atoms/RadioButton/RadioButton';

const schema = yup
  .object({
    package: yup.object().nullable().required(),
    startDate: yup.string().required(),
    discountPackage: yup.string(),
    AmountPaid: yup.string(),
    packageChange: yup.string(),
    dueAmount: yup.string()
  })
  .required();

export default function UpdatePackageModal({ onClose, active, memberId, expiresAt, packageData, dueAmount, memberPackageHistoriesId }) {
  const dispatch = useDispatch();
  const { data: packagesListData } = useSelector((state) => state?.plan?.packagesList);
  const { data: paymentCostData } = useSelector((state) => state?.member?.paymentCost);

  const [selectedToday, setSelectedToday] = useState(false);
  const [showPackageOption, setShowPackageOption] = useState(false);

  useEffect(() => {
    setShowPackageOption(false)
    dispatch(getPackagesListAction());
  }, []);

  function handleModalClose() {
    onClose();
    reset();
    fillInitialForm()
    setShowPackageOption(false);
  }

  const {
    register,
    formState: { errors, values },
    handleSubmit,
    control,
    reset,
    setValue,
    watch
  } = useForm({
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    fillInitialForm()
  }, [expiresAt, packageData, dueAmount]);

  const fillInitialForm = () => {
    setValue('startDate', expiresAt);
    setValue('dueAmount', dueAmount);
    setValue('package', { label: packageData?.title, value: packageData?.id });
    setValue('packageChange', 'endCurrentPackage');
  }

  useEffect(() => {
    setValue('AmountPaid', paymentCostData?.total);
  }, [paymentCostData, setValue]);

  const handlePackageChange = (obj) => {
    setValue('package', obj);
    setShowPackageOption(true);
  };

  const discountPackage = watch('discountPackage');
  const packageVal = watch('package')?.value;
  const packageChange = watch('packageChange');

  useEffect(() => {
    const body = {
      packageId: packageVal,
      discountFee: discountPackage || 0,
    };
    (discountPackage || packageVal) && dispatch(calculatePaymentAction(body));
  }, [discountPackage, packageVal]);

  useEffect(() => {
    setSelectedToday(packageChange === 'today');
    setValue('startDate', packageChange === 'today' ? getCurrentDate() : expiresAt);
  }, [packageChange]);

  const onSubmit = async (data) => {
    console.log('data', data);
    const body = {
      packageId: data?.package?.value,
      discountPackage: data?.discountPackage || 0,
      startDate: data?.startDate,
      memberPackageHistoriesId,
      action: selectedToday ? 'NOW' : 'LATER',
      dueAmount: data?.dueAmount
    };
    selectedToday && delete body.startDate
    console.log('body', body);
    const res = await dispatch(updateMemberPackageAction(memberId, body));
    if (res?.statusCode === 201) {
      toast.success(res?.message);
      handleModalClose()
      dispatch(getMemberAction(memberId));
    } else {
      handleUseFormError(res);
    }
  };

  return (
    <div className="modal" id="staticBackdrop" style={{ display: active ? 'block' : 'none' }}>
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <MdClose size={30} onClick={() => handleModalClose(false)} className="modal__icon" />
          <div className="modal-header">
            <h5 className="modal-title header-4" id="staticBackdropLabel">
              {'Update Package'}
            </h5>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Controller
                name="package"
                control={control}
                render={({ field: { onChange, value }, fieldState }) => (
                  <MultiSelectField
                    label={'package'}
                    onChange={handlePackageChange}
                    value={value}
                    placeholder={'Select package'}
                    options={packagesListData?.map((pack) => ({ value: pack?.id, label: pack?.title }))}
                    noOptionsMessage={'Please create package before adding member'}
                    error={fieldState?.error?.message}
                  />
                )}
              />

              {showPackageOption && (
                <div className="mt-4">
                  <RadioButton
                    register={register}
                    label={'End of current package'}
                    name="packageChange"
                    value={'endCurrentPackage'}
                    // handleOnChange={() => {}}
                  />
                  <RadioButton
                    register={register}
                    label={'Today'}
                    value={'today'}
                    name="packageChange"
                  />
                </div>
              )}

              <InputField
                placeholder="Start Date"
                label="Start Date"
                type="date"
                isDisabled={true}
                name={'startDate'}
                register={register}
                error={errors.startDate?.message}
              />
              <InputField
                placeholder="Discount % in package"
                label="Discount % in package"
                type="number"
                name={'discountPackage'}
                register={register}
                error={errors.discountPackage?.message}
              />
              <InputField
                placeholder={`Amount to be paid`}
                label={`Amount to be paid ${selectedToday ? '' : `on ${expiresAt}`}`}
                type="number"
                isDisabled={true}
                name={'AmountPaid'}
                register={register}
                error={errors.text?.message}
              />
              {selectedToday ? (
                <InputField
                  placeholder={`Due Amount`}
                  label={`Due Amount`}
                  type="number"
                  name={'dueAmount'}
                  register={register}
                  error={errors.text?.message}
                />
              ) : null}
              <Button label={'Update'} type="submit" class="btn-primary w-100 mt-4" />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
