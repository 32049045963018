import { convertPascal, getResponseError } from '../../../utils/utils';
import { handleRequest, handleSuccess, handleFailure } from '../../services/handleData/handleData.ts';
import {
  GET_SMS_TEMPLATES_REQUEST,
  GET_SMS_TEMPLATES_SUCCESS,
  GET_SMS_TEMPLATES_FAILED,
  SEND_SMS_REQUEST,
  SEND_SMS_SUCCESS,
  SEND_SMS_FAILED,
  GET_SMS_PRICING_REQUEST,
  GET_SMS_PRICING_SUCCESS,
  GET_SMS_PRICING_FAILED,
  GET_SMS_COUNT_REQUEST,
  GET_SMS_COUNT_SUCCESS,
  GET_SMS_COUNT_FAILED
} from './sms.constants';
import {
  getAvailableSmsCountService,
  getSmsPricingService,
  getSmsTemplatesService,
  razorpaySmsCreateService,
  razorpaySmsFailureService,
  razorpaySmsSuccessService,
  sendBulkSmsService
} from './sms.services';

// get sms templates
export const getSmsTemplatesAction = () => async (dispatch) => {
  dispatch(handleRequest(GET_SMS_TEMPLATES_REQUEST));
  try {
    const res = await getSmsTemplatesService();
    if (res?.statusCode === 200) {
      dispatch(handleSuccess(GET_SMS_TEMPLATES_SUCCESS, res?.data));
    } else {
      let error = getResponseError(res);
      dispatch(handleFailure(GET_SMS_TEMPLATES_FAILED, error));
    }
    return res;
  } catch (ex) {
    console.log('API FAILED', ex);
    dispatch(handleFailure(GET_SMS_TEMPLATES_FAILED, ex?.message));
    return ex;
  }
};

// send bulk sms
export const sendBulkSmsAction = (data, filters) => async (dispatch) => {
  let urlParams = '';
  if (filters?.length) {
    filters?.map((item, index) =>
      index === 0
        ? (urlParams += `?${convertPascal(item?.type?.toLowerCase())}[]=${item?.id}`)
        : (urlParams += `&${convertPascal(item?.type?.toLowerCase())}[]=${item?.id}`)
    );
  }
  dispatch(handleRequest(SEND_SMS_REQUEST));
  console.log('urlParams', urlParams);
  try {
    const res = await sendBulkSmsService(data, urlParams);
    if (res?.statusCode === 200) {
      dispatch(handleSuccess(SEND_SMS_SUCCESS, res));
    } else {
      let error = getResponseError(res);
      dispatch(handleFailure(SEND_SMS_FAILED, error));
    }
    return res;
  } catch (ex) {
    console.log('API FAILED', ex);
    dispatch(handleFailure(SEND_SMS_FAILED, ex?.message));
    return ex;
  }
};

// get sms pricing
export const getSmsPricingAction = () => async (dispatch) => {
  dispatch(handleRequest(GET_SMS_PRICING_REQUEST));
  try {
    const res = await getSmsPricingService();
    if (res?.statusCode === 200) {
      dispatch(handleSuccess(GET_SMS_PRICING_SUCCESS, res?.data));
    } else {
      let error = getResponseError(res);
      dispatch(handleFailure(GET_SMS_PRICING_FAILED, error));
    }
    return res;
  } catch (ex) {
    console.log('API FAILED', ex);
    dispatch(handleFailure(GET_SMS_PRICING_FAILED, ex?.message));
    return ex;
  }
};

// get available sms count
export const getAvailableSmsCountAction = () => async (dispatch) => {
  dispatch(handleRequest(GET_SMS_COUNT_REQUEST));
  try {
    const res = await getAvailableSmsCountService();
    if (res?.statusCode === 200) {
      dispatch(handleSuccess(GET_SMS_COUNT_SUCCESS, res?.data));
    } else {
      let error = getResponseError(res);
      dispatch(handleFailure(GET_SMS_COUNT_FAILED, error));
    }
    return res;
  } catch (ex) {
    console.log('API FAILED', ex);
    dispatch(handleFailure(GET_SMS_COUNT_FAILED, ex?.message));
    return ex;
  }
};

export const razorpaySmsCreateAction = (orderInfo) => async (dispatch) => {
  try {
    const res = await razorpaySmsCreateService(orderInfo);
    return res;
  } catch (ex) {
    console.log('API FAILED', ex?.message);
    return ex;
  }
};

export const razorpaySmsSuccessAction = (referenceId, razorpayId) => async () => {
  try {
    const res = await razorpaySmsSuccessService(referenceId, razorpayId);
    return res;
  } catch (ex) {
    console.log('API FAILED', ex?.message);
    return ex;
  }
};

export const razorpaySmsFailureAction = (referenceId) => async () => {
  try {
    const res = await razorpaySmsFailureService(referenceId);
    return res;
  } catch (ex) {
    console.log('API FAILED', ex?.message);
    return ex;
  }
};
