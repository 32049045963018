import { handleSuccess,handleRequest,handleFailure } from '../../services/handleData/handleData.ts';
import {
  LOAD_ALERT_DATA,
  REGISTER_USER_REQUEST,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAILED,
  VALIDATE_OTP_REQUEST,
  VALIDATE_OTP_SUCCESS,
  VALIDATE_OTP_FAILED,
  LOGIN_USER_REQUEST,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILED,
  SAVE_ADDRESS_SUCCESS,
  GYM_ADDRESS_COUNTRIES_FAILED,
  GYM_ADDRESS_COUNTRIES_REQUEST,
  GYM_ADDRESS_COUNTRIES_SUCCESS,
  GYM_ADDRESS_STATES_FAILED,
  GYM_ADDRESS_STATES_REQUEST,
  GYM_ADDRESS_STATES_SUCCESS,
  GYM_ADDRESS_CITIES_REQUEST,
  GYM_ADDRESS_CITIES_SUCCESS, 
  GYM_ADDRESS_CITIES_FAILED,
  SAVE_ADDRESS_REQUEST,
  SAVE_ADDRESS_FAILED,
  GYM_LOGO_REQUEST,
  GYM_LOGO_SUCCESS,
  GYM_LOGO_FAILED,
  PRICING_LIST_REQUEST,
  PRICING_LIST_SUCCESS,
  PRICING_LIST_FAILED,
  VALIDATE_REFERRAL_REQUEST,
  VALIDATE_REFERRAL_SUCCESS,
  VALIDATE_REFERRAL_FAILED,
  CREATE_ORDER_REQUEST,
  CREATE_ORDER_SUCCESS,
  CREATE_ORDER_FAILED
} from './auth.constants';
import { postLoginRequestService, postUserRegisterService,postValidateUserService,getCountrieService,getStatesService,getCitiesService,saveGymAddressService, saveGymLogoService, getPricingListService, validateReferralService, createOrderService, razorpaySuccessService, razorpayFailureService } from './auth.services';

export const loadAlert = (data) => async (dispatch) => {
  dispatch(handleSuccess(LOAD_ALERT_DATA, data));
  setTimeout(() => {
    dispatch(handleSuccess(LOAD_ALERT_DATA, ''));
  }, 5000);
};

export const validateOtpAction = (validateUserInfo) => async (dispatch) => {
  dispatch(handleRequest(VALIDATE_OTP_REQUEST));
  try {
    const res = await postValidateUserService(validateUserInfo);
    if (res?.statusCode === 200) {
      dispatch(handleSuccess(VALIDATE_OTP_SUCCESS, res?.message));
    }else{
      dispatch(handleFailure(VALIDATE_OTP_FAILED, res?.message));
    }
    return res;
  } catch (ex) {
    console.log('API FAILED', ex?.message);
    dispatch(handleFailure(VALIDATE_OTP_FAILED, ex?.message));
    return ex
  }
};

export const postRegisterAction = (registerInfo) => async (dispatch) => {
  dispatch(handleRequest(REGISTER_USER_REQUEST));
  try {
    const res = await postUserRegisterService(registerInfo);
    if (res?.statusCode === 200) {
      dispatch(handleSuccess(REGISTER_USER_SUCCESS, res?.message))
    } else {
      dispatch(handleFailure(REGISTER_USER_FAILED, res?.message))
    }
    return res;
  } catch (ex) {
    console.log('API FAILED', ex?.message);
    dispatch(handleFailure(REGISTER_USER_FAILED, ex?.message))
  }

};

export const postLoginAction = (mobile) => async (dispatch) => {
  dispatch(handleRequest(LOGIN_USER_REQUEST));
  try {
    const res = await postLoginRequestService(mobile);
    if (res?.statusCode === 200) {
      dispatch(handleSuccess(LOGIN_USER_SUCCESS, res?.message));
    } else {
      dispatch(handleFailure(LOGIN_USER_FAILED, res?.message));
    }
    return res;
  } catch (ex) {
    console.log('API FAILED', ex?.message);
    dispatch(handleFailure(LOGIN_USER_FAILED, ex?.message));
    return ex;
  }
};


export const getCountriesAction = () => async (dispatch) => {
  dispatch(handleRequest(GYM_ADDRESS_COUNTRIES_REQUEST));
  try {
    const res = await getCountrieService();
    if (res?.statusCode === 200) {
      dispatch(handleSuccess(GYM_ADDRESS_COUNTRIES_SUCCESS, res?.data))
    } else {
      dispatch(handleFailure(GYM_ADDRESS_COUNTRIES_FAILED, res?.errors))
    }
    return res;
  } catch (ex) {
    console.log('API FAILED', ex?.message);
    dispatch(handleFailure(GYM_ADDRESS_COUNTRIES_FAILED, ex?.message))
  }
};
export const getStatesAction = (countryId) => async (dispatch) => {
  dispatch(handleRequest(GYM_ADDRESS_STATES_REQUEST));
  try {
    const res = await getStatesService(countryId);
    if (res?.statusCode === 200) {
      dispatch(handleSuccess(GYM_ADDRESS_STATES_SUCCESS, res?.data))
    } else {
      dispatch(handleFailure(GYM_ADDRESS_STATES_FAILED, res?.errors))
    }
    return res;
  } catch (ex) {
    console.log('API FAILED', ex?.message);
    dispatch(handleFailure(GYM_ADDRESS_STATES_FAILED, ex?.message))
  }
};

export const getCitiesAction = (stateId) => async (dispatch) => {
  dispatch(handleRequest(GYM_ADDRESS_CITIES_REQUEST));
  try {
    const res = await getCitiesService(stateId);
    if (res?.statusCode === 200) {
      dispatch(handleSuccess(GYM_ADDRESS_CITIES_SUCCESS, res?.data))
    } else {
      dispatch(handleFailure(GYM_ADDRESS_CITIES_FAILED, res?.errors))
    }
    return res;
  } catch (ex) {
    console.log('API FAILED', ex?.message);
    dispatch(handleFailure(GYM_ADDRESS_CITIES_FAILED, ex?.message))
  }
};


export const saveGymAddressAction = (gymInfo) => async (dispatch) => {
  dispatch(handleRequest(SAVE_ADDRESS_REQUEST));
  try {
    const res = await saveGymAddressService(gymInfo);
    if (res?.statusCode === 200) {
      dispatch(handleSuccess(SAVE_ADDRESS_SUCCESS, res?.message))
    } else {
      dispatch(handleFailure(SAVE_ADDRESS_FAILED, res?.message))
    }
    return res;
  } catch (ex) {
    console.log('API FAILED', ex?.message);
    dispatch(handleFailure(SAVE_ADDRESS_FAILED, ex?.message))
  }
};

export const  saveGymLogoAction = (gymLogoInfo) => async (dispatch) => {
  dispatch(handleRequest(GYM_LOGO_REQUEST));
  try {
    const res = await saveGymLogoService(gymLogoInfo);
    if (res?.statusCode === 200) {
      dispatch(handleSuccess(GYM_LOGO_SUCCESS, res?.message))
    } else {
      dispatch(handleFailure(GYM_LOGO_FAILED, res?.message))
    }
    return res;
  } catch (ex) {
    console.log('API FAILED', ex?.message);
    dispatch(handleFailure(GYM_LOGO_FAILED, ex?.message))
  }
};


export const getPricingListAction = () => async (dispatch) => {
  dispatch(handleRequest(PRICING_LIST_REQUEST));
  try {
    const res = await getPricingListService();
    if (res?.statusCode === 200) {
      dispatch(handleSuccess(PRICING_LIST_SUCCESS, res?.data))
    } else {
      dispatch(handleFailure(PRICING_LIST_FAILED, res?.errors))
    }
    return res;
  } catch (ex) {
    console.log('API FAILED', ex?.message);
    dispatch(handleFailure(PRICING_LIST_FAILED, ex?.message))
  }
};

export const validateReferralAction = (referralInfo) => async (dispatch) => {
  dispatch(handleRequest(VALIDATE_REFERRAL_REQUEST));
  try {
    const res = await validateReferralService(referralInfo);
    if (res?.statusCode === 200) {
      dispatch(handleSuccess(VALIDATE_REFERRAL_SUCCESS, res?.data))
    } else {
      dispatch(handleFailure(VALIDATE_REFERRAL_FAILED, res?.message))
    }
    return res;
  } catch (ex) {
    console.log('API FAILED', ex?.message);
    dispatch(handleFailure(VALIDATE_REFERRAL_FAILED, ex?.message))
  }
};

export const createOrderAction = (orderInfo) => async (dispatch) => {
  dispatch(handleRequest(CREATE_ORDER_REQUEST));
  try {
    const res = await createOrderService(orderInfo);
    if (res?.statusCode === 200) {
      dispatch(handleSuccess(CREATE_ORDER_SUCCESS, res?.data))
    } else {
      dispatch(handleFailure(CREATE_ORDER_FAILED, res?.message))
    }
    return res;
  } catch (ex) {
    console.log('API FAILED', ex?.message);
    dispatch(handleFailure(CREATE_ORDER_FAILED, ex?.message))
  }
};

export const razorpaySuccessAction = (referenceId, razorpayId) => async () => {
  try {
    const res = await razorpaySuccessService(referenceId, razorpayId);
    console.log('res', res);
    return res;
  } catch (ex) {
    console.log('API FAILED', ex?.message);
    return ex;
  }
};

export const razorpayFailureAction = (referenceId) => async () => {
  try {
    const res = await razorpayFailureService(referenceId);
    return res;
  } catch (ex) {
    console.log('API FAILED', ex?.message);
    return ex;
  }
};
