import { convertPascal } from '../../../utils/utils';
import { handleRequest, handleSuccess, handleFailure } from '../../services/handleData/handleData.ts';
import {
  GET_MEMBER_LIST_FAILED,
  GET_MEMBER_LIST_REQUEST,
  GET_MEMBER_LIST_SUCCESS,
  GET_MEMBERSHIP_DURATION_FAILED,
  GET_MEMBERSHIP_DURATION_REQUEST,
  GET_MEMBERSHIP_DURATION_SUCCESS,
  ADD_MEMBER_REQUEST,
  ADD_MEMBER_SUCCESS,
  ADD_MEMBER_FAILED,
  GET_MEMBER_FILTER_REQUEST,
  GET_MEMBER_FILTER_SUCCESS,
  GET_MEMBER_FILTER_FAILED,
  CALCULATE_PAYMENT_REQUEST,
  CALCULATE_PAYMENT_SUCCESS,
  CALCULATE_PAYMENT_FAILED,
  GET_MEMBER_REQUEST,
  GET_MEMBER_SUCCESS,
  GET_MEMBER_FAILED,
  UPDATE_MEMBER_REQUEST,
  UPDATE_MEMBER_SUCCESS,
  UPDATE_MEMBER_FAILED,
  GET_GYM_PAYMENT_REQUEST,
  GET_GYM_PAYMENT_SUCCESS,
  GET_GYM_PAYMENT_FAILED,
  UPDATE_MEMBER_BATCH_REQUEST,
  UPDATE_MEMBER_BATCH_SUCCESS,
  UPDATE_MEMBER_BATCH_FAILED,
  UPDATE_MEMBER_PACKAGE_REQUEST,
  UPDATE_MEMBER_PACKAGE_SUCCESS,
  UPDATE_MEMBER_PACKAGE_FAILED,
  UPDATE_MEMBER_TRAINER_REQUEST,
  UPDATE_MEMBER_TRAINER_SUCCESS,
  UPDATE_MEMBER_TRAINER_FAILED,
  BULK_ADD_MEMBER_REQUEST,
  BULK_ADD_MEMBER_SUCCESS,
  BULK_ADD_MEMBER_FAILED
} from './member.constants';
import {
  addMemberService,
  getMemberFiltersService,
  getMemberListService,
  membershipDurationsService,
  calculatePaymentService,
  getMemberService,
  updateMemberService,
  updateStatusMemberService,
  getGymPaymentService,
  postGymPaymentService,
  updateMemberBatchService,
  updateMemberPackageService,
  updateMemberTrainerService,
  bulkAddMemberService
} from './member.services';

// get membership duration
export const getMembershipDurationsAction = () => async (dispatch) => {
  dispatch(handleRequest(GET_MEMBERSHIP_DURATION_REQUEST));
  try {
    const res = await membershipDurationsService();
    if (res?.statusCode === 200) {
      dispatch(handleSuccess(GET_MEMBERSHIP_DURATION_SUCCESS, res?.data));
    } else {
      dispatch(handleFailure(GET_MEMBERSHIP_DURATION_FAILED, res));
    }
    return res;
  } catch (ex) {
    console.log('API FAILED', ex);
    dispatch(handleFailure(GET_MEMBERSHIP_DURATION_FAILED, ex));
    return ex;
  }
};

// get member filter
export const getMemberFiltersAction = () => async (dispatch) => {
  dispatch(handleRequest(GET_MEMBER_FILTER_REQUEST, true));
  try {
    const res = await getMemberFiltersService();
    if (res?.statusCode === 200) {
      dispatch(handleSuccess(GET_MEMBER_FILTER_SUCCESS, res?.data));
    } else {
      let error = (res || {}).error;
      dispatch(handleFailure(GET_MEMBER_FILTER_FAILED, error));
    }
    return res;
  } catch (ex) {
    console.log('API FAILED', ex);
    dispatch(handleFailure(GET_MEMBER_FILTER_FAILED, ex));
    return ex;
  }
};

// get member lists
export const getMemberListAction = (filters, searchVal, page) => async (dispatch) => {
  dispatch(handleRequest(GET_MEMBER_LIST_REQUEST, true));
  let urlParams = '';
  if (filters?.length) {
    filters?.map((item, index) =>
      index === 0
        ? (urlParams += `?${convertPascal(item?.type?.toLowerCase())}[]=${item?.id}`)
        : (urlParams += `&${convertPascal(item?.type?.toLowerCase())}[]=${item?.id}`)
    );
  }
  if (searchVal) {
    if (!urlParams) {
      urlParams = `?q=${searchVal}`;
    } else {
      urlParams = `&q=${searchVal}`;
    }
  }

  let pageParams = '';
  if (page && urlParams) {
    pageParams = `&page=${page}`;
  } else if (page) {
    pageParams = `?page=${page}`;
  }

  try {
    const res = await getMemberListService(urlParams + pageParams);
    if (res?.statusCode === 200) {
      dispatch(handleSuccess(GET_MEMBER_LIST_SUCCESS, res?.data));
    } else {
      let error = (res || {}).error;
      dispatch(handleFailure(GET_MEMBER_LIST_FAILED, error));
    }
    return res;
  } catch (ex) {
    console.log('API FAILED', ex);
    dispatch(handleFailure(GET_MEMBER_LIST_FAILED, ex));
    return ex;
  }
};

// get member
export const getMemberAction = (id) => async (dispatch) => {
  dispatch(handleRequest(GET_MEMBER_REQUEST, true));
  try {
    const res = await getMemberService(id);
    if (res?.statusCode === 200) {
      dispatch(handleSuccess(GET_MEMBER_SUCCESS, res?.data));
    } else {
      let error = (res || {}).error;
      dispatch(handleFailure(GET_MEMBER_FAILED, error));
    }
    return res;
  } catch (ex) {
    console.log('API FAILED', ex);
    dispatch(handleFailure(GET_MEMBER_FAILED, ex));
    return ex;
  }
};

// add member
export const addMemberAction = (data) => async (dispatch) => {
  dispatch(handleRequest(ADD_MEMBER_REQUEST, true));
  try {
    const res = await addMemberService(data);
    if (res?.statusCode === 201) {
      dispatch(handleSuccess(ADD_MEMBER_SUCCESS, res?.data));
    } else {
      let error = (res || {}).error;
      dispatch(handleFailure(ADD_MEMBER_FAILED, error));
    }
    return res;
  } catch (ex) {
    console.log('API FAILED', ex);
    dispatch(handleFailure(ADD_MEMBER_FAILED, ex));
    return ex;
  }
};

// update member
export const updateMemberAction = (id, data) => async (dispatch) => {
  dispatch(handleRequest(UPDATE_MEMBER_REQUEST, true));
  try {
    const res = await updateMemberService(id, data);
    if (res?.statusCode === 200) {
      dispatch(handleSuccess(UPDATE_MEMBER_SUCCESS, res?.data));
    } else {
      let error = (res || {}).error;
      dispatch(handleFailure(UPDATE_MEMBER_FAILED, error));
    }
    return res;
  } catch (ex) {
    console.log('API FAILED', ex);
    dispatch(handleFailure(UPDATE_MEMBER_FAILED, ex));
    return ex;
  }
};

// update member state
export const updateMemberStatusAction = (id, status) => async (dispatch) => {
  dispatch(handleRequest(UPDATE_MEMBER_REQUEST, true));
  try {
    const res = await updateStatusMemberService(id, status);
    if (res?.statusCode === 200) {
      dispatch(handleSuccess(UPDATE_MEMBER_SUCCESS, res?.data));
    } else {
      let error = (res || {}).error;
      dispatch(handleFailure(UPDATE_MEMBER_FAILED, error));
    }
    return res;
  } catch (ex) {
    console.log('API FAILED', ex);
    dispatch(handleFailure(UPDATE_MEMBER_FAILED, ex));
    return ex;
  }
};

// calculate payment
export const calculatePaymentAction = (data) => async (dispatch) => {
  dispatch(handleRequest(CALCULATE_PAYMENT_REQUEST, true));
  try {
    const res = await calculatePaymentService(data);
    if (res?.statusCode === 200) {
      dispatch(handleSuccess(CALCULATE_PAYMENT_SUCCESS, res?.data));
    } else {
      let error = (res || {}).error;
      dispatch(handleFailure(CALCULATE_PAYMENT_FAILED, error));
    }
    return res;
  } catch (ex) {
    console.log('API FAILED', ex);
    dispatch(handleFailure(CALCULATE_PAYMENT_FAILED, ex));
    return ex;
  }
};

// get gym payment
export const getGymPaymentAction = (memberId) => async (dispatch) => {
  dispatch(handleRequest(GET_GYM_PAYMENT_REQUEST, true));
  try {
    const res = await getGymPaymentService(memberId);
    if (res?.statusCode === 200) {
      dispatch(handleSuccess(GET_GYM_PAYMENT_SUCCESS, res?.data?.data));
    } else {
      let error = (res || {}).error;
      dispatch(handleFailure(GET_GYM_PAYMENT_FAILED, error));
    }
    return res;
  } catch (ex) {
    console.log('API FAILED', ex);
    dispatch(handleFailure(GET_GYM_PAYMENT_FAILED, ex));
    return ex;
  }
};

// post gym payment
export const postGymPaymentAction = (memberId) => async (dispatch) => {
  try {
    const res = await postGymPaymentService(memberId);
    return res;
  } catch (ex) {
    console.log('API FAILED', ex);
    return ex;
  }
};

// update member state
export const updateMemberBatchAction = (id, data) => async (dispatch) => {
  dispatch(handleRequest(UPDATE_MEMBER_BATCH_REQUEST, true));
  try {
    const res = await updateMemberBatchService(id, data);
    if (res?.statusCode === 200) {
      dispatch(handleSuccess(UPDATE_MEMBER_BATCH_SUCCESS, res?.data));
    } else {
      let error = (res || {}).error;
      dispatch(handleFailure(UPDATE_MEMBER_BATCH_FAILED, error));
    }
    return res;
  } catch (ex) {
    console.log('API FAILED', ex);
    dispatch(handleFailure(UPDATE_MEMBER_BATCH_FAILED, ex));
    return ex;
  }
};

// update member package
export const updateMemberPackageAction = (id, data) => async (dispatch) => {
  dispatch(handleRequest(UPDATE_MEMBER_PACKAGE_REQUEST, true));
  try {
    const res = await updateMemberPackageService(id, data);
    if (res?.statusCode === 200) {
      dispatch(handleSuccess(UPDATE_MEMBER_PACKAGE_SUCCESS, res?.data));
    } else {
      let error = (res || {}).error;
      dispatch(handleFailure(UPDATE_MEMBER_PACKAGE_FAILED, error));
    }
    return res;
  } catch (ex) {
    console.log('API FAILED', ex);
    dispatch(handleFailure(UPDATE_MEMBER_PACKAGE_FAILED, ex));
    return ex;
  }
};

// update member trainer
export const updateMemberTrainerAction = (id, data) => async (dispatch) => {
  dispatch(handleRequest(UPDATE_MEMBER_TRAINER_REQUEST, true));
  try {
    const res = await updateMemberTrainerService(id, data);
    if (res?.statusCode === 200) {
      dispatch(handleSuccess(UPDATE_MEMBER_TRAINER_SUCCESS, res?.data));
    } else {
      let error = (res || {}).error;
      dispatch(handleFailure(UPDATE_MEMBER_TRAINER_FAILED, error));
    }
    return res;
  } catch (ex) {
    console.log('API FAILED', ex);
    dispatch(handleFailure(UPDATE_MEMBER_TRAINER_FAILED, ex));
    return ex;
  }
};

// bulk add member
export const bulkAddMemberAction = (data) => async (dispatch) => {
  dispatch(handleRequest(BULK_ADD_MEMBER_REQUEST, true));
  try {
    const res = await bulkAddMemberService(data);
    if (res?.statusCode === 201) {
      dispatch(handleSuccess(BULK_ADD_MEMBER_SUCCESS, res?.data));
    } else {
      let error = (res || {}).error;
      dispatch(handleFailure(BULK_ADD_MEMBER_FAILED, error));
    }
    return res;
  } catch (ex) {
    console.log('API FAILED', ex);
    dispatch(handleFailure(BULK_ADD_MEMBER_FAILED, ex));
    return ex;
  }
};