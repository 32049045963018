import React, { useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import randomColor from "randomcolor";

ChartJS.register(ArcElement, Tooltip, Legend);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "right",
    },
  },
};

const PlanDistributionGraph = ({ distributionData }) => {

  // const [switchToPlan, setSwitchToPlan] = useState(false);
  // const [pieData, setPieData] = useState(null);


  const constructPieData = (data) =>{
    const labels = data?.labels;
    const values = data?.data;
    const pieData = { labels:labels, datasets:[{data:values, backgroundColor:data?.data.map((_x) => randomColor({luminosity: 'light' })),
    borderWidth: 0,}]}
    return pieData;
  }

  // const handleChange = () =>{
  //   setSwitchToPlan(!switchToPlan);
  // }

  // useEffect(() =>{
  //   if(switchToPlan){
  //     const plansData = distributionData?.plans;
  //     setPieData(constructPieData(plansData));
  //   }else{
  //     const packagesData = distributionData?.packages;
  //     setPieData(constructPieData(packagesData));
  //   }
  // },[switchToPlan])

  return (
    <div className="card" style={{ position: "relative", minHeight: "400px" }}>
      {/* <SwitchField labelLeft={'Package'} labelRight={'Plan'} checked={switchToPlan} handleChange={handleChange}/> */}
      <h3 className="header-3 mb-3">Package</h3>

        <div style={{ position: "relative", height: "300px" }}>
          {distributionData?.packages && <Pie data={constructPieData(distributionData?.packages)} options={options} />}
        </div>
    </div>
  );
}

export default PlanDistributionGraph
