import React from 'react';
import InputField from '../../../../atoms/inputField/inputField';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Button, OutlineButton } from '../../../../component/button/button';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addPlanAction, getPlanByIdAction, updatePlanAction } from '../../../../redux/states/plan/plan.actions';
import { toast } from 'react-toastify';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { handleUseFormError } from '../../../../utils/utils';

const schema = yup
  .object({
    planName: yup.string().required()
  })
  .required();

export default function AddPlan() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();

  const { data: planData, error: planError, loading: plainLoading } = useSelector((state) => state.plan?.getPlan);

  const {loading: addPlanLoading} = useSelector(state => state?.plan?.addPlan);

  const {loading: updatePlanLoading} = useSelector(state => state?.plan?.updatePlan);


  const [planBenefits, setPlanBenefits] = useState([{ title: '', tempId: 864347 }]);

  const updateId = state?.planId || false;

  useEffect(() => {
    if (updateId) {
      dispatch(getPlanByIdAction(updateId));
    }
  }, [updateId]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset
  } = useForm({
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    if (updateId) {
      if (planData?.name) {
        setValue('planName', planData?.name);
      }
      const tempBenefits = planData?.benefits?.map((item) => {
        return {
          ...item,
          tempId: Math.floor(Math.random() * 999999) + 1
        };
      });
      setPlanBenefits(tempBenefits || []);
    }
  }, [planData, updateId, setValue]);

  const onSubmit = async (data) => {
    const updatedPlanBenefit = planBenefits?.map((item) => {
      if (item?.tempId) delete item?.tempId;
      return item;
    });
    if (updateId) {
      const res = await dispatch(updatePlanAction(updateId, { name: data?.planName, benefits: updatedPlanBenefit }));
      if (res?.statusCode === 200) {
        toast.success('Plan updated successfully.');
        navigate('/dashboard/plan-list');
      } else {
        handleUseFormError(res);
      }
      return;
    }

    const res = await dispatch(addPlanAction({ name: data?.planName, benefits: updatedPlanBenefit }));
    if (res?.statusCode === 201) {
      toast.success('Plan added successfully.');
      reset();
      navigate('/dashboard/plan-list');
      setPlanBenefits([{ title: '', tempId: 864347 }]);
    } else {
      handleUseFormError(res);
    }
  };

  const handleBenefit = () => {
    const temp = {
      title: '',
      tempId: Math.floor(Math.random() * 999999) + 1
    };
    setPlanBenefits([...planBenefits, temp]);
  };

  const handleDeleteBenefit = (deleteId) => {
    setPlanBenefits(planBenefits?.filter((item) => item?.tempId !== deleteId));
  };

  const handleBenefitChange = async (index, data) => {
    const tempData = [...planBenefits];
    tempData[index].title = data;
    setPlanBenefits(tempData);
  };

  return (
    <>
      <div className="d-flex justify-content-start px-3">
        <h3 className="header-2 mb-3">Plans</h3>
      </div>
      <div className="row">
        <div className="col-md-7">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="card">
              <h1 className="header-7 mb-4">{updateId ? 'Update Plans' : 'Add Plans'}</h1>
              <div className="row">
                <div className="col-md-8 p-0 mb-2">
                  <InputField
                    placeholder="john Doe"
                    label="Plan Name"
                    type="text"
                    name={'planName'}
                    register={register}
                    error={errors.planName?.message}
                  />
                </div>
              </div>
              {planBenefits?.map((item, index) => (
                <div className="row" key={index}>
                  <div className="col-md-8 p-0">
                    <div className="mb-2">
                      <label className="label">{'Benefits'}</label>
                      <div className="mt-1">
                        <input
                          type={'text'}
                          className={false ? 'form-control form-error' : 'form-control'}
                          value={item?.title}
                          placeholder="Benefits"
                          onChange={(e) => handleBenefitChange(index, e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <p className="col-md-1" onClick={() => handleDeleteBenefit(item?.tempId)}>
                    x
                  </p>
                </div>
              ))}

              <div className="col-md-4 mt-4">
                <OutlineButton handleBtnClick={handleBenefit} label="Add Benefit" class="btn-outline-primary" />
              </div>

              <div className="col-md-8 p-0 mt-4">
                <Button label={updateId ? 'Update' : 'Add'} isLoading={addPlanLoading || updatePlanLoading} isDisabled={addPlanLoading || updatePlanLoading} type="submit" class="btn-primary w-100" />
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
