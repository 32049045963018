import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { MdClose } from 'react-icons/md';
import InputField from './../../../atoms/inputField/inputField';
import { Button } from './../../../component/button/button';
import * as yup from 'yup';
import MultiSelectField from '../../../atoms/multiSelectField/multiSelectField';
import { useDispatch, useSelector } from 'react-redux';
import { getSmsPricingAction, razorpaySmsCreateAction } from '../../../redux/states/sms/sms.actions';
import { createOrderAction } from '../../../redux/states/auth/auth.actions';
import usePaymentRazorpay from '../../../hooks/use-payment-razorpay';
import { handleUseFormError } from '../../../utils/utils';

const schema = yup
  .object({
    sms: yup.object().nullable().required(),
    amount: yup.string().required()
  })
  .required();

export default function RechargeSmsModal({ onClose, active }) {
  const {
    reset,
    register,
    control,
    handleSubmit,
    setValue,
    resetField,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  });
  const dispatch = useDispatch();
  const [handlePayment] = usePaymentRazorpay();

  const { data: smsPricingData, loading: smsPricingLoading, error: smsPricingError } = useSelector((state) => state?.sms?.smsPricing);
  const defaultValues = {
    sms: '',
    amount: ''
  };

  const handleModalClose = () => {
    reset(defaultValues);
    onClose();
  };

  const handleOnSmsChange = (selectedType) => {
    resetField('amount');
    setValue('amount', selectedType?.value);
  };

  const onSubmit = async (data) => {
    console.log(data);
    // pricingId: selectedId, referralCode: inputReferral
    const orderRes = await dispatch(razorpaySmsCreateAction({ smsPricingId: data?.sms?.id }));
    console.log('orderRes', orderRes);
    if (orderRes?.statusCode === 200) {
      const { paymentReferenceId, razorpayOrderId } = orderRes?.data;
      handlePayment(paymentReferenceId, data?.amount, razorpayOrderId, 'sms');
      handleModalClose();
    } else {
      handleUseFormError(orderRes || 'Something went wrong');
    }
    // please handle submit here
    // {
    //   "sms": {
    //     "value": "340.00",
    //       "label": 1000,
    //         "id": 2
    //   },
    //   "amount": "340.00"
    // }
  };

  useEffect(() => {
    if (active) dispatch(getSmsPricingAction());
  }, [dispatch, active]);

  return (
    <>
      <div className="modal" id="staticBackdrop" style={{ display: active ? 'block' : 'none' }}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <MdClose size={30} onClick={handleModalClose} className="modal__icon" />
            <div className="modal-header">
              <h5 className="modal-title header-4" id="staticBackdropLabel">
                {'Recharge SMS'}
              </h5>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit(onSubmit)}>
                <Controller
                  name={'sms'}
                  control={control}
                  render={({ field: { onChange, value }, fieldState }) => (
                    <MultiSelectField
                      label={'Number of SMS'}
                      onChange={(selectedType) => {
                        handleOnSmsChange(selectedType);
                        onChange(selectedType);
                      }}
                      value={value}
                      placeholder={'Please select'}
                      options={smsPricingData.map((item) => ({ value: item?.price, label: item?.numSms, id: item?.id }))}
                      error={fieldState?.error?.message}
                    />
                  )}
                />
                <InputField label="Price" type="text" name={'amount'} register={register} error={errors.amount?.message} isDisabled={true} />
                <Button label={'Continue Payment'} type="submit" class="btn-primary w-100 mt-4" />
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
