
export const LOAD_ALERT_DATA = 'LOAD_ALERT_DATA';

export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_REQUEST = 'REGISTER_USER_REQUEST';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAILED = 'REGISTER_USER_FAILED';

export const REQUEST_OTP = 'REQUEST_OTP';
export const REQUEST_OTP_REQUEST = 'REQUEST_OTP_REQUEST';
export const REQUEST_OTP_SUCCESS = 'REQUEST_OTP_SUCCESS';
export const REQUEST_OTP_FAILED = 'REQUEST_OTP_FAILED';

export const VALIDATE_OTP = 'VALIDATE_OTP';
export const VALIDATE_OTP_REQUEST = 'VALIDATE_OTP_REQUEST';
export const VALIDATE_OTP_SUCCESS = 'VALIDATE_OTP_SUCCESS';
export const VALIDATE_OTP_FAILED = 'VALIDATE_OTP_FAILED';

export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_REQUEST = 'LOGIN_USER_REQUEST';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILED = 'LOGIN_USER_FAILED';



export const GYM_ADDRESS_COUNTRIES = 'GYM_ADDRESS_COUNTRIES';
export const GYM_ADDRESS_COUNTRIES_REQUEST = 'GYM_ADDRESS_COUNTRIES_REQUEST';
export const GYM_ADDRESS_COUNTRIES_SUCCESS = 'GYM_ADDRESS_COUNTRIES_SUCCESS';
export const GYM_ADDRESS_COUNTRIES_FAILED = 'GYM_ADDRESS_COUNTRIES_FAILED';


export const GYM_ADDRESS_STATES = 'GYM_ADDRESS_STATES';
export const GYM_ADDRESS_STATES_REQUEST = 'GYM_ADDRESS_STATES_REQUEST';
export const GYM_ADDRESS_STATES_SUCCESS = 'GYM_ADDRESS_STATES_SUCCESS';
export const GYM_ADDRESS_STATES_FAILED = 'GYM_ADDRESS_STATES_FAILED';


export const GYM_ADDRESS_CITIES = 'GYM_ADDRESS_CITIES';
export const GYM_ADDRESS_CITIES_REQUEST = 'GYM_ADDRESS_CITIES_REQUEST';
export const GYM_ADDRESS_CITIES_SUCCESS = 'GYM_ADDRESS_CITIES_SUCCESS';
export const GYM_ADDRESS_CITIES_FAILED = 'GYM_ADDRESS_CITIES_FAILED';

export const SAVE_ADDRESS = 'SAVE_ADDRESS';
export const SAVE_ADDRESS_REQUEST = 'SAVE_ADDRESS_REQUEST';
export const SAVE_ADDRESS_SUCCESS = 'SAVE_ADDRESS_SUCCESS';
export const SAVE_ADDRESS_FAILED = 'SAVE_ADDRESS_FAILED';

export const GYM_LOGO = 'GYM_LOGO';
export const GYM_LOGO_REQUEST = 'GYM_LOGO_REQUEST';
export const GYM_LOGO_SUCCESS = 'GYM_LOGO_SUCCESS';
export const GYM_LOGO_FAILED = 'GYM_LOGO_FAILED';

export const PRICING_LIST = 'PRICING_LIST';
export const PRICING_LIST_REQUEST = 'PRICING_LIST_REQUEST';
export const PRICING_LIST_SUCCESS = 'PRICING_LIST_SUCCESS';
export const PRICING_LIST_FAILED = 'PRICING_LIST_FAILED';


export const VALIDATE_REFERRAL = 'VALIDATE_REFERRAL';
export const VALIDATE_REFERRAL_REQUEST = 'VALIDATE_REFERRAL_REQUEST';
export const VALIDATE_REFERRAL_SUCCESS = 'VALIDATE_REFERRAL_SUCCESS';
export const VALIDATE_REFERRAL_FAILED = 'VALIDATE_REFERRAL_FAILED';



export const CREATE_ORDER = 'CREATE_ORDER';
export const CREATE_ORDER_REQUEST = 'CREATE_ORDER_REQUEST';
export const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS';
export const CREATE_ORDER_FAILED = 'CREATE_ORDER_FAILED';

