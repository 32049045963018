import React from 'react';
import CheckBox from '../../atoms/checkBox/checkBox';
import { Button } from '../button/button';
import Loader from '../loader/loader';

export default function FilterCard({ data = {}, loader = false, checkedFilter = [], setCheckedFilter }) {
  const handleChange = (obj, filterItem) => {
    const tempObj = {
      ...obj,
      type: filterItem
    };
    if (checkedFilter?.find((item) => item?.name?.toLowerCase() === tempObj?.name?.toLowerCase())?.name) {
      return setCheckedFilter(checkedFilter?.filter((item) => item?.id !== tempObj?.id));
    }
    setCheckedFilter([...checkedFilter, tempObj]);
  };

  const handleResetFilter = () => {
    setCheckedFilter([]);
  };

  return (
    <div className="card mb-4 mb-md-0">
      <h2 className="header-4 mb-4 mb-md-5">Filter</h2>
      {loader ? (
        <Loader />
      ) : (
        typeof data === 'object' && (
          <div className='d-md-block d-flex overflow-scroll'>
            {Object.keys(data)?.map((filterItem, index) => (
              <div className="mb-3 mb-md-5 me-md-0 me-3" key={index}>
                <h6 className="subHeading fw-bold mb-3 mb-md-4">{filterItem}</h6>
                <div className="d-md-block d-flex">
                  {data[filterItem]?.map((item, innerIndex) => (
                    <div className="form-check mb-2 mx-md-0 mx-2" key={innerIndex}>
                      <CheckBox
                        label={item?.name}
                        value={item?.id}
                        checked={checkedFilter?.find((i) => i?.name === item?.name)?.id}
                        handleChange={() => handleChange(item, filterItem)}
                      />
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        )
      )}

      <Button label="Reset All Filters" class="btn btn-primary btn-block" handleBtnClick={handleResetFilter} />
    </div>
  );
}
