import React from 'react';
import './checkBox.css'

const CheckBox = ({ label, value, checked, handleChange }) => {
  return (
    <div>
      <input className="form-check-input" type="checkbox" id="flexCheckDefault" checked={checked} onChange={handleChange} />
      <label className="form-check-label subHeading" htmlFor="flexCheckDefault">
        {label}
      </label>
    </div>
  );
};

export default CheckBox;
