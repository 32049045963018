import React, { useState } from 'react';
import { MdClose } from 'react-icons/md';
import { Button } from '../../../../component/button/button';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import InputField from '../../../../atoms/inputField/inputField';
import { useSelector } from 'react-redux';
import { getPackagesListAction } from '../../../../redux/states/plan/plan.actions';
import MultiSelectField from '../../../../atoms/multiSelectField/multiSelectField';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  calculatePaymentAction,
  getMemberAction,
  updateMemberBatchAction,
  updateMemberPackageAction,
  updateMemberTrainerAction
} from '../../../../redux/states/member/member.actions';
import { toast } from 'react-toastify';
import { getCurrentDate, handleUseFormError } from '../../../../utils/utils';
import RadioButton from '../../../../atoms/RadioButton/RadioButton';
import { getTrainerListAction } from '../../../../redux/states/trainer/trainer.actions';

const schema = yup
  .object({
    trainer: yup.object().nullable(),
    startDate: yup.string().required(),
    discountTrainer: yup.string(),
    AmountPaid: yup.string(),
    packageChange: yup.string(),
    dueAmount: yup.string()
  })
  .required();

export default function UpdateTrainerModal({ onClose, active, memberId, packageId, expiresAt, trainerData, dueAmount, memberPackageHistoriesId }) {
  const dispatch = useDispatch();

  const { data: trainerListData } = useSelector((state) => state?.trainer?.trainerList);
  const { data: paymentCostData } = useSelector((state) => state?.member?.paymentCost);

  const [selectedToday, setSelectedToday] = useState(false);
  const [showPackageOption, setShowPackageOption] = useState(false);

  useEffect(() => {
    setShowPackageOption(false);
    dispatch(getTrainerListAction());
  }, []);

  function handleModalClose() {
    onClose();
    reset();
    fillInitialForm();
    setShowPackageOption(false);
  }

  const {
    register,
    formState: { errors, values },
    handleSubmit,
    control,
    reset,
    setValue,
    watch
  } = useForm({
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    fillInitialForm();
  }, [expiresAt, trainerData, dueAmount]);

  const fillInitialForm = () => {
    setValue('startDate', expiresAt);
    setValue('dueAmount', dueAmount);
    setValue('trainer', { label: trainerData?.user?.name, value: trainerData?.id });
    setValue('packageChange', 'endCurrentPackage');
  };

  useEffect(() => {
    setValue('AmountPaid', paymentCostData?.total);
  }, [paymentCostData, setValue]);

  const handleTrainerChange = (obj) => {
    setValue('trainer', obj);
    setShowPackageOption(true);
  };

  const discountTrainer = watch('discountTrainer');
  const trainerVal = watch('trainer')?.value;
  const packageChange = watch('packageChange');

  console.log('trainerVal', trainerVal);
  useEffect(() => {
    const body = {
      trainerId: trainerVal,
      discountTrainer: discountTrainer || 0,
      packageId
    };
    (discountTrainer || trainerVal) && dispatch(calculatePaymentAction(body));
  }, [discountTrainer, trainerVal, packageId, dispatch]);

  useEffect(() => {
    setSelectedToday(packageChange === 'today');
    setValue('startDate', packageChange === 'today' ? getCurrentDate() : expiresAt);
  }, [packageChange, expiresAt, setValue]);

  const onSubmit = async (data) => {
    const body = {
      trainerId: data?.trainer?.value,
      discountTrainer: data?.discountTrainer || 0,
      startDate: data?.startDate,
      memberPackageHistoriesId,
      action: selectedToday ? 'NOW' : 'LATER',
      dueAmount: data?.dueAmount
    };
    selectedToday && delete body.startDate;
    const res = await dispatch(updateMemberTrainerAction(memberId, body));
    if (res?.statusCode === 201) {
      toast.success(res?.message);
      handleModalClose();
      dispatch(getMemberAction(memberId));
    } else {
      handleUseFormError(res);
    }
  };

  return (
    <div className="modal" id="staticBackdrop" style={{ display: active ? 'block' : 'none' }}>
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <MdClose size={30} onClick={() => handleModalClose(false)} className="modal__icon" />
          <div className="modal-header">
            <h5 className="modal-title header-4" id="staticBackdropLabel">
              {'Update Trainer'}
            </h5>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Controller
                name="trainer"
                control={control}
                render={({ field: { onChange, value }, fieldState }) => (
                  <MultiSelectField
                    label={'trainer'}
                    onChange={handleTrainerChange}
                    value={value}
                    placeholder={'Select trainer'}
                    options={trainerListData?.map((trainer) => ({ value: trainer?.id, label: trainer?.name }))}
                    noOptionsMessage={'Please create trainer before adding member'}
                    error={fieldState?.error?.message}
                  />
                )}
              />

              {showPackageOption && (
                <div className="mt-4">
                  <RadioButton
                    register={register}
                    label={'End of current trainer'}
                    name="packageChange"
                    value={'endCurrentPackage'}
                    // handleOnChange={() => {}}
                  />
                  <RadioButton register={register} label={'Today'} value={'today'} name="packageChange" />
                </div>
              )}

              <InputField
                placeholder="Start Date"
                label="Start Date"
                type="date"
                isDisabled={true}
                name={'startDate'}
                register={register}
                error={errors.startDate?.message}
              />
              <InputField
                placeholder="Discount % in trainer"
                label="Discount % in trainer"
                type="number"
                name={'discountTrainer'}
                register={register}
                error={errors.discountTrainer?.message}
              />
              <InputField
                placeholder={`Amount to be paid`}
                label={`Amount to be paid ${selectedToday ? '' : `on ${expiresAt}`}`}
                type="number"
                isDisabled={true}
                name={'AmountPaid'}
                register={register}
                error={errors.text?.message}
              />
              {selectedToday ? (
                <InputField
                  placeholder={`Due Amount`}
                  label={`Due Amount`}
                  type="number"
                  name={'dueAmount'}
                  register={register}
                  error={errors.text?.message}
                />
              ) : null}
              <Button label={'Update'} type="submit" class="btn-primary w-100 mt-4" />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
