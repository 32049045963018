export enum VisitorStatus {
    ACTIVE = "Active",
    WON = "Won",
    CLOSED = "Closed",
}

export enum MemberStatus {
    ACTIVE = "Active",
    LEFT = "Left",
    FREEZE = "Freeze"

}